import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-edit-phone',
  templateUrl: './edit-phone.component.html',
  styleUrls: ['./edit-phone.component.scss']
})
export class EditPhoneComponent {

  phoneNumber = new FormControl<string>(this.data.phoneNumber, [
    Validators.required, Validators.pattern(/^[+]?[0-9]{10,14}$/)
  ]);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      phoneNumber: string
    }
  ) { }

}
