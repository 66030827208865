import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, catchError, first, Observable, tap } from 'rxjs';
import { GlobalSharedService } from '../../global-shared.service';
import { ChatDetailedInfo } from '../../interfaces/ChatDetailedInfo';
import { ChatMessage, ChatMessageData } from '../../interfaces/ChatMessage';
import { UnreadChat } from '../../interfaces/UnreadChat';
import { ErrorService } from '../helpers/error.service';
import { BidsService } from './bids.service';
import { BookingsService } from './bookings.service';
import Reference = firebase.database.Reference;
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private chatsUrl!: string;
  chats: { [ key: string ]: { [ key: string ]: { [ key: string ]: string; }; }; } = {
    bookings: {},
    offers: {},
    archivedOffers: {},
    archivedBookings: {},
  };
  totalUnread$: BehaviorSubject<{ bookings: number; offers: number; }> = new BehaviorSubject<{ bookings: number; offers: number; }>({
    bookings: 0,
    offers: 0
  });
  chatsRefs: { [ x: string ]: Array<Reference>; } = {
    bookings: [],
    offers: [],
    archivedOffers: [],
    archivedBookings: [],
  };
  unread$: BehaviorSubject<{ [ key: string ]: number; }> = new BehaviorSubject({});

  constructor(
    private fbRTB: AngularFireDatabase,
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
    private bookingService: BookingsService,
    private bidsService: BidsService,
  ) {
    this.chatsUrl = `${this.globalSharedService.config.baseUrl}/chats`;
  }

  // get chat by offer id
  getChatById(id: string | null, query = {}): Observable<ChatDetailedInfo> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<ChatDetailedInfo>( `${this.chatsUrl}/${id}`, {
      params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() })
    })
      .pipe(
        catchError(this.errorService.handleError),
      );
  }

  // get all unreaded chats
  getUnreadChats(): Observable<UnreadChat[]> {
    return this.http.get<UnreadChat[]>(`${this.chatsUrl}/unread`)
      .pipe(
        catchError(this.errorService.handleError),
      );
  }

  subscribeChat(chatId: string, cb: (message: ChatMessage) => void): void {
    firebase.database().ref(`chat/${ chatId }/`).on('child_added', childSnapshot => {
      if (childSnapshot.key === 'isTyping' || childSnapshot.key === 'users') return;
      cb({ key: childSnapshot.key, data: childSnapshot.val() } as ChatMessage);
    });
  }

  onChatChanges(chatId: string, cb: (messages: ChatMessageData[]) => void): void {
    firebase.database().ref(`chat/${ chatId }/`).on('value', snapshot => {
      const chat = snapshot.val()
      const messages = Object.keys(chat).filter(mId => mId !== 'isTyping' && mId !== 'users').map((messageId) => ({ ...chat[messageId as string], id: messageId}))
      cb(messages);        
    });
  }

  subscribeCreatedChat(userId: string): Observable<{ [x: string]: string; } | null> {
    return this.fbRTB.object<{ [x: string]: string; }>(`updates/users/${ userId }/messages/chats`).valueChanges()
    .pipe(
      tap(data => {
        if(data) this.subscribeUnreadMessages(data.text, userId)
      })
    )
  }

  sendMessage(chatId: string, message: ChatMessageData): Promise<any> {
    const ref = firebase.database().ref(`chat/${ chatId }/`).push();
    const newMessageKey = ref.key;
    return ref.set(message)
      .then(() => {
        return this.sendUnread(chatId, message.senderId, message.senderName, newMessageKey || '');
      })
      .catch(console.error);
  }

  updateMessage(chatId: string, messageId: string, messageTxt: string): Promise<any> {
    const ref = firebase.database().ref(`chat/${ chatId }/${messageId}/txt`)
    return ref.set(messageTxt).catch(console.error);
  }

  sendUnread(chatId: string, senderId: string, senderName: string, messageId = ''): Promise<any> {
    // chat/<offerId>/users/<otherUserId>/unreadMessages
    if (!chatId) throw (new Error('No chatId'));
    if (!senderId) throw (new Error('No senderId'));
    if (!senderName) throw (new Error('No senderName'));
    if (!messageId) throw (new Error('No messageId'));

    return firebase.database().ref(`chat/${ chatId }/users/`).once('value')
      .then((snapshot) => {
        if (snapshot.exists()) {
          const chatUsers = Object.keys(snapshot.val());
          chatUsers.forEach((chatUser: string) => {
            if (chatUser !== senderId) {
              firebase.database().ref(`chat/${ chatId }/users/${ chatUser }`).child('unreadMessages')
                .update({ [messageId]: senderName })
                .then()
                .catch(console.error);
            }
          });
        }
        return messageId;
      }).catch(console.error);
  }

  unSubscribeChat(chatId: string): void {
    if(chatId) firebase.database().ref(`chat/${ chatId }/`).off();
  }

  async isExist(chatId: string): Promise<boolean> {
    if (!chatId) return Promise.resolve(false);
    const snapshot = await firebase.database().ref(`chat/${ chatId }`).once('value');
    return snapshot.exists();
  }

  createChat(chatId: string): Observable<unknown> {
    return this.http.post<unknown>(this.chatsUrl, { offer: chatId }, { params: new HttpParams({ fromObject: { silent: true }, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  setTyping(chatId: string, userId: string, value: boolean): Promise<unknown> {
    return firebase.database().ref(`chat/${ chatId }/isTyping/`).set({ [userId]: value });
  }

  getChatsIds(): Observable<Array<string>> {
    return this.http.get<Array<string>>(this.chatsUrl)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  subscribeUnreadMessages(chatId: string, userId: string, typeId?: string): Reference {
    const ref = firebase.database().ref(`chat/${ chatId }/users/${ userId }/unreadMessages`);
    ref.on('value', childSnapshot => {
      const setCount = (type: string) => {
        this.chats[ type ][ chatId ] = { [ childSnapshot.key as string ]: childSnapshot.val() as string };
        Object.keys(this.chats).forEach(key => {
          const arr = Object.values(this.chats[ key ]).map(m => Object.keys(m.unreadMessages || {}).length);
          const count = arr.length ? arr.reduce((accumulator, currentValue) => accumulator + currentValue) : 0;
          this.totalUnread$.next({ ...this.totalUnread$.value, [ key ]: count });
        });
      };
      if (typeId) {
        setCount(typeId);
      }
      else {
        this.bookingService.bookings$.pipe(first()).subscribe(bookings => bookings?.forEach(booking => {
          if (chatId === booking.bids[ 0 ]?.offer) {
            setCount('bookings');
          }
        }));
        this.bidsService.offers$.pipe(first()).subscribe(offers => offers.forEach(offer => {
          if (chatId === offer.offer) {
            setCount('offers');
          }
        }));
      }

      const unreads: { [ key: string ]: number; } = {};
      Object.keys(this.chats).forEach(type => {
        Object.keys(this.chats[ type ]).forEach(key => {
          if (this.chats[ type ][ key ]?.unreadMessages) {
            unreads[ key ] = Object.keys(this.chats[ type ][ key ].unreadMessages).length;
          }
        });
      });
      this.unread$.next(unreads);
    });
    return ref;
  }

  subscribeSpecificChatUnreadMessages(chatId: string, userId: string, cb: (count: number) => void): Reference {
    const ref = firebase.database().ref(`chat/${ chatId }/users/${ userId }/unreadMessages`);
    ref.on('value', childSnapshot => {
      cb(childSnapshot.val() ? Object.values(childSnapshot.val()).length : 0)
    });
    return ref;
  }

  unSubscribeUnreadMessages(ref: Reference): void {
    ref.off();
  }

  removeUnread(chatId: string, userId: string): Promise<unknown> {
    return firebase.database().ref(`chat/${ chatId }/users/${ userId }/unreadMessages`).remove();
  }

  sendChatMessage(chatId: string, text: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.chatsUrl}/${chatId}`, { text })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  sendClientChatMessage(chatId: string, data: { text: string, messageId?: string}): Observable<unknown> {
    return this.http.post<unknown>(`${this.chatsUrl}/${chatId}/messages`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
