<div class="modal">
  <form [formGroup]="tabForm">
    <div class="modal-header">
      <span class="modal-header__text">Edit passenger type</span>
      <button mat-button class="close-icon" mat-dialog-close>
        <img src="assets/img/cancel.svg" alt="">
      </button>
    </div>
    <div class="modal-content">
      <div class="title">Passenger type</div>
        <mat-form-field hideRequiredMarker = true appearance="outline">
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of types" [value]="type" class="tt-c">{{type}}</mat-option>
          </mat-select>
        </mat-form-field>
    </div>
    <div class="modal-footer">
      <div class="footer-bottom">
        <div class="d-f jc-fe">
          <button mat-stroked-button type="button" class="footer-bottom__cancel modal-actions mr-15" mat-dialog-close>Cancel</button>
          <button mat-raised-button [disabled]="tabForm.invalid" [mat-dialog-close]="tabForm.value.type" class="footer-bottom__ok modal-actions">Save</button>
        </div>
      </div>
    </div>
  </form>
</div>
