import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MakeOfferRoutingModule } from './make-offer-routing.module';
import { MakeOfferComponent } from './make-offer.component';
import { LoginModalComponent } from './login/login.component';
import { GlobalSharedModule } from '../../global-shared.module';

@NgModule({
  declarations: [
    MakeOfferComponent,
    LoginModalComponent,
  ],
  imports: [
    CommonModule,
    MakeOfferRoutingModule,
    GlobalSharedModule,
  ]
})
export class MakeOfferModule { }
