import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WaitingUser } from 'projects/common/src/lib/interfaces/WaitingUser';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { GlobalSharedService } from '../../global-shared.service';
import { Pagination } from '../../interfaces/Pagination';
import { ErrorService } from '../helpers/error.service';

@Injectable({
  providedIn: 'root'
})
export class WaitingListService {
  waitingListUrl = `${ this.globalSharedService.config.baseUrl }/waiting-list`;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private globalSharedService: GlobalSharedService,
  ) { }

  getWaitingList(query: { status: string[], page: number, limit: number }): Observable<{ data: WaitingUser[], pagination?: Pagination }> {
    return this.http.get<{ data: WaitingUser[], pagination?: Pagination }>(this.waitingListUrl, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  getWaitingListItemById(id: string): Observable<WaitingUser> {
    return this.http.get<WaitingUser>(`${this.waitingListUrl}/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateWaitingListItem(data: unknown): Observable<unknown> {
    return this.http.patch<unknown>(`${this.waitingListUrl}`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateStatus(id: string, status: string): Observable<unknown> {
    return this.http.put<unknown>(`${this.waitingListUrl}/${id}/status`, { status })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createWaitingListItem(data: unknown): Observable<unknown> {
    return this.http.post<unknown>(`${this.waitingListUrl}`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
