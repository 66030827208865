import { BACKSPACE, DELETE, LEFT_ARROW, UP_ARROW, DOWN_ARROW, NINE, NUMPAD_NINE, NUMPAD_ZERO, RIGHT_ARROW, TAB, ZERO, A, P } from '@angular/cdk/keycodes';
import { Directive, ElementRef, forwardRef, HostListener, Renderer2, Self, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { DateTime } from 'luxon';
import { TimeService } from '../../services/helpers/time.service';

@Directive({
  selector: '[libTimeMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeMaskDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimeMaskDirective),
      multi: true,
    },
  ],
})
export class TimeMaskDirective implements ControlValueAccessor, Validator {
  onChange!: (_: string) => void;
  touched!: () => void;
  private dateValue!: string;

  private fieldJustGotFocus = false;
  private h12Format = false;
  @Output() timeChange = new EventEmitter();
  constructor(
    @Self() private el: ElementRef,
    private renderer: Renderer2,
    private timeService: TimeService,
  ) {
    this.h12Format = this.timeService.timeFormat !== 'HH:mm';
  }

  @HostListener('keydown', [ '$event' ])
  onKeyDown(evt: KeyboardEvent): void {
    const keyCode = evt?.keyCode;
    switch (keyCode) {
      case UP_ARROW:
        this.incrementTime();
        break;
      case DOWN_ARROW:
        this.decrementTime();
        break;
      case LEFT_ARROW:
      case RIGHT_ARROW:
      case TAB:
        this._decideWhetherToJumpAndSelect(keyCode, evt);
        break;
      case DELETE:
      case BACKSPACE:
        this._clearHoursOrMinutes();
        break;
      case A:
      case P:
        this.setPeriodKey(evt?.key);
        break;
      default:
        if ((keyCode >= ZERO && keyCode <= NINE) || (keyCode >= NUMPAD_ZERO && keyCode <= NUMPAD_NINE)) {
          this._setInputText(evt?.key);
        }
    }
    if (keyCode !== TAB) {
      evt?.preventDefault();
    }
  }

  @HostListener('click', [ '$event' ])
  onClick(): void {
    this.fieldJustGotFocus = true;
    const caretPosition = this._doGetCaretPosition();
    if (caretPosition < 3) {
      this.el.nativeElement.setSelectionRange(0, 2);
    } else if (caretPosition > 5) {
      this.el.nativeElement.setSelectionRange(6, 8);
    } else {
      this.el.nativeElement.setSelectionRange(3, 5);
    }
  }

  @HostListener('focus', [ '$event' ])
  onFocus(): void {
    this.fieldJustGotFocus = true;
    const caretPosition = this._doGetCaretPosition();
    if (caretPosition < 3) {
      this.el.nativeElement.setSelectionRange(0, 2);
    } else if (caretPosition > 5) {
      this.el.nativeElement.setSelectionRange(6, 8);
    } else {
      this.el.nativeElement.setSelectionRange(3, 5);
    }
  }

  @HostListener('blur', [ '$event' ])
  onBlur(): void {
    if (this.touched) this.touched();
  }

  private _decideWhetherToJumpAndSelect(keyCode: number, evt?: KeyboardEvent): void {
    const caretPosition = this._doGetCaretPosition();
    switch (keyCode) {
      case TAB:
      case RIGHT_ARROW:
        if (caretPosition < 2 && !evt?.shiftKey) {
          this.el.nativeElement.setSelectionRange(3, 5);
        } else if (caretPosition > 5 && !evt?.shiftKey) {
          this.el.nativeElement.setSelectionRange(0, 2);
        } else {
          this.el.nativeElement.setSelectionRange(6, 8);
        }
        evt?.preventDefault();
        break;
      case LEFT_ARROW:
        if (caretPosition < 2 && !evt?.shiftKey) {
          this.el.nativeElement.setSelectionRange(6, 8);
        } else if (caretPosition > 5 && !evt?.shiftKey) {
          this.el.nativeElement.setSelectionRange(3, 5);
        } else {
          this.el.nativeElement.setSelectionRange(0, 2);
        }
        evt?.preventDefault();
        break;
    }
    this.fieldJustGotFocus = true;
  }

  private _setInputText(key: string): void {
    const input: string[] = this.el.nativeElement.value.split(/:| /);
    const hours: string = input[ 0 ];
    const minutes: string = input[ 1 ];
    const period: string = input[ 2 ];
    const caretPosition = this._doGetCaretPosition();
    if (this.h12Format) {
      if (caretPosition < 3) {
        this._setHours(hours, minutes, key, period);
      } else if (caretPosition > 5) {
        this._setPeriood(hours, minutes, key, period);
      } else {
        this._setMinutes(hours, minutes, key, period);
      }
    } else {
      if (caretPosition < 3) {
        this._setHours(hours, minutes, key);
      } else {
        this._setMinutes(hours, minutes, key);
      }
    }
    this.fieldJustGotFocus = false;
  }

  private _setHours(hours: string, minutes: string, key: string, period?: string): void {
    const hoursArray: string[] = hours.split('');
    const firstDigit: string = hoursArray[ 0 ];
    const secondDigit: string = hoursArray[ 1 ];
    let newHour = '';
    let completeTime = '';
    let sendCaretToMinutes = false;
    if (firstDigit === '-' || this.fieldJustGotFocus) {
      newHour = `0${ key }`;
      sendCaretToMinutes = Number(key) > 2;
    } else {
      newHour = `${ secondDigit }${ key }`;
      if (Number(newHour) > (this.h12Format ? 12 : 23)) {
        newHour = this.h12Format ? '12' : '23';
      }
      sendCaretToMinutes = true;
    }
    completeTime = `${ newHour }:${ minutes }`;
    if (period) completeTime += ` ${ period }`;
    if (!sendCaretToMinutes) {
      this.setNewValue(completeTime, 0);
    } else {
      this.setNewValue(completeTime, 3);
      this.fieldJustGotFocus = true;
    }
  }

  private _setMinutes(hours: string, minutes: string, key: string, period?: string): void {
    const minutesArray: string[] = minutes.split('');
    const firstDigit: string = minutesArray[ 0 ];
    const secondDigit: string = minutesArray[ 1 ];
    let newMinutes = '';
    let completeTime = '';
    if (firstDigit === '-' || this.fieldJustGotFocus) {
      newMinutes = `0${ key }`;
    } else {
      if (Number(minutes) === 59) {
        newMinutes = `0${ key }`;
      } else {
        newMinutes = `${ secondDigit }${ key }`;
        if (Number(newMinutes) > 59) {
          newMinutes = '59';
        }
      }
    }
    completeTime = `${ hours }:${ newMinutes }`;
    if (period) completeTime += ` ${ period }`;
    this.setNewValue(completeTime, 3);
  }

  private _setPeriood(hours: string, minutes: string, key: string, period: string): void {
    let newPeriod = period;
    switch (key.toLowerCase()) {
      case 'p':
        newPeriod = 'PM';
        break;
      case 'a':
        newPeriod = 'AM';
        break;
    }
    this.setNewValue(`${ hours }:${ minutes } ${ newPeriod }`, 6);
  }

  private setPeriodKey(key: string): void {
    if (!this.h12Format) return;
    const input: string[] = this.el.nativeElement.value.split(/:| /);
    const hours: string = input[ 0 ];
    const minutes: string = input[ 1 ];
    let newPeriod: string = input[ 2 ];
    switch (key.toLowerCase()) {
      case 'p':
        newPeriod = 'PM';
        break;
      case 'a':
        newPeriod = 'AM';
        break;
    }
    this.setNewValue(`${ hours }:${ minutes } ${ newPeriod }`, 6);
  }

  private incrementTime(): void {
    const input: string[] = this.el.nativeElement.value.split(/:| /);
    let hours: string = input[ 0 ];
    let minutes: string = input[ 1 ];
    let period: string = input[ 2 ];
    const caretPosition = this._doGetCaretPosition();
    if (this.h12Format) {
      if (caretPosition < 3) {
        const newH = (hours === '12' || hours === '--') ? 1 : Number(hours) + 1;
        hours = newH > 9 ? newH.toString() : '0' + newH;
      } else if (caretPosition > 5) {
        period = (period === 'AM' || period === '--') ? 'PM' : 'AM';
      } else {
        const newM = (minutes === '59' || minutes === '--') ? 0 : Number(minutes) + 1;
        minutes = newM > 9 ? newM.toString() : '0' + newM;
      }
      this.setNewValue(`${ hours }:${ minutes } ${ period }`, caretPosition);
    } else {
      if (caretPosition < 3) {
        const newH = (hours === '23' || hours === '--') ? 0 : Number(hours) + 1;
        hours = newH > 9 ? newH.toString() : '0' + newH;
      } else {
        const newM = (minutes === '59' || minutes === '--') ? 0 : Number(minutes) + 1;
        minutes = newM > 9 ? newM.toString() : '0' + newM;
      }
      this.setNewValue(`${ hours }:${ minutes }`, caretPosition);
    }
  }

  private decrementTime(): void {
    const input: string[] = this.el.nativeElement.value.split(/:| /);
    let hours: string = input[ 0 ];
    let minutes: string = input[ 1 ];
    let period: string = input[ 2 ];
    const caretPosition = this._doGetCaretPosition();
    if (this.h12Format) {
      if (caretPosition < 3) {
        const newH = (hours === '01' || hours === '--') ? 12 : Number(hours) - 1;
        hours = newH > 9 ? newH.toString() : '0' + newH;
      } else if (caretPosition > 5) {
        period = (period === 'AM' || period === '--') ? 'PM' : 'AM';
      } else {
        const newM = (minutes === '00' || minutes === '--') ? 59 : Number(minutes) - 1;
        minutes = newM > 9 ? newM.toString() : '0' + newM;
      }
      this.setNewValue(`${ hours }:${ minutes } ${ period }`, caretPosition);
    } else {
      if (caretPosition < 3) {
        const newH = (hours === '00' || hours === '--') ? 23 : Number(hours) - 1;
        hours = newH > 9 ? newH.toString() : '0' + newH;
      } else {
        const newM = (minutes === '00' || minutes === '--') ? 59 : Number(minutes) - 1;
        minutes = newM > 9 ? newM.toString() : '0' + newM;
      }
      this.setNewValue(`${ hours }:${ minutes }`, caretPosition);
    }
  }

  _clearHoursOrMinutes(): void {
    const caretPosition = this._doGetCaretPosition();
    const input: string[] = this.el.nativeElement.value.split(/:| /);
    const hours: string = input[ 0 ];
    const minutes: string = input[ 1 ];
    const period: string = input[ 2 ];
    let newTime = '';
    if (this.h12Format) {
      if (caretPosition < 2) {
        newTime = `--:${ minutes } ${ period }`;
      } else if (caretPosition > 5) {
        newTime = `${ hours }:${ minutes } --`;
      } else {
        newTime = `${ hours }:-- ${ period }`;
      }
    } else {
      if (caretPosition > 2) {
        newTime = `${ hours }:--`;
      } else {
        newTime = `--:${ minutes }`;
      }
    }
    this.fieldJustGotFocus = true;
    this.setNewValue(newTime, caretPosition);
  }

  writeValue(value: string): void {
    let caretPosition;
    if (document.activeElement === this.el.nativeElement) {
      caretPosition = this._doGetCaretPosition();
    }
    if (value) this.dateValue = DateTime.fromFormat(value, 'HH:mm').toFormat(this.timeService.timeFormat);
    const v = this.dateValue || (this.h12Format && '--:-- --' || '--:--');
    this.renderer.setProperty(this.el.nativeElement, 'value', v);
    if (typeof caretPosition === 'number') {
      if (caretPosition < 3) {
        this.el.nativeElement.setSelectionRange(0, 2);
      } else if (caretPosition > 5) {
        this.el.nativeElement.setSelectionRange(6, 8);
      } else {
        this.el.nativeElement.setSelectionRange(3, 5);
      }
    }
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  validate(): null | { validTime: boolean; } {
    return this.el.nativeElement.value.indexOf('-') === -1 ? null : { validTime: false };
  }

  _doGetCaretPosition(): number {
    let iCaretPos = 0;
    const nativeElement = this.el.nativeElement;
    if (nativeElement.selectionStart || nativeElement.selectionStart === '0') {
      iCaretPos = nativeElement.selectionStart;
    }
    return iCaretPos;
  }

  setNewValue(newTime: string, caretPosition?: number): void {
    this.renderer.setProperty(this.el.nativeElement, 'value', newTime);
    this._controlValueChanged();
    if ( typeof caretPosition !== 'number') caretPosition = this._doGetCaretPosition();
    if (caretPosition < 3) {
      this.el.nativeElement.setSelectionRange(0, 2);
    } else if (caretPosition > 5) {
      this.el.nativeElement.setSelectionRange(6, 8);
    } else {
      this.el.nativeElement.setSelectionRange(3, 5);
    }
  }

  _controlValueChanged(): void {
    const time = DateTime.fromFormat(this.el.nativeElement.value, this.timeService.timeFormat).toFormat('HH:mm');
    if (this.onChange) this.onChange(time);
    if (!this.dateValue?.includes('-')) this.timeChange.emit(time);
  }

}
