<mat-expansion-panel class="widget mat-elevation-z request-menu" hideToggle expanded (opened)="autosize.resizeToFitContent(true);">
  <mat-expansion-panel-header class="widget__header">
    <mat-panel-title>
      <div class="expand-icon" [class._active]="false">
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
        </svg>
      </div>
      <div class="title">Request</div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="description">
        <div class="row jc-fe">
          <span class="description__first">Created</span>
          <span class="description__second">{{frp?.created | date: 'dd/MM/yyyy ' + timeService.timeFormat}}</span>
        </div>
        <div *ngIf="frp?.created !== frp?.updated" class="row jc-fe">
          <span class="description__first">Updated</span>
          <span class="description__second">{{frp?.updated | date: 'dd/MM/yyyy ' + timeService.timeFormat}}</span>
        </div>
      </div>
      <div class="menu">
        <button type="button" mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();" class="w-32 h-32">
          <img src="assets/img/more_vert.svg" alt="">
        </button>
        <mat-menu #menu="matMenu" yPosition="below">
          <a mat-menu-item *ngIf="frp?.legs" [routerLink]="['/request/edit']" [queryParams]="{ request: frp?.id }">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
            </svg>
            <span>Edit</span>
          </a>
          <button type="button" mat-menu-item *ngIf="frp?.legs&&frp?.state !== 'canceled'" (click)="deleteFrp(frp?.id)">
            <img src="assets/img/delete.svg" alt="">
            <span>Delete</span>
          </button>
          <button type="button" mat-menu-item *ngIf="frp && frp.legs && frp.decisionDate.split('T')[0] <= now" (click)="returnToSourcing()">
            <span>Return to Sourcing</span>
          </button>
          <button type="button" mat-menu-item *ngIf="frp && frp.legs && frp.decisionDate.split('T')[0] > now" (click)="setDecisionRequired()">
            <span>Set Decision Required</span>
          </button>
        </mat-menu>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <form [formGroup]="frpForm">
    <div class="widget__body new-request" *ngFor="let leg of frp?.legs; let last = last; index as i">
      <div class="title">LEG-{{i+1}}</div>
      <div class="new-request-field">
        <div class="d-f ai-c">
          <img src="assets/img/calendar.svg" alt="" width="42" height="42" style="padding: 1px; margin-right: 5px; margin-left: -5px;">
          <div class="col jc-c">
            <div class="text1">{{leg | airportDateTime: 'from' : "calendar_day"}}</div>
            <div class="text2">{{leg | airportDateTime: 'from' : "week_day"}}</div>
          </div>
        </div>
        <div class="ta-r">
          <div class="text1">{{leg | airportDateTime: 'from' : "string_time"}}</div>
          <div class="text2">Depart time (local)</div>
        </div>
      </div>
      <div *ngIf="leg.from" class="d-f ai-c">
        <span class="fi" [class]="getCountryFlagClass(leg.from.countryCode)"></span>
        <div class="new-request-icao">
          <span *ngIf="!leg.fromCityOnly">{{ leg.from.name.replace(' Airport', '') }} ({{ leg.from.icao }})</span>
          <span *ngIf="leg.fromCityOnly">
            <span>{{ leg.from.city }}</span>
            <span *ngIf="leg.from.province">, {{ leg.from.province }}</span>
            <span>, {{ leg.from.countryName }}</span>
          </span>
        </div>
      </div>
      <div *ngIf="leg.to" class="d-f ai-c">
        <span class="fi" [class]="getCountryFlagClass(leg.to.countryCode)"></span>
        <div class="new-request-icao">
          <span *ngIf="!leg.toCityOnly">{{ leg.to.name.replace(' Airport', '') }} ({{ leg.to.icao }})</span>
          <span *ngIf="leg.toCityOnly">
            <span>{{ leg.to.city }}</span>
            <span *ngIf="leg.to.province">, {{ leg.to.province }}</span>
            <span>, {{ leg.to.countryName }}</span>
          </span>
        </div>
      </div>
      <div class="d-f ai-c jc-sb mb-10">
        <div class="d-f ai-c">
          <img src="assets/img/client-app/passengers.svg" alt="" width="42" height="42" style="padding: 1px; margin-right: 5px; margin-left: -5px;">
          <div>{{leg.adultsNumber}} adult<span *ngIf="leg.childrenNumber">, {{leg.childrenNumber}} children</span><span *ngIf="leg.infantsNumber">, {{leg.infantsNumber}} infant</span></div>
        </div>
        <div class="d-f ai-c">
          <lib-popup-default class="flex" *ngIf="leg.pets" head="Pets" [description]="leg?.petsDescription || 'No pets description'" [hoverOpen]="true">
            <div class="d-f jc-c ai-c">
              <img src="assets/img/pets.svg" alt="" width="32" height="32" style="padding: 2px;">
            </div>
          </lib-popup-default>
          <lib-popup-default class="flex" *ngIf="leg.cargo" head="Comments" [description]="leg?.cargoDescription || 'No comments'" [hoverOpen]="true">
            <div class="d-f jc-c ai-c" style="filter: invert(81%) sepia(10%) saturate(920%) hue-rotate(1deg) brightness(80%) contrast(85%);">
              <img src="assets/img/comments-white.svg" alt="" width="32" height="32" style="padding: 2px; filter: brightness(0) saturate(100%)">
            </div>
          </lib-popup-default>
        </div>
      </div>
      <div *ngIf="leg.passengers.length" class="mb-10">
        <div class="title">PASSENGER DOCUMENTS</div>
        <div class="new-request-passenger" *ngFor="let p of leg.passengers">
          <div>{{p.title}} {{p.firstName}} {{p.lastName}}</div>
          <button mat-icon-button style="width: 36px; height: 36px;" *ngIf="p.documents?.length; else noDocs" (click)="downloadPassengerDocumentsAsZip(p, leg.id)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 15.6667L3 16.75C3 18.5449 4.45507 20 6.25 20L17.0833 20C18.8783 20 20.3333 18.5449 20.3333 16.75L20.3333 15.6667M16.3333 12.1667L12 15.6667M12 15.6667L7.66667 12.1667M12 15.6667L12 4" stroke="#B4A27F" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
          <ng-template #noDocs>
            <span class="fill-gold op-05 h-36">No documents</span>
          </ng-template>
        </div>
      </div>
      <div class="new-request-field mt-15" *ngIf="frp?.legs && last">
        <div class="d-f ai-c">
          <img src="assets/img/purse.svg" alt="" width="42" height="42" style="padding: 1px; margin-right: 5px; margin-left: -5px;">
          <div class="col jc-c">
            <div class="text1">CLIENT'S PRICE EXPECTATION</div>
          </div>
        </div>
        <div class="ta-r">
          <div class="text1">{{leg.budgetCurCode}} {{clientBudget | number}}</div>
        </div>
      </div>
      <div class="new-request-field mt-15" *ngIf="frp?.legs && last && frp?.estimatedBudget">
        <div class="d-f ai-c">
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" style="padding: 1px;">
            <path d="M6.25958 23.4861L13.6917 16.054L23.4303 25.0238L25.9931 23.4861L31.5031 28.8679" stroke="#B4A27F" />
            <rect x="33.9727" y="17.9236" width="4.55365" height="4.55365" fill="#B4A27F" />
            <rect x="6.25958" y="17.9229" width="15.2958" height="3.37939" fill="white" />
            <path d="M4.58441 19.5935H38.5263" stroke="#B4A27F" stroke-dasharray="2 2" />
          </svg>

          <div class="col jc-c">
            <div class="text1">ESTIMATED BUDGET</div>
          </div>
        </div>
        <div class="ta-r">
          <div class="text1">{{leg.budgetCurCode}} {{frp?.estimatedBudget | number}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="widget__field">
        <mat-label class="title">Additional Information</mat-label>
        <mat-form-field style="width: 100%" appearance="outline">
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" formControlName="note"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="frp && frp.legs.length" class="m-10">
      <mgl-map accessToken="pk.eyJ1Ijoidml0YWxpaXQiLCJhIjoiY2tmdHMzMzVsMHEwYzJ6c3ZteDh1ZXk2biJ9.h6wE6hQg_iyMbsBvyGY98A" [style]="'mapbox://styles/vitaliit/ckftu0xlz2c8t19qpk14gxkdk'" [fitBounds]="mapCoordinates" [fitBoundsOptions]="{ padding: 20 }" [scrollZoom]="false" [dragRotate]="false" [touchPitch]="false" [touchZoomRotate]="false" [pitchWithRotate]="false" [dragPan]="false" [doubleClickZoom]="false" [attributionControl]="false" (load)="mapLoad($event)">
        <ng-container *ngFor="let leg of frp?.legs; index as i">
          <mgl-layer *ngIf="leg.from?.location?.coordinates && leg.to?.location?.coordinates" [id]="'route'+i" type="line" [source]="{
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': [
                      leg.from.location.coordinates,
                      leg.to.location.coordinates
                    ]
                }
            }
          }" [layout]="{
            'line-join': 'round',
            'line-cap': 'round'
          }" [paint]="{
            'line-color': '#2A2F45',
            'line-width': 2
          }">
          </mgl-layer>
          <mgl-image [id]="'from'+i" url="https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Pin_from.svg/24px-Pin_from.svg.png">
          </mgl-image>
          <mgl-image [id]="'to'+i" url="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Pin_to.svg/24px-Pin_to.svg.png">
          </mgl-image>
          <mgl-layer *ngIf="leg.from.location.coordinates" [id]="'pointfrom'+i" type="symbol" [source]="{
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: leg.from.location.coordinates
              }
            }
          }" [layout]="{ 'icon-image': 'from'+i }"></mgl-layer>
          <mgl-layer *ngIf="leg.to.location.coordinates" [id]="'pointto'+i" type="symbol" [source]="{
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: leg.to.location.coordinates
              }
            }
          }" [layout]="{ 'icon-image': 'to'+i }"></mgl-layer>
        </ng-container>
      </mgl-map>
    </div>
  </form>
</mat-expansion-panel>
