import { Component } from '@angular/core';

@Component({
  selector: 'lib-not-allow-edit',
  templateUrl: './not-allow-edit.component.html',
  styleUrls: ['./not-allow-edit.component.scss']
})
export class NotAllowEditComponent {

}
