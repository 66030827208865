<div class="modal-header">
	<span class="modal-header__text" [class.red]="data.alert">{{data.title}}</span>
	<button mat-icon-button mat-dialog-close>
		<img src="assets/img/cancel.svg" alt="">
	</button>
</div>
<form class="modal-container" [formGroup]="alertForm">
	<div class="modal-container__text" [innerHtml]="data.text" [class.red]="data.alert" [class.center]="data.textarea || data.input"></div>
	<mat-form-field *ngIf="data.input" class="comment" appearance="outline">
		<mat-label>Leave a comment</mat-label>
		<input matInput placeholder="Ex. The reason of this action is..." formControlName="shortComment"/>
	</mat-form-field>
	<mat-form-field *ngIf="data.textarea" class="comment" appearance="outline">
		<mat-label>Leave a comment</mat-label>
		<textarea matInput placeholder="Ex. The reason of this action is..." cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="14" formControlName="longComment"></textarea>
	</mat-form-field>
</form>
<div *ngIf="!data.confirm" class="modal-bottom">
	<button mat-stroked-button class="modal-bottom__btn" mat-dialog-close>OK</button>
</div>
<div *ngIf="data.confirm" class="modal-bottom" (keydown.enter)="confirmModal()">
	<button class="modal-bottom__btn d-f jc-c ai-c cancel" (click)="cancel()" mat-stroked-button mat-dialog-close>No</button>
	<button mat-stroked-button class="modal-bottom__btn d-f jc-c ai-c ml-10" (click)="confirmModal()">Yes</button>
</div>
