<div [matMenuTriggerFor]="defaultPopup" #trigger="matMenuTrigger" #triggerElement>
  <ng-content></ng-content>
</div>
<mat-menu #defaultPopup="matMenu" class="wp-100" [hasBackdrop]="!hoverOpen">
  <div class="pointer" [cdkCopyToClipboard]="copyOnClick">
    <div *ngIf="head" class="menu-title">{{head}}</div>
    <div *ngIf="head" class="menu-divider"></div>
    <div class="menu-desc">{{description}}<ng-content select="[description]"></ng-content></div>
  </div>
</mat-menu>
