<mat-expansion-panel class="widget mat-elevation-z" hideToggle [expanded]="expanded">
  <mat-expansion-panel-header class="widget__header">
      <mat-panel-title>
          <div class="expand-icon" [class._active]="false">
              <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
              </svg>
          </div>
          <div class="title">Billing information</div>
      </mat-panel-title>
      <mat-panel-description class="description">
          <div class="row jc-fe">
              <div class="description__first">Account Type</div>
              <div class="description__second sentry-mask">{{client?.type === 'business' ? 'Business' : 'Private' }}</div>
          </div>
          <div class="row jc-fe" *ngIf="client?.billingInfo?.preferredPaymentType">
              <div class="description__first">Payment method</div>
              <div class="description__second sentry-mask">{{client?.billingInfo?.preferredPaymentType === 'creditCard' ? 'Credit Card' : 'Apple Pay'}}</div>
          </div>
      </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="widget__body">
      <div class="row" *ngIf="client?.type === 'business'">
          <div class="widget__field">
              <div class="title">Company name</div>
              <div class="value sentry-mask">{{client?.companyName}}</div>
          </div>
          <div class="widget__field">
              <div class="title">ATTN to</div>
              <div class="value sentry-mask">{{client?.attn}}</div>
          </div>
      </div>
      <div class="row" *ngIf="!client?.type || client?.type === 'private'">
        <div class="widget__field">
            <div class="title">Title</div>
            <div class="value sentry-mask">{{client?.title}}</div>
        </div>
        <div class="widget__field">
            <div class="title">First name</div>
            <div class="value sentry-mask">{{client?.firstName}}</div>
        </div>
        <div class="widget__field">
            <div class="title">Last name</div>
            <div class="value sentry-mask">{{client?.lastName}}</div>
        </div>
    </div>
      <div class="row">
          <div class="widget__field">
              <div class="title">Mobile Phone Number</div>
              <div class="value sentry-mask">{{client?.phoneNumber}}</div>
          </div>
          <div class="widget__field" *ngIf="client?.type === 'business'">
              <div class="title">VAT</div>
              <div class="value sentry-mask">{{client?.vat}}</div>
          </div>
      </div>
      <div class="row">
          <div class="widget__field">
              <div class="title fl-cap">{{client?.type === 'business' ? 'Company' : ''}} billing address</div>
              <div class="value sentry-mask">{{client?.billingAddress}}</div>
          </div>
      </div>
      <div class="row">
          <div class="widget__field">
              <div class="title fl-cap">{{client?.type === 'business' ? 'Company' : ''}} email</div>
              <div class="value sentry-mask">{{client?.email}}</div>
          </div>
      </div>
  </div>
</mat-expansion-panel>
