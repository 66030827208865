import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ElementRef, ViewChild, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { debounce, first, map, tap } from 'rxjs/operators';
import { ChatMessage, ChatService } from 'common';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('chatcontent') chatcontent!: ElementRef;
  scrolltop: number | null = null;
  chatId = '';
  userId = '';
  chatMessages$ = new BehaviorSubject<Array<ChatMessage>>([]);
  chatMessagesSub: Subscription | null = null;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ChatComponent>,
    @Inject(MAT_DIALOG_DATA) public offer: string,
    private changeDetection: ChangeDetectorRef,
    private afAuth: AngularFireAuth,
    private chatService: ChatService,
  ) {}

// TODO loading message or spinner

  ngOnInit(): void {
    this.afAuth.user.pipe(first())
      .subscribe(user => {
        this.userId = user?.uid || '';
      });

    this.chatId = this.offer;
    if (this.chatId) {
      this.chatService.createChat(this.chatId)
        .pipe(first())
        .subscribe(
          () => this.chatService.subscribeChat(this.chatId, this.updateMessagesList),
          console.log, // TODO message
        );
    } else {
      alert('No chat id.'); // TODO message
    }

    this.chatMessagesSub = this.chatMessages$
      .pipe(
        map(results => results.sort((a, b) => new Date(a.data.sentDate).getTime() - new Date(b.data.sentDate).getTime())),
        debounce(() => timer(500)),
        tap(() => {
          this.scrolltop = this.chatcontent.nativeElement.scrollHeight;
          this.changeDetection.detectChanges();
        }),
      )
      .subscribe();
  }

  updateMessagesList = (message: ChatMessage): void => {
    this.chatMessages$.next(this.chatMessages$.value.concat(message));
    this.changeDetection.detectChanges();
  }

  onNoClick(): void {
    this.chatService.unSubscribeChat(this.chatId);
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.chatService.unSubscribeChat(this.chatId);
    this.chatMessagesSub?.unsubscribe();
  }
}
