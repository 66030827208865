import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  public timeFormat: string;
  constructor() {
    this.timeFormat = localStorage.getItem('timeFormat') || 'HH:mm';
  }
}
