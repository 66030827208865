import { Component, OnInit } from '@angular/core';
import { debounceTime, Observable, Subject, Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { ToolbarService } from './toolbar.service';
import { UntypedFormControl } from '@angular/forms';
import { PlatformService } from 'common';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {
  curTitle$: Observable<string> = this.toolbarService.getTitleObject();
  curEmail = '';
  activeComponent!: any;
  search = new UntypedFormControl('');
  tabletSubscription!: Subscription;
  isTablet$: Observable<boolean>;

  constructor(
    public router: Router,
    public afAuth: AngularFireAuth,
    private toolbarService: ToolbarService,
    private platformService: PlatformService,
  ) {
    this.search.valueChanges.pipe(debounceTime(600)).subscribe(value => {
      if (this.activeComponent?.searchOnShell) {
        if (this.activeComponent?.searchOnShell instanceof Subject) {
          this.activeComponent?.searchOnShell.next(value);
        } else this.activeComponent.searchOnShell(value);
      }

    });
    this.isTablet$ = this.platformService.isTablet$;
  }

  ngOnInit(): void {
    this.afAuth.user.subscribe(user => this.curEmail = user?.email || '');
  }

  onActivate(component: unknown): void {
    if (this.search.dirty) this.search.reset();
    this.activeComponent = component;
  }

  signOut(): void {
    this.afAuth.signOut()
      .then(() => {
        this.router.navigate([ '/signin' ]);
      })
      .catch(err => {
        console.log(`Logout error: ${ err }`);
      });
  }
}
