import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { map, Observable, shareReplay } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  isMobileMini$: Observable<boolean>;
  isMobile$: Observable<boolean>;
  isTablet$: Observable<boolean>;
  constructor(
    private platform: Platform,
    private breakpointObserver: BreakpointObserver,
    ) {
    this.isTablet$ = this.breakpointObserver.observe('(max-width: 1200px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
    this.isMobile$ = this.breakpointObserver.observe('(max-width: 992px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
    this.isMobileMini$ = this.breakpointObserver.observe('(max-width: 768px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  }
  browserSupport(): boolean {
    if (navigator.userAgent.indexOf('Safari') !== -1 && this.platform.SAFARI) {
      /* Safari */
      return true;
    } else {
      document.documentElement.classList.add('scroll-not-safari');
      if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
        /* Opera */
        return true;
      } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        /* Chrome */
        return true;
      } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        /* Firefox */
        return true;
      } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
        /* IE */
        return false;
      } else {
        return false;
      }
    }
  }
}
