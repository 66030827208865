import { Pipe, PipeTransform } from '@angular/core';
import { Operator } from '../interfaces/Operator';

@Pipe({name: 'getOperatorEmailAndPhone'})
export class GetOperatorEmailAndPhone implements PipeTransform {

  transform(operator: Operator): { email: string, phone: string } {
    if(!operator) return { email: '', phone: '' };
    return {
      email: operator.email || operator.departments?.find(o=>o.email)?.email || operator.owners?.find(o=>o.email)?.email || '',
      phone: operator.phoneNumber || operator.departments?.find(o=>o.phone)?.phone || operator.owners?.find(o=>o.phoneNumber)?.phoneNumber || ''
    };
  }
}
