import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';

@Injectable({
  providedIn: 'root'
})
export class AdditionalServicesService {
  additionalServicesUrl = `${ this.globalSharedService.config.baseUrl }/additional-services`;

  constructor(
    private globalSharedService: GlobalSharedService,
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  getAdditionalServices(bookingId: string): Observable<any> {
    return this.http.get<any>(`${this.additionalServicesUrl}?bookingId=${bookingId}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createService(body: any): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.additionalServicesUrl, body).pipe(
        catchError(this.errorService.handleError)
      );
  }
}
