<div class="modal-header">
	<span class="modal-header__text">Create flight offer with unusual price</span>
	<button mat-icon-button mat-dialog-close>
		<img src="assets/img/cancel.svg" alt="">
	</button>
</div>
<form class="modal-container">
	<div class="modal-container__text">
    You have set an unusual price. Please confirm you wish to create an empty leg offer with a total price of <span class="fill-gold">({{data}})</span> ?
  </div>
</form>
<div class="modal-bottom" >
	<button class="modal-bottom__btn d-f jc-c ai-c cancel" mat-stroked-button mat-dialog-close>No, Amend Price</button>
	<button mat-stroked-button class="modal-bottom__btn d-f jc-c ai-c ml-10" [mat-dialog-close]="true">Yes, Create</button>
</div>
