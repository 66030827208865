import { Component, EventEmitter, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { UserService } from '../../../services/api/user.service';
import { SnackService } from '../../../services/helpers/snack.service';
@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ]
})

export class LoginModalComponent {
  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [ Validators.required, Validators.email ]),
    password: new UntypedFormControl('', [ Validators.required, Validators.minLength(6) ]),
  });
  resetForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [ Validators.required, Validators.email ]),
  });
  step = 0;
  isOperator: boolean;
  loading?: boolean;
  currentPage?: { url: string, params: string; };
  lottieSpinner: { [ x: string ]: string; } = {
    path: 'assets/json/loading-dots.json',
  };
  @Output() logined = new EventEmitter();
  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private snackSrv: SnackService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.isOperator = this.userService.isOperator;
    this.route.queryParams
      .pipe(first())
      .subscribe(params => {
        this.currentPage = {
          url: this.router.url.split('?')[ 0 ],
          params: JSON.stringify(params)
        };
      }
      );
  }

  onSubmit(): void {
    this.loading = true;
    try {
      this.afAuth.signInWithEmailAndPassword(this.loginForm.value.email, this.loginForm.value.password)
        .then(() => {
          this.logined.emit();
        })
        .catch(err => {
          this.snackSrv.systemError(err.message.replace('Firebase: ', '').split('(')[ 0 ]);
        });
    } catch (err) {
      this.snackSrv.systemError(err as string);
    }
    this.loading = false;
  }

  resetPassword(): void {
    this.loading = true;
    this.userService.sendPasswordResetEmail(this.resetForm.value.email)
    .pipe(first())
    .subscribe({
      next: () => {
        this.step = 0;
        this.clear();
        this.loading = false;
      },
      error: (err) => {
        this.snackSrv.systemError(err.error.message);
        this.loading = false;
      }
    });
  }

  openNextStep(): void {
    this.step += 1;
  }

  back(): void {
    this.step -= 1;
    this.clear();
  }

  clear(): void {
    this.loginForm.reset();
    this.resetForm.reset();
  }
}
