<div class="modal">
  <form (ngSubmit)="save()" [formGroup]="tabForm">
    <div class="modal-header">
      <span class="modal-header__text">Edit operator</span>
      <button mat-button class="close-icon" mat-dialog-close>
        <img src="assets/img/cancel.svg" alt="">
      </button>
    </div>
    <div class="modal-content">
      <mat-form-field hideRequiredMarker = true>
        <input matInput type="email" formControlName="email" placeholder="Email">
      </mat-form-field>
    </div>
    <div class="modal-footer">
      <div class="footer-bottom">
        <div class="d-f jc-fe">
          <button mat-stroked-button type="button" class="footer-bottom__cancel modal-actions mr-15" (click)="close();">Cancel</button>
          <button mat-raised-button [disabled]="tabForm.invalid" class="footer-bottom__ok modal-actions">Ok</button>
        </div>
      </div>
    </div>
  </form>
</div>
