import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';

@Injectable({
  providedIn: 'root'
})
export class LegsService {
  private legUrl!: string;
  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
  ) {
    this.legUrl = `${this.globalSharedService.config.baseUrl}/legs`;
  }

  updateLegsById(legsData: any): Observable<unknown> {
    return this.http.patch<unknown>(`${this.legUrl}`, legsData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

}
