import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
  selector: '[libBack]'
})
export class BackDirective {

  constructor(private location: Location) { }
  @HostListener('click', ['$event']) onClick(){
      this.location.back()
  }

}
