import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { FeaturedFlight } from '../../interfaces/FeaturedFlight';
import { User } from '../../interfaces/User';
import { FeaturedFlightsService } from '../../services/api/featured-flights.service';
import { UserService } from '../../services/api/user.service';
import { SnackService } from '../../services/helpers/snack.service';
import { ActionAlertComponent } from '../../components/modals/action-alert/action-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { Booking } from '../../interfaces/Booking';
import { Bid } from '../../interfaces/Bid';
import { AirportDatetimeService } from '../../services/helpers/airport-datetime.service';
import { BookingsService } from '../../services/api/bookings.service';
import { Duration } from 'luxon';

@Component({
  selector: 'lib-featured-flight-confirmation',
  templateUrl: './featured-flight-confirmation.component.html',
  styleUrls: [ './featured-flight-confirmation.component.scss' ]
})
export class FeaturedFlightConfirmationComponent implements OnInit {
  curUser!: User;
  featuredFlight?: FeaturedFlight;
  booking?: Booking;
  flightForm = new UntypedFormGroup({
    emptyLeg: new UntypedFormControl({ value: false, disabled: true }),
    description: new UntypedFormControl({ value: '' }),
    takeoffTime: new UntypedFormControl('00:00', Validators.required),
    landingTime: new UntypedFormControl('00:00', Validators.required),
    flightTime: new UntypedFormControl('00:00', Validators.required),
  });
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private featuredFlightsService: FeaturedFlightsService,
    private snack: SnackService,
    private dialog: MatDialog,
    private airportDatetimeSrv: AirportDatetimeService,
    private bookingService: BookingsService,
  ) { }

  ngOnInit(): void {
    this.getUserInfo();
    this.getFeaturedFlight();
  }

  getFeaturedFlight(): void {
    this.route.queryParams
      .pipe(first())
      .subscribe(params => {
        if (params.featuredFlight) {
          this.featuredFlightsService.getFeaturedFlight(params.featuredFlight).subscribe((flight) => {
            if (params.bookingId) this.booking = flight.bookings.find((booking) => booking.id === params.bookingId);
            this.featuredFlight = flight;
            this.flightForm.patchValue({
              emptyLeg: flight.emptyLeg,
              description: this.booking?.bookingLegs[ 0 ].cargoDescription,
              takeoffTime: this.booking ? this.airportDatetimeSrv.getAirportDate(this.booking.bookingLegs[ 0 ].takeoffDate, this.booking.bookingLegs[ 0 ].from, 'string_time') : '00:00',
              landingTime: (this.booking && this.booking.bookingLegs[ 0 ].landingDate) ? this.airportDatetimeSrv.getAirportDate(this.booking.bookingLegs[ 0 ].landingDate, this.booking.bookingLegs[ 0 ].to, 'string_time') : '00:00',
            });
            this.flightTimeChange();
          });
        }
      });
  }
  getUserInfo(): void {
    this.userService.getUserInfo()
      .pipe(first())
      .subscribe(resp => {
        this.curUser = resp;
        this.curUser.operator = resp.operatorAccountOwner || resp.operatorAccountAdmin;
      });
  }

  flightTimeChange(): void {
    if (this.booking?.bids[ 0 ]) {
      if (this.flightForm.get('takeoffTime')?.value === 'Invalid DateTime' || this.flightForm.get('landingTime')?.value === 'Invalid DateTime') {
        this.flightForm.get('flightTime')?.setValue('00:00');
      } else {
        const takeoffDateTime = this.airportDatetimeSrv.makeAirportDateTime((this.booking.bids[ 0 ] as Bid).takeoffDate, this.booking.bids[ 0 ].from)
          .set({ hour: this.flightForm.get('takeoffTime')?.value?.split(':')[ 0 ], minute: this.flightForm.get('takeoffTime')?.value?.split(':')[ 1 ] });
        let landingDateTime = this.airportDatetimeSrv.makeAirportDateTime((this.booking.bids[ 0 ] as Bid).takeoffDate, this.booking.bids[ 0 ].to).set({ hour: this.flightForm.get('landingTime')?.value?.split(':')[ 0 ], minute: this.flightForm.get('landingTime')?.value?.split(':')[ 1 ] });
        if (landingDateTime.diff(takeoffDateTime).milliseconds < 0) landingDateTime = landingDateTime.plus({ days: 1 });
        const flightTime = landingDateTime.diff(takeoffDateTime);
        let time = flightTime.toFormat('hh:mm');
        if (flightTime.valueOf() < 0) {
          time = Duration.fromMillis(86400000 + flightTime.valueOf()).toFormat('hh:mm');
        }
        this.flightForm.get('flightTime')?.setValue(time);
      }
    }
  }

  flightFormToUpdateBid(): any {
    if (this.booking) {
      const tempBidsToUpdate: Array<Record<any, any>> = JSON.parse(JSON.stringify([ ...this.booking.bookingLegs ]));
      const bookingLegsToUpdate: Array<Record<any, any>> = [];
      tempBidsToUpdate.forEach(tempBidToCreate => {
        const takeoffDateTime = this.airportDatetimeSrv.makeAirportDateTime((tempBidToCreate as Bid).takeoffDate, tempBidToCreate.from)
          .set({ hour: this.flightForm.get('takeoffTime')?.value?.split(':')[ 0 ], minute: this.flightForm.get('takeoffTime')?.value?.split(':')[ 1 ] });
        let landingDateTime = this.airportDatetimeSrv.makeAirportDateTime((tempBidToCreate as Bid).takeoffDate, tempBidToCreate.to).set({ hour: this.flightForm.get('landingTime')?.value?.split(':')[ 0 ], minute: this.flightForm.get('landingTime')?.value?.split(':')[ 1 ] });
        if (landingDateTime.diff(takeoffDateTime).milliseconds < 0) landingDateTime = landingDateTime.plus({ days: 1 });
        const bid = {
          id: tempBidToCreate.id,
          takeoffDate: takeoffDateTime
            .set({ hour: this.flightForm.get('takeoffTime')?.value?.split(':')[ 0 ], minute: this.flightForm.get('takeoffTime')?.value?.split(':')[ 1 ], second: 0 }).toUTC().toISO(),
          takeoffTime: this.flightForm.get('takeoffTime')?.value,
          landingDate: landingDateTime.toUTC().toISO(),
          landingTime: this.flightForm.get('landingTime')?.value,
          flightTimeHours: this.flightForm.get('flightTime')?.value?.split(':')[ 0 ],
          flightTimeMinutes: this.flightForm.get('flightTime')?.value?.split(':')[ 1 ],
        };
        bookingLegsToUpdate.push(bid);
      });
      return bookingLegsToUpdate;
    }
  }

  confirmFlight(): void {
    if (this.flightForm.get('flightTime')?.value === '00:00') return this.snack.systemError(`Flight time should be greater then zero`, 5000);
    if (!this.flightForm.valid) return this.snack.systemError('Please complete the required fields', 5000);
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'CONFIRM FLIGHT OFFER',
        text: 'Are you sure you wish to confirm this flight offer?',
        confirm: () => {
          this.bookingService.updateBookingLegs(this.booking?.id, this.flightFormToUpdateBid()).subscribe(() => {
            if (this.featuredFlight?.id) {
              this.featuredFlightsService.confirmFeaturedFlightBooking(this.featuredFlight.id).subscribe((flight) => {
                this.router.navigate([ '/flight-details' ], { queryParams: { booking: this.booking?.id } });
              }, (err) => {
                return this.snack.systemError(err.message, 6000);
              });
            }
          });
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  cancelFlight(): void {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'CANCEL FLIGHT OFFER',
        text: `Are you sure you wish to cancel this offer? This action can't be undone. All money will be returned to the client tokens balance.`,
        confirm: () => {
          if (this.featuredFlight?.id) this.featuredFlightsService.cancelFeaturedFlightBookings(this.featuredFlight.id).subscribe((flight) => {
            this.router.navigate([ '/featured-flights' ]);
          }, (err) => {
            return this.snack.systemError(err.message, 6000);
          });
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  get bookingLeg(): Bid | undefined {
    return this.booking?.bookingLegs[ 0 ];
  }
}
