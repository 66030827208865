import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-unusual-price',
  templateUrl: './unusual-price.component.html',
  styleUrls: ['./unusual-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnusualPriceComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

}
