import { NgModule } from '@angular/core';
import { ShellComponent } from './shell/shell.component';
import { MaintenanceBannerComponent } from '../maintenance/maintenance-banner/maintenance-banner.component';
import { GlobalSharedModule } from 'common';

const components = [
  ShellComponent,
  MaintenanceBannerComponent,
];

const modules = [
  GlobalSharedModule,
];

@NgModule({
  declarations: components,
  imports: modules,
  exports: [
    ...components,
    ...modules,
  ]
})
export class SharedModule { }
