import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs';
import { OperatorsService } from '../../../services/api/operators.service';

@Component({
  selector: 'lib-reviews-modal',
  templateUrl: './reviews-modal.component.html',
  styleUrls: ['./reviews-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewsModalComponent implements OnInit {
  reviews: Array<{
    author: { firstName: string; lastName: string }
    created: string;
    value: number;
    comment: string;
  }> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public operatorId: string,
    public dialogRef: MatDialogRef<ReviewsModalComponent>,
    private operatorsService: OperatorsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.operatorId) {
      this.operatorsService.getOperatorRates(this.operatorId).pipe(first()).subscribe((rates: any) => {
        this.reviews = rates
        this.cd.detectChanges();
      });
    }
  }
}