import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { Bid } from '../../interfaces/Bid';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  private offersUrl!: string;
  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
    ) {
      this.offersUrl = `${this.globalSharedService.config.baseUrl}/offers`;
    }

  getOfferById(id: string): Observable<Bid[]> {
    return this.http.get<Bid[]>(`${this.offersUrl}/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  cancelOffer(offerId: string): Observable<unknown> {
    return this.http.post<unknown>(`${ this.offersUrl }/${ offerId }/cancel`, null)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getOfferServiceFee(offerId: string): Observable<any> {
    return this.http.get<any>(`${ this.offersUrl }/${ offerId }/service-fee`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
