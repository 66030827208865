import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../../services/helpers/snack.service';

@Component({
  selector: 'lib-edit-balance',
  templateUrl: './edit-balance.component.html',
  styleUrls: ['./edit-balance.component.scss']
})
export class EditBalanceComponent implements OnInit {

  form = new FormGroup({
    amount: new FormControl(0, [ Validators.required ]),
    description: new FormControl(''),
  });
  constructor(
    public dialogRef: MatDialogRef<EditBalanceComponent>,
    private snackService: SnackService,
    @Inject(MAT_DIALOG_DATA) public data: {
      balance: number,
    },
  ) { }

  ngOnInit(): void {
    this.form.patchValue({
      amount: this.data.balance,
    });
  }

  confirmModal(): void {
    if(!this.form.valid) return this.snackService.systemError('Please enter correct balance', 5000);
    this.dialogRef.close(this.form.value)
  }

}
