import { Component, Input, SimpleChanges } from '@angular/core';
import { FRP } from '../../../interfaces/FRP';

@Component({
  selector: 'lib-flight-preferences',
  templateUrl: './flight-preferences.component.html'
})
export class FlightPreferencesComponent {
  @Input() frp?: FRP;
  @Input() expanded!: boolean;

  hasPreferences(): boolean {
    return !!this.frp?.preferences && !!Object.keys(this.frp.preferences as object).length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.frp && changes.frp.currentValue) {
      this.expanded = this.hasPreferences();
    }
  }
}
