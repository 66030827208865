import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { Observable, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {

  balanceUrl = `${this.globalSharedService.config.baseUrl}/balance`;

  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService
  ) { }

  getTopupBalanceInfo(data: any): Observable<any> {
    return this.http.post<any>(`${this.balanceUrl}/top-up/info`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  topupBalance(data: any): Observable<any> {
    return this.http.post<any>(`${this.balanceUrl}/top-up`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

}
