import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  curTitle = new BehaviorSubject('OPES JET Admin');

  getTitleObject(): BehaviorSubject<string> {
    return this.curTitle;
  }
}
