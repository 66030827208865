import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { ServiceModalComponent } from '../../modals/service-modal/service-modal.component';
import { AdditionalServicesService } from '../../../services/api/additional-services';

export interface Service {
  title: string;
  status: string;
  created: string;
  price: number;
  currency: string;
}

@Component({
  selector: 'lib-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesComponent {

  services: Service[] = []

  @Input() bookingId?: string;
  @Output() onUpdate = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private additionalServicesService: AdditionalServicesService,
    private cd: ChangeDetectorRef
  ) { }

  onWidgetExpand(): void {
    if (!this.services?.length) {
      this.getAdditionalServices()
    }
  }

  getAdditionalServices(): void {
    if (this.bookingId) {
      this.additionalServicesService.getAdditionalServices(this.bookingId).pipe(first()).subscribe((services: any) => {
        this.services = services
        this.cd.detectChanges()
      })
    }
  }

  openCreateService(): void {
    const dialogRef = this.dialog.open(ServiceModalComponent, {
      width: '486px',
      data: { bookingId: this.bookingId },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe(() => {
      this.getAdditionalServices()
      this.cd.detectChanges()
    })
  }
}
