<mat-expansion-panel class="widget mat-elevation-z request-menu" hideToggle>
  <mat-expansion-panel-header class="widget__header">
    <mat-panel-title>
      <div class="expand-icon" [class._active]="false">
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
        </svg>
      </div>
      <div class="title">Tags</div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="widget__body request">
    <mat-form-field style="width: 100%" appearance="outline">
      <mat-label>Tags</mat-label>
      <mat-chip-grid #chipList aria-label="Tag selection">
        <mat-chip-row *ngFor="let tag of tags; index as i" (removed)="removeTag(tag, i)">
          {{tag.name}}
          <button matChipRemove>
            <img src="assets/img/cancel.svg" alt="">
          </button>
        </mat-chip-row>
        <input class="tt-u" placeholder="New tag..." #fruitInput [formControl]="tagCtrl" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
      </mat-chip-grid>
    </mat-form-field>
  </div>
</mat-expansion-panel>
