import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SnackService } from 'common';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

/*  constructor(private afAuth: AngularFireAuth, private snackSrv: SnackService) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = !!await this.afAuth.currentUser;
    if (!isLoggedIn) { this.snackSrv.authError(); }
    return isLoggedIn;
  }*/
  constructor(
    private afAuth: AngularFireAuth,
    private snackSrv: SnackService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.afAuth.user.pipe(
      tap(user => {
        if (!user) {
          this.snackSrv.authError(state);
          // this.router.navigate([ 'signin' ], { queryParams: { returnUrl: state.url } });
        }
      }),
      map(user => !!user )
    );
  }
}
