import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchUrl!: string;
  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
  ) {
    this.searchUrl = `${this.globalSharedService.config.baseUrl}/search`;
  }

  search(query = {}): Observable<any> {
    return this.http.get<any>(this.searchUrl, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
