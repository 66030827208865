import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'lib-reject-offer',
  templateUrl: './reject-offer.component.html',
  styleUrls: ['./reject-offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RejectOfferComponent {

  customReason = new FormControl('', Validators.required);
  selectedReason: any = {
    dislike_aircraft: false,
    dislike_price: false,
    other: false,
  };


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RejectOfferComponent>
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      if (this.data.cancel) this.data.cancel();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape' && this.data.cancel) this.data.cancel();
    });
  }

  setReason(event: MatSlideToggleChange, key: 'dislike_aircraft' | 'dislike_price' | 'other'): void {
    this.selectedReason.dislike_aircraft = false;
    this.selectedReason.dislike_price = false;
    this.selectedReason.other = false;
    this.customReason.reset();

    this.selectedReason[key] = event.checked;
  }

  get anyReasonSelected(): boolean {
    if (Object.values(this.selectedReason).some(val => val === true)) {
      if (!this.selectedReason.other) return true;
      else {
        return this.customReason.valid;
      }
    } else return false;
  }

  get rejectionReason(): string {
    const r = Object.keys(this.selectedReason).find(key => this.selectedReason[key] === true);
    if (r) {
      if (r === 'other') return this.customReason.value || '';
      else {
        return r;
      }
    } else return '';
  }


}
