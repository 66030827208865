import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './user/auth.guard';

const routes: Routes = [
  { path: '', component: DashboardComponent, data: { title: 'Dashboard' } },
  { path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard' } },
  { path: 'signin', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'waiting-list',
    loadChildren: () => import('./waiting-list/waiting-list.module').then(m => m.WaitingListModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'operators',
    loadChildren: () => import('./operators/operators.module').then(m => m.OperatorsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'wingX',
    loadChildren: () => import('./wingX/wingX.module').then(m => m.WingXModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'frps',
    loadChildren: () => import('./frps/frps.module').then(m => m.FrpsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bookings',
    loadChildren: () => import('./bookings/bookings.module').then(m => m.BookingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'clients-invites',
    loadChildren: () => import('./clients-invites/clients-invites.module').then(m => m.ClientsInvitesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unsubscribers-list',
    loadChildren: () => import('./unsubscribers-list/unsubscribers-list.module').then(m => m.UnsubscribersListModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'clients-discounts',
    loadChildren: () => import('./discounts/discounts.module').then(m => m.DiscountsModule),
    canActivate: [AuthGuard],
  },
  { path: 'chats',
    loadChildren: () => import('./chats/chats.module').then(m => m.ChatsModule),
    canActivate: [ AuthGuard ],
  },
  { path: 'airports',
    loadChildren: () => import('./airports/airports.module').then(m => m.AirportsModule),
    canActivate: [ AuthGuard ],
  },
  { path: 'aircraft-categories',
    loadChildren: () => import('./aircraft/aircraft-category/categories-list/categories-list.module').then(m => m.CategoriesListModule),
    canActivate: [ AuthGuard ],
  },
  {
    path: 'rates',
    loadChildren: () => import('./rates/rates.module').then(m => m.RatesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'deleted-accounts',
    loadChildren: () => import('./delaccs/delaccs.module').then(m => m.DelaccsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'download-report',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
