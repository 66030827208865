import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { Observable, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  private currencyUrl!: string;
  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private globalSharedService: GlobalSharedService,
  ) {
    this.currencyUrl = `${this.globalSharedService.config.baseUrl}/currency`;
  }

  getExchangeRates(): Observable<{ [x: string]: number; }> {
    return this.http.get<{ [x: string]: number; }>(`${this.currencyUrl}/exchange-rates`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
