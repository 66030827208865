import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { RatesService } from '../../../services/api/rates.service';
import { AdditionalServicesService } from '../../../services/api/additional-services';

@Component({
  selector: 'lib-service-modal',
  templateUrl: './service-modal.component.html',
  styleUrls: ['./service-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceModalComponent implements OnInit {
  serviceForm: FormGroup;
  currentRates: any
  exchangeRate: number = 1;
  convertedValue: number = 0;

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private ratesService: RatesService,
    private additionalServicesService: AdditionalServicesService,
    @Inject(MAT_DIALOG_DATA) 
    public data: { bookingId: string }
  ) { 
    this.serviceForm = this.fb.group({
      name: ['', [Validators.required]],
      price: [null, [Validators.required, Validators.min(0)]],
      currency: ['EUR', [Validators.required]],
      description: [''],
    });
  }

  ngOnInit(): void {
    this.serviceForm.get('currency')?.valueChanges.subscribe((currency) => {
      this.updateExchangeRate(currency);
    });

    this.serviceForm.get('price')?.valueChanges.subscribe((price) => {
      this.updateConvertedValue(price);
    });

    this.getExchangeRates();
  }

  updateExchangeRate(currency: string): void {
    this.exchangeRate = this.currentRates[currency] / 100;
    this.updateConvertedValue(this.serviceForm.get('price')?.value);

    this.cd.detectChanges();
  };

  getExchangeRates(): void {
    this.ratesService.getCurrentRates().pipe(first()).subscribe(result => {
      this.currentRates = result
      this.updateExchangeRate('EUR')
    });
  }

  updateConvertedValue(price: number): void {
    if (price != null) {
      this.convertedValue = price * this.exchangeRate;
    }
  }

  createAdditionalService(): void {
    const formValues = this.serviceForm.value;

    this.additionalServicesService.createService({
      bookingId: this.data.bookingId,
      title: formValues.name,
      description: formValues.description,
      price: formValues.price,
      currency: formValues.currency
    }).pipe(first()).subscribe(result => {
      console.log('result', result)
    })
  }
}