import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Client } from '../../../interfaces/Client';
import { ClientsService } from '../../../services/api/clients.service';
import { EditBalanceComponent } from './edit-balance/edit-balance.component';
import { first } from 'rxjs/operators';
import { CentsToDollarsPipe } from '../../../pipes/CentsToDollars';
import { NotAllowEditComponent } from './not-allow-edit/not-allow-edit.component';

@Component({
  selector: 'lib-opes-coin',
  templateUrl: './opes-coin.component.html',
})
export class OpesCoinComponent implements OnChanges {
  balance = 0;
  transactions!: any;
  @Input() client?: Client;
  displayedColumns: Array<string> = [ 'name', 'date', 'balance' ];
  constructor(
    private dialog: MatDialog,
    private clientsService: ClientsService,
    private cd: ChangeDetectorRef,
    private centsToDollars: CentsToDollarsPipe,
  ) { }

  ngOnChanges(): void {
    if (this.client?.id) {
      this.clientsService.getClientBalance(this.client?.id, { silent: true })
      .pipe(first())
      .subscribe({
        next: resp => {
          this.balance = resp.balance.amount;
          this.transactions = resp.balanceHistory;
          this.cd.detectChanges();
        },
        error: () => {
          this.balance = 0;
          this.transactions = [];
          this.cd.detectChanges();
        }
      });
    }
  }

  editBalance(): void {
    const dialogRef = this.dialog.open(EditBalanceComponent, {
      width: '378px',
      data: {
        balance: this.centsToDollars.transform(this.balance),
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe((form : { amount: number; description: string }) => {
      if (this.balance === form.amount) return dialogRef.close();
      if (this.client?.id) this.clientsService.updateClientBalance(this.client?.id, {
        amount: (form.amount * 100).toString(),
        hiddenComment: form.description
      })
        .pipe(first())
        .subscribe({
          next: resp => {
            this.balance = resp.balance.amount;
            this.transactions = [ resp.balanceHistory, ...this.transactions ];
            dialogRef.close();
            this.cd.detectChanges();
          },
          error: error => {
            this.dialog.open(NotAllowEditComponent, {
              width: '378px',
              panelClass: 'no-padding-dialog'
            });
            dialogRef.close();
          }
        });
    })
  }

}
