import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GlobalSharedService } from '../global-shared.service';
import { LoadingSpinnerService } from '../services/helpers/loading-spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  loggerUrl = `${this.globalSharedService.config.loggerUrl}`;

  constructor(
    private readonly spinnerService: LoadingSpinnerService,
    private readonly globalSharedService: GlobalSharedService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(this.loggerUrl) || request.params.get('silent')) {
      return next.handle(request);
    }
    this.spinnerService.start(request.url);

    return next.handle(request).pipe(
      finalize(() => this.spinnerService.stop(request.url))
    );
  }
}
