import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmDeactivateGuard } from '../../guards/confirm-deactivate.guard';
import { MakeFeaturedDealComponent } from './make-featured-deal.component';

const routes: Routes = [
  {
    path: '',
    component: MakeFeaturedDealComponent,
    canDeactivate: [ConfirmDeactivateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MakeFeaturedDealRoutingModule { }
