import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, combineLatest, debounce, debounceTime, first, map, skip, Subject, takeUntil, tap, timer } from 'rxjs';
import { Md5 } from 'ts-md5';
import { ActionAlertComponent } from '../../components/modals/action-alert/action-alert.component';
import { Bid } from '../../interfaces/Bid';
import { Booking } from '../../interfaces/Booking';
import { ChatDetailedInfo } from '../../interfaces/ChatDetailedInfo';
import { ChatMessage, ChatMessageData } from '../../interfaces/ChatMessage';
import { Client } from '../../interfaces/Client';
import { Operator } from '../../interfaces/Operator';
import { User } from '../../interfaces/User';
import { BookingStatusPipe } from '../../pipes/BookingStatus';
import { BookingsService } from '../../services/api/bookings.service';
import { ChatService } from '../../services/api/chat.service';
import { UserService } from '../../services/api/user.service';
import { FileService } from '../../services/helpers/file.service';
import { StatusService } from '../../services/helpers/status.service';
import { TimeService } from '../../services/helpers/time.service';
import Reference = firebase.database.Reference;
import { Passenger } from '../../interfaces/Passenger';
import { PassengersService } from '../../services/api/passengers.service';
import { ClientsService } from '../../services/api/clients.service';
import { RenameDocumentComponent } from '../../components/modals/rename-document/rename-document.component';
import { IncreasePassengersNumberComponent } from './increase-passengers-number/increase-passengers-number.component';
import { OperatorsService } from '../../services/api/operators.service';
import { EditPassengerTypeComponent } from './edit-passenger-type/edit-passenger-type.component';

const passengerTypeLegDictionary: any = {
  adult: 'adultsNumber',
  child: 'childrenNumber',
  infant: 'infantsNumber',
  pet: 'petsNumber',
}

@Component({
  selector: 'lib-flight-details',
  templateUrl: './flight-details.component.html',
  styleUrls: ['./flight-details.component.scss']
})
export class FlightDetailsComponent implements OnInit, OnDestroy {
  curUser!: User;
  // legs!: Leg[];
  booking!: Booking;
  isOperator = false;
  errorMsg = '';
  items: Array<Bid> = [];
  @ViewChild('fileInput', { static: false }) public fileInput!: ElementRef;
  // chat
  chatMessages$ = new BehaviorSubject<Array<ChatMessageData>>([]);
  client?: Client;
  operator?: Operator;
  chatForm = new UntypedFormGroup({
    message: new UntypedFormControl(null, this.validatorTrim),
  });
  editingMessageId = '';
  chatId = '';
  isTyping = false;
  @ViewChild('chatcontent') chatcontent!: ElementRef;
  unsubscribe$ = new Subject<void>;
  unReadCount = 0;
  chatRef?: Reference;
  chatUsersByFirebaseId: { [x: string]: User; } = {};
  currentTab = 0;

  passengers$ = new BehaviorSubject<Passenger[]>([]);
  expanded: any[] = [];

  constructor(
    public afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    public timeService: TimeService,
    public userService: UserService,
    public clientsService: ClientsService,
    private metaService: Meta,
    private bookingsService: BookingsService,
    private passengersService: PassengersService,
    private fileService: FileService,
    private bookingsStatus: BookingStatusPipe,
    private dialog: MatDialog,
    private operatorsService: OperatorsService,
    private chatService: ChatService,
    private statusCheckSrv: StatusService,
    private cd: ChangeDetectorRef,
  ) {
    this.isOperator = this.userService.isOperator;
    this.metaService.addTag({ name: 'theme-color', content: '#192d50' });
    document.documentElement.classList.add('ofy-hide');
    document.body.classList.add('ofy-hide');
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.route.queryParams
      .pipe(first())
      .subscribe(params => {
        if (params.booking) this.getBookingById(params.booking);
      });
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=theme-color');
    document.documentElement.classList.remove('ofy-hide');
    document.body.classList.remove('ofy-hide');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.isOperator) {
      this.chatService.unSubscribeChat(this.chatId);
    }
    if (this.chatRef) this.chatService.unSubscribeUnreadMessages(this.chatRef);
  }

  getUserInfo(): void {
    this.userService.getUserInfo()
      .pipe(first())
      .subscribe(resp => {
        this.curUser = resp;
      });
  }

  getBookingById(id: string, onLoad = true): void | Promise<void> {
    this.bookingsService.getBookingById(id)
      .pipe(first())
      .subscribe(resp => {
        this.items = resp.bookingLegs.sort((a, b) => new Date(a.takeoffDate).getTime() - new Date(b.takeoffDate).getTime());
        this.booking = resp;
        this.booking.bookingLegs.forEach(leg => {
          this.expanded.push({
            adults: [],
            childs: [],
            infants: [],
            pets: [],
          } as any)
          if (leg.pets && !leg.petsNumber) leg.petsNumber = 1;
          const tempAdultsNumber = leg.passengers.filter(p => p.type === 'adult').length;
          const tempChildrenNumber = leg.passengers.filter(p => p.type === 'child').length;
          const tempInfantsNumber = leg.passengers.filter(p => p.type === 'infant').length;
          const tempPetsNumber = leg.passengers.filter(p => p.type === 'pet').length;
          for (let k = 0; k < (leg.adultsNumber - tempAdultsNumber); k++) {
            leg.passengers.push({ type: 'adult' } as Passenger);
          }
          for (let k = 0; k < (leg.childrenNumber - tempChildrenNumber); k++) {
            leg.passengers.push({ type: 'child' } as Passenger);
          }
          for (let k = 0; k < (leg.infantsNumber - tempInfantsNumber); k++) {
            leg.passengers.push({ type: 'infant' } as Passenger);
          }
          for (let k = 0; k < (leg.petsNumber - tempPetsNumber); k++) {
            leg.passengers.push({ type: 'pet' } as Passenger);
          }
        });
        if (!this.isOperator) this.passengersService.getPassengersByClientId(this.booking.client.id)
          .pipe(first())
          .subscribe(resp => {
            this.passengers$.next(resp);
          })
        if (onLoad) {
          if (resp.operatorId) this.getOperatorInfo(resp.operatorId);
          this.checkStatus(this.booking.id);
        }
      });
  }
  operatorName!: string;
  getOperatorInfo(id: string): void {
    this.operatorsService.getOperatorById(id).pipe(first()).subscribe(resp => {
      this.operatorName = resp.name;
    });
  }

  onFileSelect(event: Event, type?: string): void {
    if (type === 'agreement' || type === 'invoice' || type === 'payment-confirmation') {
      this.fileService.onFileSelect(event, ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'], file => {
        if (type === 'agreement') {
          this.sendAgreement(file);
        } else if (type === 'invoice') {
          this.sendInvoice(file);
        } else if (type === 'payment-confirmation') {
          this.sendPaymentConfirmation(file);
        }
      });
    } else {
      this.fileService.onFilesSelect(event, ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'], files => {
        if (this.booking) {
          const docs = new DataTransfer();
          for (let i = 0; i < files.length; i++) {
            if (this.booking.docs.some((item) => item === files[i].name || typeof item === 'string' && item.split('/').pop() === files[i].name)) {
              docs.items.add(new File([files[i]], 'copy-' + files[i].name, { type: files[i].type }));
            } else {
              docs.items.add(files[i]);
            }
          }
          if (this.fileInput) this.fileInput.nativeElement.value = '';
          this.sendDoc(docs.files);
        }
      });
    }
  }

  deleteDocumentModal(type: 'agreement' | 'invoice' | 'docs', i?: number): void {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'Are you sure?',
        text: 'Do you really want to delete this document?',
        confirm: () => {
          this.deleteDocument(type, i);
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  sendDoc(files: FileList): void {
    if (this.booking) {
      const formData = new FormData();
      formData.append('bookingId', this.booking.id);
      for (let i = 0; i < files.length; i++) {
        formData.append(`documents[${i}]`, files[i]);
      }
      this.bookingsService.uploadDocs(this.booking.id, formData)
        .pipe(first())
        .subscribe(
          resp => {
            this.booking.docs = this.booking.docs.concat(resp);
          },
          error => {
            console.error('Error in upload file: ', error);
          }
        );
    }
  }

  sendAgreement(file: File): void {
    const formData = new FormData();
    formData.append('agreement', file);
    formData.append('bookingId', this.booking.id);
    this.bookingsService.uploadAgreement(formData)
      .pipe(first())
      .subscribe({
        next: () => {
          this.getBookingById(this.booking.id, false);
        },
        error: error => {
          console.error('Error in upload file: ', error);
        }
      });
  }
  sendInvoice(file: File): void {
    const formData = new FormData();
    formData.append('file', file);
    this.bookingsService.uploadInvoice(formData, this.booking.id)
      .pipe(first())
      .subscribe(
        resp => {
          //this.setNextStatus(this.booking);
          this.booking.invoice = resp;
        },
        error => {
          console.error('Error in upload file: ', error);
        }
      );
  }

  sendPaymentConfirmation(file: File): void {
    const formData = new FormData();
    formData.append('file', file);
    this.bookingsService.uploadPaymentConfirmation(formData, this.booking.id)
      .pipe(first())
      .subscribe(
        resp => {
          //this.setNextStatus(this.booking);
          this.booking.invoice = resp;
        },
        error => {
          console.error('Error in upload file: ', error);
        }
      );
  }

  setNextStatus(booking: Booking): void {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'Are you sure?',
        text: `Do you really want to change status to "${booking.status === 'CheckingAgreement' ? 'Upload invoice' : booking.status === 'PreparingForFlight' ? 'Flight ready' : 'Preparing for flight'}"?`,
        confirm: () => {
          this.bookingsService.setStatus(booking.id, this.bookingsStatus.transform(booking).status)
            .pipe(first())
            .subscribe((updatedBooking) => {
              this.booking.status = updatedBooking.status;
            });
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  openDocument(type: string, index?: number): void {
    let link = '';
    if (type === 'documents') {
      link = `${this.bookingsService.getBookingUrl()}/${this.booking.id}/${type}/${index}`;
    } else {
      link = `${this.bookingsService.getBookingUrl()}/${this.booking.id}/${type}`;
    }
    this.bookingsService.getDocument(link)
      .pipe(first())
      .subscribe(resp => {
        const fileURL = URL.createObjectURL(resp);
        window.open(fileURL);
      });
  }

  deleteDocument(type: 'agreement' | 'invoice' | 'docs', i?: number): void {
    const deleteData = {
      body: {
        objectName: 'Booking',
        objectId: this.booking.id,
        propertyName: type,
        fileUriHash: Md5.hashStr(i !== undefined ? this.booking.docs[i] : this.booking[type] as string),
      }
    };
    if (type === 'agreement') {
      this.booking.agreement = '';
    } else if (type === 'invoice') {
      this.booking.invoice = '';
    } else {
      if (i !== undefined) this.booking.docs.splice(i, 1);
    }
    this.fileService.deleteDocs(deleteData)
      .pipe(first())
      .subscribe({
        next: undefined,
        error: error => {
          console.error('Error in upload file: ', error);
        }
      });
  }

  openIncreasePassengersNumberModal(legIndex: number): void {
    const dialogRef = this.dialog.open(IncreasePassengersNumberComponent, {
      width: '340px',
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe(passengerType => {
      const tempData = this.booking.bookingLegs.map((leg, index) => {
        const obj: any = {
          id: leg.id,
          adultsNumber: passengerType === 'adult' && legIndex === index ? leg.adultsNumber + 1 : leg.adultsNumber,
          childrenNumber: passengerType === 'child' && legIndex === index ? leg.childrenNumber + 1 : leg.childrenNumber,
          infantsNumber: passengerType === 'infant' && legIndex === index ? leg.infantsNumber + 1 : leg.infantsNumber,
          petsNumber: passengerType === 'pet' && legIndex === index ? leg.petsNumber + 1 : leg.petsNumber,
        }
        if(obj.petsNumber > 0) obj.pets = true;
        return obj;
      });
      this.bookingsService.updateBookingLegs(this.booking.id, tempData).pipe(first()).subscribe(() => {
        this.getBookingById(this.booking.id, false);
      });
    });
  }

  setPassenger(legIndex: number, selected: Passenger): void {
    this.bookingsService.setPassengersForBookingLeg(this.booking.bookingLegs[legIndex].id, {
      clientId: this.booking.client.id,
      passengers: [selected.id]
    })
    .pipe(first())
    .subscribe(() => {
      this.getBookingById(this.booking.id, false);
    });
  }

  @ViewChild('fileField', { static: false }) public fileField!: ElementRef;

  uploadPassengerDocuments(e: Event, passenger: Passenger) {
    this.fileService.onFilesSelect(e, ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'], files => {
      const formData = new FormData();
      formData.append('clientId', this.booking.client.id);
      for (let i = 0; i < files.length; i++) {
        formData.append(files[i].name, files[i]);
      }
      if (this.fileField) this.fileField.nativeElement.value = '';
      this.passengersService
        .uploadDocuments(passenger.id, formData)
        .pipe(first())
        .subscribe(resp => {
          passenger.documents = passenger.documents.concat(resp);
          this.cd.detectChanges();
        });
    });
  }

  openChangePassengerType(legIndex: number, passenger: Passenger): void {
    const dialogRef = this.dialog.open(EditPassengerTypeComponent, {
      width: '340px',
      panelClass: 'no-padding-dialog',
      autoFocus: false,
      data: { initialType: passenger.type }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(newType => {
      if (newType && passenger.type !== newType) {
        const reducingProp = passengerTypeLegDictionary[passenger.type]
        const increasingProp = passengerTypeLegDictionary[newType]

        const editingLeg: any = this.booking.bookingLegs[legIndex]
        const obj: any = {
          id: editingLeg.id,
          [reducingProp]: editingLeg[reducingProp] - 1,
          [increasingProp]: editingLeg[increasingProp] + 1,
        }
        obj.pets = !!obj.petsNumber;

        this.passengersService.updatePassenger(passenger.id, {
          clientId: this.booking.clientId,
          type: newType
        }).pipe(first()).subscribe(() => {
          this.bookingsService.updateBookingLegs(this.booking.id, [obj]).pipe(first()).subscribe(() => {
            this.getBookingById(this.booking.id, false);
          });
        });
      }
    });
  }

  clearPassengerData(legIndex: number, passenger: Passenger, clearPlace = false): void {
    if (passenger.id) {
      this.bookingsService.removePassengersFromBookingLeg(this.booking.bookingLegs[legIndex].id, {
        clientId: this.booking.client.id,
        passengers: [passenger.id]
      })
        .pipe(first())
        .subscribe(() => {
          if(clearPlace) {
            this.removePassenger(legIndex, passenger.type);
          } else this.getBookingById(this.booking.id, false);
        });
    } else {
      this.removePassenger(legIndex, passenger.type);
    }
  }

  removePassenger(legIndex: number, passengerType: string): void {
    const tempData = this.booking.bookingLegs.map((leg, index) => {
      const obj: any = {
        id: leg.id,
        adultsNumber: passengerType === 'adult' && legIndex === index ? leg.adultsNumber - 1 : leg.adultsNumber,
        childrenNumber: passengerType === 'child' && legIndex === index ? leg.childrenNumber - 1 : leg.childrenNumber,
        infantsNumber: passengerType === 'infant' && legIndex === index ? leg.infantsNumber - 1 : leg.infantsNumber,
        petsNumber: passengerType === 'pet' && legIndex === index ? leg.petsNumber - 1 : leg.petsNumber,
      }
      if(obj.petsNumber === 0) obj.pets = false;
      return obj;
    });
    this.bookingsService.updateBookingLegs(this.booking.id, tempData).pipe(first()).subscribe(() => {
      this.getBookingById(this.booking.id, false);
    });
  }

  updatePassengerDocument(passengerId: string, document: any): void {
    const dialogRef = this.dialog.open(RenameDocumentComponent, {
      width: '520px',
      data: document,
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe(updatedData => {
      if (updatedData) this.passengersService
        .updateDocument(passengerId, document.id, {
          newPassengerId: passengerId,
          name: updatedData.name,
          clientId: this.booking.client.id
        })
        .pipe(first())
        .subscribe(() => {
          this.getBookingById(this.booking.id, false);
        });
    });
  }

  deletePassengerDocument(passengerId: string, documentId: string) {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'Are you sure?',
        text: 'Do you really want to delete this document?',
        confirm: () => {
          this.passengersService
            .deleteDocument(passengerId, documentId, this.booking.client.id)
            .pipe(first())
            .subscribe(() => {
              this.getBookingById(this.booking.id, false);
            });
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  openPassengerDocument(passengerId: string, documentId: string, download?: string): void {
    this.passengersService.getDocument(passengerId, documentId, { clientId: this.booking.client.id })
      .pipe(first())
      .subscribe(resp => {
        if (download) {
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(resp);
          downloadLink.setAttribute('download', download);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          const fileURL = URL.createObjectURL(resp);
          window.open(fileURL);
        }
      });
  }

  downloadZip(passenger: Passenger, bookingLegId: string): void {
      this.passengersService.getDocumentsAsZip(passenger.id, { format: 'zip', clientId: this.booking.client.id, bookingLeg: bookingLegId })
      .pipe(first())
      .subscribe(resp => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(resp);
        downloadLink.setAttribute('download', `${passenger.firstName}${passenger.lastName ? '_' + passenger.lastName : ''}_${passenger.id}`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  }

  toggleExpand(value: boolean, legIngex: number, type: string, pIndex: number): void {
    if(value) {
      if (!this.expanded[legIngex][type].includes(pIndex)) this.expanded[legIngex][type].push(pIndex)
    } else {
      this.expanded[legIngex][type].splice(this.expanded[legIngex][type].indexOf(pIndex), 1);
    }
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.currentTab = event.index;
    if (event.index === 1) {
      //if (this.isOperator) {
      this.chatService.removeUnread(this.chatId, this.curUser.firebaseId);
      this.unReadCount = 0;
      //}
    }
  }

  validatorTrim(formControl: AbstractControl): ValidationErrors | null {
    if (!formControl.value) return null;
    const isWhitespace = (formControl.value).trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  checkStatus(bookingId: string): void {
    combineLatest([
      this.statusCheckSrv.checkBookingConfirmedPayment(bookingId),
      this.statusCheckSrv.checkBookingSignedAgreement(bookingId),
      this.statusCheckSrv.checkBookingAdditionalDocuments(bookingId)
    ])
      .pipe(
        skip(2),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.getBookingById(this.booking.id, false);
      });
  }

  filterPassenger(passengers: Passenger[], selected: Passenger[]): Passenger[] {
    return passengers.filter(p => !selected.find(f => f.id === p.id));
  }

  getButtonTextByStatus(status: string | undefined): string {
    return status === 'CheckingAgreement' ? 'Confirm Agreement' : status === 'PreparingForFlight' ? 'CONFIRM FLIGHT READY' : 'CONFIRM PAYMENT';
  }

  sameWithPreviousDate(i: number): boolean {
    if (i === 0) return false;
    return this.chatMessages$.value[i - 1].sentDate.toString().split('T')[0] === this.chatMessages$.value[i].sentDate.toString().split('T')[0];
  }

  sameWithPreviousSender(i: number): boolean | string {
    if (i === 0) return false;
    return this.chatMessages$.value[i - 1].senderId === this.chatMessages$.value[i].senderId && this.sameWithPreviousDate(i) ? true : false;
  }

  get clientInfo(): User | undefined {
    return this.booking?.client?.owners ? this.booking?.client?.owners[0] : undefined;
  }

  get clientInitials(): string | undefined {
    return `${this.booking?.client?.owners[0]?.firstName[0]}${this.booking?.client?.owners[0]?.lastName[0]}`;
  }
}
