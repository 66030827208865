<div class="modal-top row ai-c jc-sb">
	<div class="modal-top__title">Operation not allowed</div>
  <button mat-icon-button mat-dialog-close>
    <img src="assets/img/cancel.svg" alt="">
  </button>
</div>
<div class="modal-container">
	<div class="op-05">This feature is not available to you</div>
</div>
<div class="modal-bottom">
	<button mat-raised-button color="primary" class="modal-bottom__btn" mat-dialog-close>Ok</button>
</div>
