import { Injectable } from '@angular/core';
import { Passenger } from '../../interfaces/Passenger';
import { Observable, ReplaySubject, catchError, tap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { query } from '@angular/animations';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';

@Injectable({
  providedIn: 'root'
})
export class PassengersService {
  public passengersUrl!: string;
  passengers$: ReplaySubject<Passenger[]> = new ReplaySubject<Passenger[]>();
  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
  ) {
    this.passengersUrl = `${this.globalSharedService.config.baseUrl}/passengers`;
  }

  getPassengers(): Observable<Passenger[]> {
    return this.http.get<Passenger[]>(`${this.passengersUrl}`)
      .pipe(
        tap(passengers => this.passengers$.next(passengers)),
        catchError(this.errorService.handleError)
      );
  }

  getPassengersByClientId(clientId: string | null): Observable<Passenger[]> {
    if (!clientId) return this.errorService.noPropertyError();
    return this.http.get<Passenger[]>(this.passengersUrl, { params: new HttpParams({ fromObject: { clientId }, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createPassenger(data: unknown): Observable<Passenger> {
    return this.http.post<Passenger>(`${ this.passengersUrl }`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updatePassenger(passengerId: string, data: unknown): Observable<unknown> {
    return this.http.patch<unknown>(`${ this.passengersUrl }/${passengerId}`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  deletePassenger(passengerId: string): Observable<unknown> {
    return this.http.delete<unknown>(`${ this.passengersUrl }/${passengerId}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getDocument(passengerId: string, documentId: string, query?: any): Observable<Blob> {
    return this.http.get(`${ this.passengersUrl }/${passengerId}/documents/${documentId}`, { responseType: 'blob', params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getDocumentsAsZip(passengerId: string, query?: any): Observable<Blob> {
    return this.http.get(`${ this.passengersUrl }/${passengerId}/documents`,  { responseType: 'blob', params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  uploadDocuments(passengerId: string, data: unknown): Observable<unknown> {
    return this.http.post<unknown>(`${ this.passengersUrl }/${passengerId}/documents`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateDocument(passengerId: string, documentId: string, data: unknown): Observable<unknown> {
    return this.http.patch<unknown>(`${ this.passengersUrl }/${passengerId}/documents/${documentId}`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  deleteDocument(passengerId: string, documentId: string, clientId?: string): Observable<unknown> {
    return this.http.delete<unknown>(`${ this.passengersUrl }/${passengerId}/documents/${documentId}`, { body: { clientId } })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

}
