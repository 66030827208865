import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { first, ReplaySubject, Subscription } from 'rxjs';
import { FeaturedFlight } from '../../../interfaces/FeaturedFlight';
import { User } from '../../../interfaces/User';
import { UserService } from '../../../services/api/user.service';
import { AirportDatetimeService } from '../../../services/helpers/airport-datetime.service';
import { SnackService } from '../../../services/helpers/snack.service';
import { TimeService } from '../../../services/helpers/time.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'lib-featured-deal',
  templateUrl: './featured-deal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedDealComponent implements OnChanges {
  userLoginLink$ = new ReplaySubject<string | undefined>();
  frpNoteChangeRef?: Subscription;

  @Input() featuredDeal!: FeaturedFlight;
  @Input() created?: string;
  @Input() updated?: string;
  @Input() estimatedBudget?: number;
  @Output() featuredDealCancel = new EventEmitter<string>();
  constructor(
    private airportDatetimeService: AirportDatetimeService,
    public timeService: TimeService,
    public userService: UserService,
    private clipboard: Clipboard,
    private snackSrv: SnackService,

  ) { }

  ngOnChanges(): void {
    this.userLoginLink$.next(undefined);
  }

  makeAndCopyLoginLink(employee: User): void {
    this.userService.getLoginLink(employee.firebaseId)
      .pipe(first())
      .subscribe(resp => {
        this.userLoginLink$.next(resp);
        this.copyLoginLink(resp);
      });
  }

  copyLoginLink(link: string): void {
    const pending = this.clipboard.beginCopy(link);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        this.snackSrv.systemError('Link was copied to clipboard');
        pending.destroy();
      }
    };
    attempt();
  }

  cancelFeaturedDeal(id?: string) {
    if (id) this.featuredDealCancel?.emit(id);
  }

  get flightDateRange(): string {
    const minDate = DateTime.fromISO(this.featuredDeal.minDate as string).toFormat('dd');
    const maxDate = DateTime.fromISO((this.featuredDeal.maxDate as string).slice(0, -1)).toFormat('dd');
    return minDate === maxDate ? minDate : `${minDate} - ${maxDate}`;
  }

  get flightMonthRange(): string {
    const minMonth = DateTime.fromISO(this.featuredDeal.minDate as string).toFormat('MMM');
    const maxMonth = DateTime.fromISO((this.featuredDeal.maxDate as string).slice(0, -1)).toFormat('MMM');
    return minMonth === maxMonth ? minMonth : `${minMonth} - ${maxMonth}`;
  }

  get flightTime(): string {
    return this.featuredDeal.minTime === this.featuredDeal.maxTime ? this.featuredDeal.minTime : `${this.featuredDeal.minTime} - ${this.featuredDeal.maxTime}`;
  }

}
