import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalSharedService, PlatformService, SnackService } from 'common';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ToolbarService } from './shared/shell/toolbar.service';

@Component({
  selector: 'app-root',
  template: `
    <app-shell>
      <router-outlet></router-outlet>
    </app-shell>
  `
})
export class AppComponent implements OnInit {
  constructor(
    private globalSharedService: GlobalSharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private toolbarService: ToolbarService,
    private platformService: PlatformService,
    private snackSrv: SnackService,
  ) {
    this.globalSharedService.config = {
      baseUrl: environment.baseUrl,
      loggerUrl: environment.loggerUrl,
      env: 'admin'
    };
  }

  checkPlatform(): void {
    const support = this.platformService.browserSupport();
    if (!sessionStorage.getItem('platform_check')) {
      if (!support) this.snackSrv.systemError('Please note that current browser is not fully supported and Chrome is recommended instead');
      sessionStorage.setItem('platform_check', 'true');
    }
  }

  ngOnInit(): void {
    this.checkPlatform();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((routeData) => {
        if (routeData) {
          this.toolbarService.getTitleObject().next(routeData.title || '');
          this.titleService.setTitle(`${ routeData.title || '' } - OPES JET Admin`);
        }
      });
  }
}
