import { DateRange } from '@angular/material/datepicker';
import { Bid } from '../interfaces/Bid';
import { FeaturedFlight } from '../interfaces/FeaturedFlight';

export class BidToFeaturedFlight {
  featuredFlight?: FeaturedFlight;

  constructor(bid: Bid) {
    this.featuredFlight = {
      id: '0',
      aircraft: bid.aircraftInfo,
      aircraftId: bid.aircraftInfo.id,
      emptyLeg: bid.emptyLeg,
      pets: bid.leg.pets,
      maxPassengers: bid.leg.adultsNumber + bid.leg.childrenNumber,
      minDate: new Date((bid.takeoffDate as string).slice(0, -1)),
      maxDate: new Date((bid.landingDate as string).slice(0, -1)),
      minTime: '00:00',
      maxTime: '00:00',
      rangeDate: new DateRange<Date>(new Date((bid.takeoffDate as string).slice(0, -1)), new Date((bid.landingDate as string).slice(0, -1))),
      description: bid.description,
      from: bid.from,
      fromId: bid.from.id,
      to: bid.to,
      toId: bid.to.id,
      price: bid.price,
      priceCurCode: bid.priceCurCode,
      published: true,
      bookings: [],
      created: new Date().toString(),
    };
  }
}
