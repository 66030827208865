import { Component, OnInit, HostListener } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, first, debounceTime, map } from 'rxjs';
import { ConfirmDeactivateGuard } from '../../guards/confirm-deactivate.guard';
import { User } from '../../interfaces/User';
import { UserService } from '../../services/api/user.service';
import { AircraftSaved } from '../../interfaces/AircraftSaved';
import { AircraftsSavedService } from '../../services/api/aircrafts-saved.service';
import { AirportsService } from '../../services/api/airports.service';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatRangeDateSelectionModel,
} from "@angular/material/datepicker";
import { SnackService } from '../../services/helpers/snack.service';
import { FeaturedFlightsService } from '../../services/api/featured-flights.service';
import { FeaturedFlightToCreate } from '../../classes/FeaturedFlightToCreate';
import { ActivatedRoute } from '@angular/router';
import { OffersService } from '../../services/api/offers.service';
import { FeaturedFlight } from '../../interfaces/FeaturedFlight';
import { MatDialog } from '@angular/material/dialog';
import { ActionAlertComponent } from '../../components/modals/action-alert/action-alert.component';
import { BidToFeaturedFlight } from '../../classes/BidToFeaturedFlight';
import { ErsService } from '../../services/api/ers.service';
import { UnusualPriceComponent } from './unusual-price/unusual-price.component';

@Component({
  selector: 'lib-make-featured-deal',
  templateUrl: './make-featured-deal.component.html',
  styleUrls: [ './make-featured-deal.component.scss' ],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy
    },
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel
  ]
})
export class MakeFeaturedDealComponent implements OnInit, ConfirmDeactivateGuard {
  fromToValidator = (control: AbstractControl) => {
    if (!control.value?.id) return { invalidFromTo: true };
    return null;
  };
  pastDateTimeValidator = (formControl: AbstractControl) => {
    const isValidDates = formControl.get('minDate')?.value <= formControl.get('maxDate')?.value;
    const isValidTimes = formControl.get('minTime')?.value <= formControl.get('maxTime')?.value;
    formControl.get('minDate')?.setErrors(isValidDates ? null : { pasttime: true });
    formControl.get('maxDate')?.setErrors(isValidDates ? null : { pasttime: true });
    formControl.get('minTime')?.setErrors(isValidTimes ? null : { pasttime: true });
    formControl.get('maxTime')?.setErrors(isValidTimes ? null : { pasttime: true });
    return (isValidDates && isValidTimes) ? null : { pastdate: true };
  };
  curUser!: User;
  featuredDealForm!: UntypedFormGroup;
  featuredFlightId?: string;
  aircraftsSaved: Array<AircraftSaved> = [];
  minDate = new Date();
  filteredFromCities: any = [];
  filteredToCities: any = [];
  successState = false;
  ready = true;
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.featuredDealForm.dirty || this.successState;
  }

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private aircraftsSavedService: AircraftsSavedService,
    private airportsService: AirportsService,
    private featuredFlightService: FeaturedFlightsService,
    private fb: UntypedFormBuilder,
    private snack: SnackService,
    private offerService: OffersService,
    private ersService: ErsService,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.featuredDealForm = this.fb.group({
      aircraft: [ undefined, Validators.required ],
      emptyLeg: [ true, Validators.required ],
      pets: [ false, Validators.required ],
      maxPassengers: [ 1, Validators.required ],
      minDate: [ new Date(), Validators.required ],
      maxDate: [ new Date(), Validators.required ],
      minTime: [ '00:00', [Validators.required, Validators.min(3)] ],
      maxTime: [ '23:59', [Validators.required, Validators.min(3)] ],
      rangeDate: [ new DateRange<Date>(new Date(), new Date()), Validators.required ],
      from: [ undefined, [ Validators.required, this.fromToValidator ] ],
      to: [ undefined, [ Validators.required, this.fromToValidator ] ],
      description: [ `First come, first serve applies on all our offers.\nAdditional fees may apply depending on the number of passengers and departure time of day.\nFlight may be subject to a fuel stop depending on payload and weather conditions.\nPlease contact support to confirm conditions before booking. Thank you.` ],
      price: [ undefined, Validators.required ],
      priceEstimated: [ undefined ],
      priceCurCode: [ 'USD', Validators.required ],
      published: [ true ],
    }, {
      validators: [
        this.pastDateTimeValidator,
      ]
    });

    this.getUserInfo();
    this.getAircrafts();
  }

  getUserInfo(): void {
    this.userService.getUserInfo()
      .pipe(first())
      .subscribe(resp => {
        this.curUser = resp;
        this.curUser.operator = resp.operatorAccountOwner || resp.operatorAccountAdmin;
      });
  }

  getOfferById(id: string, leg?: string): void {
    this.offerService.getOfferById(id)
      .pipe(first())
      .subscribe(resp => {
        const legId = resp.find((legs) => legs.id === leg);
        if (legId) {
          const flight = new BidToFeaturedFlight(legId).featuredFlight;
          if(flight) {
            flight.aircraft = this.aircraftsSaved.find((aircraft)=> aircraft.id === flight?.aircraftId);
            this.loadForm(flight);
          }
        }
      });
  }

  getAircrafts(): void {
    this.route.queryParams
      .pipe(first())
      .subscribe(params => {
        this.featuredFlightId = params.featuredFlight;
        this.aircraftsSavedService.getAircraftsSaved()
          .pipe(
            first(),
            map(savedAircrafts => savedAircrafts.filter(aircraft => aircraft.active))
          ).subscribe((savedAircrafts) => {
            this.aircraftsSaved = savedAircrafts;
            if (params.featuredFlight) {
              this.featuredFlightService.getFeaturedFlight(params.featuredFlight).pipe(first()).subscribe((flight: FeaturedFlight) => {
                flight.aircraft = this.aircraftsSaved.find((aircraft) => aircraft.id === flight.aircraftId);
                const minDate = new Date((flight.minDate as string).slice(0, -1));
                const maxDate = new Date((flight.maxDate as string).slice(0, -1));
                flight.rangeDate = new DateRange<Date>(minDate, maxDate);
                flight.minDate = minDate;
                flight.maxDate = maxDate;
                if (!flight.minTime) flight.minTime = '00:00';
                if (!flight.maxTime) flight.maxTime = '00:00';
                this.loadForm(flight);
              });
            } else if (params.offer) {
              this.getOfferById(params.offer, params.leg);
            } else {
              this.loadForm();
            }
          });
      });
  }

  loadForm(bid?: FeaturedFlight): void {
    this.ready = false;
    if (bid) this.featuredDealForm.patchValue(bid);
    this.bindAirports(this.featuredDealForm.controls.from, 'from');
    this.bindAirports(this.featuredDealForm.controls.to, 'to');
    this.featuredDealForm.get('minDate')?.valueChanges.subscribe(value => {
      this.featuredDealForm.patchValue({
        rangeDate: new DateRange<Date>(value, this.featuredDealForm.value.maxDate),
      });
    });
    this.featuredDealForm.get('maxDate')?.valueChanges.subscribe(value => {
      this.featuredDealForm.patchValue({
        rangeDate: new DateRange<Date>(this.featuredDealForm.value.minDate, value),
      });
    });
    this.featuredDealForm.get('aircraft')?.valueChanges.subscribe(value => {
      if (value.seats) this.featuredDealForm.patchValue({
        maxPassengers: value.seats,
      });
    });
    setTimeout(() => {
      this.ready = true;
    });
  }

  changePassengers(action?: boolean): void {
    this.featuredDealForm.get('maxPassengers')?.patchValue(action ? this.featuredDealForm.value.maxPassengers += 1 : this.featuredDealForm.value.maxPassengers -= 1);
  }

  displayFn(airport: any): string {
    return airport?.cityOnly ? airport.city : airport?.name ? `${ airport.name }  (${ airport.icao })` : '';
  }

  setCurrency(curCode: 'USD' | 'EUR' | 'GBP'): void {
    this.featuredDealForm.get('priceCurCode')?.patchValue(curCode);
  }

  updateExpirationDate(date: Date): void {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        date,
        selection
      );

    this.selectionModel.updateSelection(newSelection, this);
    this.featuredDealForm.patchValue({
      rangeDate: new DateRange<Date>(newSelection.start, newSelection.end),
      minDate: newSelection.start,
      maxDate: newSelection.end,
    });
  }

  bindAirports(control: AbstractControl, type: string): void {
    control.valueChanges
      .pipe(debounceTime(500))
      .subscribe(value => {
        if (!value || value instanceof Object || value.length < 3) return;
        if (type === 'from') this.filteredFromCities = [];
        if (type === 'to') this.filteredToCities = [];

        this.airportsService.searchAirports(value)
          .pipe(first())
          .subscribe(resp => {
            const cities: any = [];
            resp.airports.forEach(airport => {
              if (!cities.some((city: any) => airport.city === city.city && airport.province === city.province && airport.countryCode === city.countryCode)) {
                cities.push({ ...airport, ...{ cityOnly: true } });
              }
            });
            let airportsAndCities: any = [];
            cities.forEach((city: any) => {
              city.airportsCounter = resp.airports.filter(airport => airport.city === city.city && airport.province === city.province && airport.countryCode === city.countryCode).length;
              airportsAndCities.push(city);
              airportsAndCities = airportsAndCities
                .concat(resp.airports
                  .filter(airport => airport.city === city.city && airport.province === city.province && airport.countryCode === city.countryCode)
                  .sort((first, second) => {
                    if(first.closed > second.closed) return 1;
                    if(first.closed < second.closed) return -1;
                    if( first.rating != second.rating) {                // | - if airports have different ratings - using them to sort (sorting level 2)
                        return (second.rating || 5) - (first.rating || 5); // | - 5 is the default rating, so that 1-4 means a bad airport and 6-10 means a good airport
                    } else {                            // | - if airports are equal in rating we sort them alphabetically (sorting level 3)
                        return first.name < second.name ? 1 : -1; // |
                    }
                  })
                  .map(airport => {
                    return { ...airport, ...{ airportOnly: city.airportsCounter === 1 } };
                  }));
            });
            if (airportsAndCities.length) {
              if (type === 'from') this.filteredFromCities = airportsAndCities;
              if (type === 'to') this.filteredToCities = airportsAndCities;
            } else {
              if (type === 'from') this.filteredFromCities = [];
              if (type === 'to') this.filteredToCities = [];
            }
          });
      });
  }

  onSubmit(priceIgnore = false): void {
    if (this.featuredDealForm.valid) {
      if(!priceIgnore && this.featuredDealForm.value.price < 1000) {
        this.openUnusualPrice(this.featuredDealForm.value.price);
        return;
      }
      const today = new Date();
      if(this.featuredDealForm.value.minDate < new Date(today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate()) || this.featuredDealForm.value.maxDate < new Date(today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate())) {
        return this.snack.systemError(`Start or end date shoudn't be in past`, 5000);
      } else if (this.featuredDealForm.value.minDate > this.featuredDealForm.value.maxDate) {
        return this.snack.systemError('Start date should be less then end date', 5000);
      } if (this.featuredDealForm.value.minTime > this.featuredDealForm.value.maxTime) {
        return this.snack.systemError('Start time should be less then end time', 5000);
      } else {
        this.ersService.getFlightEstimateByLegs({ legs: [new FeaturedFlightToCreate(this.featuredDealForm.value)]}).pipe(first()).subscribe(resp => {
          const category = Object.values(resp.legs[0].categories).find(c => c.name === this.featuredDealForm.value.aircraft.categoryName);
          console.log(category);
          if(category) {
            this.featuredDealForm.get('priceEstimated')?.patchValue(category.estimateV2.estimate50th);
            switch (category.name) {
              case 'super midsize':
                if (category.estimateV2.estimate50th < 25000)
                  this.featuredDealForm.get('priceEstimated')?.patchValue(25000);
                break;
              case 'large':
                if (category.estimateV2.estimate50th < 35000)
                this.featuredDealForm.get('priceEstimated')?.patchValue(35000);
                break;

              default:
                break;
            }
          }
          if(this.featuredFlightId) {
            this.featuredFlightService.updateFeaturedFlight(this.featuredFlightId, new FeaturedFlightToCreate(this.featuredDealForm.value))
            .pipe(first())
            .subscribe({
              next: () => {
                this.successState = true;
                this.featuredDealForm.reset();
              },
              error: () => {
                this.snack.systemError('Something went wrong.', 5000);
              }
            });
          } else {
            this.featuredFlightService.createFeaturedFlight(new FeaturedFlightToCreate(this.featuredDealForm.value))
            .pipe(first())
            .subscribe({
              next: () => {
                this.successState = true;
                this.featuredDealForm.reset();
              },
              error: () => {
                this.snack.systemError('Something went wrong.', 5000);
              }
            });
          }
        })

      }
    }
    else { return this.snack.systemError('Please complete the required fields', 5000); }
  }

  openFeaturedFlightInformation(): void {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: `Here's how it works`,
        text: `Empty Legs are special deals that are offered to all our members directly. Deals do not need to be empty legs, but if they are, simply tick the "empty leg conditions" box to inform our members. Deals you add will automatically expire after 24 hours, but can be renewed at the click of a button.Members who wish to book a deal will select their preferred date + time plus number of passengers from the available range you set. If pets are allowed, they can add details. Members cannot negotiate the route, or price, but must book the deal "as is". In case they wish to book a variation of your deal, we encourage them to setup a flight request instead. Should your aircraft availability change, please update your offer to avoid unnecessary cancelled booking attempts.`
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  openUnusualPrice(price: number): void {
    const dialogRef = this.dialog.open(UnusualPriceComponent, {
      width: '420px',
      data: price,
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe(value => {
      if(value) this.onSubmit(true);
    })
  }
}
