import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from '../../../services/helpers/snack.service';

@Component({
  selector: 'lib-rename-tab',
  templateUrl: './rename-tab.component.html',
  styleUrls: [ './rename-tab.component.scss' ]
})
export class RenameTabComponent {
  tabForm = this.fb.group({
    title: ['', [Validators.required, Validators.maxLength(30) ]],
  });
  constructor(
    public dialogRef: MatDialogRef<RenameTabComponent>,
    private fb: UntypedFormBuilder,
    private snack: SnackService,
    @Inject(MAT_DIALOG_DATA) public title: string,
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') this.close();
    });
    this.tabForm.patchValue({
      title: title,
    });
  }
  close(): void {
    this.dialogRef.close();
  }
  save(): void {
    if(!this.tabForm.value.title) return this.snack.systemError('Please complete the required fields', 5000);
    if(this.tabForm.value.title.length > 30) return this.snack.systemError('Tab name is too large', 5000);
    this.dialogRef.close(this.tabForm.value.title);
  }

}
