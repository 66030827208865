<div class="modal">
	<div class="modal-header">
		{{data?.id&&'Edit'||'Add'}} Aircraft
	</div>
	<form class="modal-content" action="#" [formGroup]="aircraftSavedForm">
		<div class="row jc-fs ai-c">
			<div class="content-title wp-25">Aircraft Type *</div>
			<mat-form-field appearance="outline" class="wp-75">
				<input type="text" placeholder="Select aircraft" matInput [value]="selectedAircraft?.typeName" [formControl]="myControl" [matAutocomplete]="auto">
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onAircraftSelect($event)">
					<mat-option *ngFor="let category of aircraftCategoriesFiltered; let i = index" [value]="category">
						{{category.typeName}}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
			<span class="fill-space"></span>
		</div>
		<div class="row jc-fs ai-c">
			<div class="wp-25 content-title">Aircraft Class</div>
			<div class="wp-25 capitalize-first content-title">{{ aircraftSavedForm.value.categoryName }}</div>
			<div class="wp-25 content-title">Tail Number *</div>
			<mat-form-field class="wp-25" appearance="outline">
				<input matInput type="text" class="tt-u" formControlName="tailNumber" placeholder="Tail number" required matTooltip="Capital letters only">
			</mat-form-field>
			<span class="fill-space"></span>
		</div>
		<div class="row jc-fs ai-c">
			<div class="row ai-c wp-50">
				<div class="content-title wp-50">Passengers *</div>
				<mat-form-field class="wp-50 pr-20" appearance="outline">
					<input matInput type="number" formControlName="seats" min="1" placeholder="4" required matTooltip="Max passengers for category {{ selectedAircraft?.categoryName }} is {{ selectedAircraft?.paxMax }}" [matTooltipDisabled]="!selectedAircraft">
				</mat-form-field>
			</div>
			<div class="row ai-c wp-50">
				<div class="content-title wp-50">Owner approval required</div>
				<mat-form-field class="wp-50" appearance="outline">
					<mat-select placeholder="Not selected" formControlName="ownerApproval">
						<mat-option value="">Not selected</mat-option>
						<mat-option value="yes">Yes</mat-option>
						<mat-option value="no">No</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<span class="fill-space"></span>
		</div>
		<div class="row jc-fs ai-c">
			<div class="row ai-c wp-50">
				<div class="content-title wp-50">Manufactured in</div>
				<mat-form-field appearance="outline" class="wp-50 pr-20">
					<input matInput type="number" formControlName="yom" placeholder="2010">
				</mat-form-field>
			</div>
			<div class="row ai-c wp-50">
				<div class="content-title wp-50">Refurbished in</div>
				<mat-form-field class="wp-50" appearance="outline">
					<input matInput type="number" formControlName="yor" [placeholder]="curYear">
				</mat-form-field>
			</div>
			<span class="fill-space"></span>
		</div>
		<div class="row jc-fs ai-c">
			<div class="row ai-c wp-50">
				<div class="content-title wp-50">Max Speed (km/h)</div>
				<mat-form-field appearance="outline" class="wp-50 pr-20">
					<input matInput type="number" formControlName="maxSpeed" placeholder="500">
				</mat-form-field>
			</div>
			<div class="row ai-c wp-50">
				<div class="content-title wp-50">Homebase</div>
				<mat-form-field class="wp-50" appearance="outline">
					<input type="text" placeholder="Search airport" matInput [formControl]="airportControl" [matAutocomplete]="home">
					<mat-autocomplete #home="matAutocomplete" [displayWith]="displayAirportFn" (optionSelected)="onAirportSelect($event)">
						<mat-option *ngFor="let airport of airportsList; let i = index" [value]="airport">
							{{airport.icao}}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
		</div>
		<div class="content-title">Photos:</div>
		<div class="row jc-fs ai-c mb-15">
			<div class="block-image" [class.empty]="!photos.pictureExterior">
				<ng-container *ngIf="photos.pictureExterior">
					<img class="input-image" (click)="lightbox.open(0)" [src]="photos.pictureExterior" alt="Exterior">
					<mat-icon class="add-icon" (click)="exterior.click()">add_photo_alternate</mat-icon>
				</ng-container>
				<div *ngIf="!photos.pictureExterior" (click)="exterior.click()">
					<div>Exterior</div>
					<div><span class="material-icons">
							add
						</span></div>
					<div>Upload</div>
				</div>
				<input [hidden]="true" type="file" accept="image/jpg,image/jpeg,image/png" (change)="onFileSelect($event, 'pictureExterior')" required #exterior />
			</div>
			<div class="block-image" [class.empty]="!photos.pictureInterior">
				<ng-container *ngIf="photos.pictureInterior">
					<img class="input-image" (click)="lightbox.open(1)" [src]="photos.pictureInterior" alt="Interior">
					<mat-icon class="add-icon" (click)="interior.click()">add_photo_alternate</mat-icon>
				</ng-container>
				<div *ngIf="!photos.pictureInterior" (click)="interior.click()">
					<div>Interior</div>
					<div><span class="material-icons">
							add
						</span></div>
					<div>Upload</div>
				</div>
				<input [hidden]="true" type="file" accept="image/jpg,image/jpeg,image/png" (change)="onFileSelect($event, 'pictureInterior')" required #interior />
			</div>
			<div class="block-image" [class.empty]="!photos.pictureFloorplan">
				<ng-container *ngIf="photos.pictureFloorplan">
					<img class="input-image" (click)="lightbox.open(2)" [src]="photos.pictureFloorplan" alt="Floorplan">
					<mat-icon class="add-icon" (click)="floorplan.click()">add_photo_alternate</mat-icon>
				</ng-container>
				<div *ngIf="!photos.pictureFloorplan" (click)="floorplan.click()">
					<div>Floorplan</div>
					<div><span class="material-icons">
							add
						</span></div>
					<div>Upload</div>
				</div>
				<input [hidden]="true" type="file" accept="image/jpg,image/jpeg,image/png" (change)="onFileSelect($event, 'pictureFloorplan')" required #floorplan />
			</div>
		</div>
		<div class="content-title">Aircraft Services on Board</div>
		<div class="row jc-fs ai-c">
			<div class="wp-25">Flight Attendant</div>
			<mat-form-field class="wp-25 pr-20" appearance="outline">
				<mat-select placeholder="Not selected" formControlName="flightAttendant">
					<mat-option value="">Not selected</mat-option>
					<mat-option value="yes">Yes</mat-option>
					<mat-option value="no">No</mat-option>
				</mat-select>
			</mat-form-field>
			<div class="wp-25">Smoking allowed</div>
			<mat-form-field class="wp-25" appearance="outline">
				<mat-select placeholder="Not selected" formControlName="smoking">
					<mat-option value="">Not selected</mat-option>
					<mat-option value="yes">Yes</mat-option>
					<mat-option value="no">No</mat-option>
				</mat-select>
			</mat-form-field>
			<span class="fill-space"></span>
		</div>
		<div class="row jc-fs ai-c">
			<div class="wp-25">Lavatory</div>
			<mat-form-field class="wp-25 pr-20" appearance="outline">
				<mat-select placeholder="Not selected" formControlName="toilet">
					<mat-option value="">Not selected</mat-option>
					<mat-option value="yes">Yes</mat-option>
					<mat-option value="no">No</mat-option>
				</mat-select>
			</mat-form-field>
			<div class="wp-25">Pets allowed</div>
			<mat-form-field class="wp-25" appearance="outline">
				<mat-select placeholder="Not selected" formControlName="pets">
					<mat-option value="">Not selected</mat-option>
					<mat-option value="yes">Yes</mat-option>
					<mat-option value="no">No</mat-option>
				</mat-select>
			</mat-form-field>
			<span class="fill-space"></span>
		</div>
		<div class="row jc-fs ai-c">
			<div class="wp-25">Satellite phone</div>
			<mat-form-field class="wp-25 pr-20" appearance="outline">
				<mat-select placeholder="Not selected" formControlName="satphone">
					<mat-option value="">Not selected</mat-option>
					<mat-option value="yes">Yes</mat-option>
					<mat-option value="no">No</mat-option>
				</mat-select>
			</mat-form-field>
			<div class="wp-25">Wi-Fi</div>
			<mat-form-field class="wp-25" appearance="outline">
				<mat-select placeholder="Not selected" formControlName="wifi">
					<mat-option value="">Not selected</mat-option>
					<mat-option value="yes">Yes</mat-option>
					<mat-option value="no">No</mat-option>
				</mat-select>
			</mat-form-field>
			<span class="fill-space"></span>
		</div>
		<div class="row jc-fs ai-c">
			<div class="wp-25">Hot meal available</div>
			<mat-form-field class="wp-25 pr-20" appearance="outline">
				<mat-select placeholder="Not selected" formControlName="catering">
					<mat-option value="">Not selected</mat-option>
					<mat-option value="yes">Yes</mat-option>
					<mat-option value="no">No</mat-option>
				</mat-select>
			</mat-form-field>
			<div class="wp-25">Entertainment</div>
			<mat-form-field class="wp-25" appearance="outline">
				<mat-select placeholder="Not selected" formControlName="entertainment">
					<mat-option value="">Not selected</mat-option>
					<mat-option value="yes">Yes</mat-option>
					<mat-option value="no">No</mat-option>
				</mat-select>
			</mat-form-field>
			<span class="fill-space"></span>
		</div>
	</form>
	<div class="modal-footer">
		<button mat-stroked-button type="button" class="w-128 mr-8" (click)="close()">Cancel</button>
		<button mat-raised-button class="w-128" type="submit" color="primary" [disabled]="!aircraftSavedForm.valid || requesting" (click)="onSubmit()">Save Aircraft</button>
	</div>
</div>
