import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { AircraftCategory } from '../../interfaces/AircraftCategory';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';

@Injectable({
  providedIn: 'root'
})
export class AircraftsCategoriesService {
  aircraftsCategoriesUrl = '';

  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService
  ) {
    this.aircraftsCategoriesUrl = `${this.globalSharedService.config.baseUrl}/aircrafts-categories`;
  }

  getAircraftsCategories(opts = {}): Observable<any> {
    return this.http.get<any>(`${this.aircraftsCategoriesUrl}`, {
      params: new HttpParams({ fromObject: opts, encoder: new CustomHttpParamEncoder() })
    })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  addAircraftCategory(data: AircraftCategory): Observable<unknown> {
    return this.http.post<unknown>(this.aircraftsCategoriesUrl, data)
    .pipe(
      catchError(this.errorService.handleError)
    );
  }

  updateAircraftCategory(data: AircraftCategory): Observable<unknown> {
    return this.http.patch<unknown>(this.aircraftsCategoriesUrl, data)
    .pipe(
      catchError(this.errorService.handleError)
    );
  }
}
