import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BookingsService } from '../../../services/api/bookings.service';
import { first } from 'rxjs';

@Component({
  selector: 'lib-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss'],
})
export class CancelBookingComponent implements OnInit {

  dialogForm = this.fb.group({
    coinsToReturn: [undefined, (f: AbstractControl) => {
      this.validatorTrim(f);
    }],
    comment: [undefined]
  });
  coinsToReturn!: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CancelBookingComponent>,
    private fb: UntypedFormBuilder,
    private bookingsService: BookingsService,
  ) {
    this.dialogRef.backdropClick().subscribe(_ => {
      this.dialogRef.close();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') this.dialogRef.close()
    });
  }

  ngOnInit(): void {
    this.bookingsService.getRefundAmount(this.data.bookingId).pipe(first()).subscribe(resp => {
      this.coinsToReturn = Math.floor(resp/100);
      this.dialogForm.get('coinsToReturn')?.patchValue(this.coinsToReturn);
    });
  }

  validatorTrim(formControl: AbstractControl): ValidationErrors | null {
    if (formControl.value && formControl.value !== this.coinsToReturn) {
      formControl.parent?.get('comment')?.addValidators(Validators.required);
      if (!formControl.parent?.value.comment) {
        formControl.parent?.get('comment')?.setErrors({required: true});
      } else {
        formControl.parent?.get('comment')?.setErrors(null);
      }
      formControl.parent?.get('comment')?.markAsTouched();
    } else {
      formControl.parent?.get('comment')?.clearValidators();
      formControl.parent?.get('comment')?.setErrors(null);
    }
    return null;
  }

  save(): void {
    if(this.dialogForm.get('coinsToReturn')?.value === this.coinsToReturn) {
      this.dialogRef.close({
        comment: this.dialogForm.value.comment
      });
    } else {
      this.dialogRef.close({
        comment: this.dialogForm.value.comment,
        coinsToReturn: this.dialogForm.value.coinsToReturn*100
      });
    }
  }

}
