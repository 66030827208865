import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { GlobalSharedService } from '../../global-shared.service';
import { Mailing, MailingList } from '../../interfaces/Mailing';
import { Operator } from '../../interfaces/Operator';
import { PreRegisterInfo } from '../../interfaces/PreRegisterInfo';
import { PreRegisterResult } from '../../interfaces/PreRegisterResult';
import { ErrorService } from '../helpers/error.service';

@Injectable({
  providedIn: 'root'
})
export class OperatorsService {
  public operatorsUrl!: string;
  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
  ) {
    this.operatorsUrl = `${ this.globalSharedService.config.baseUrl }/operators`;
  }

  getOperators(query = {}): Observable<Operator[]> {
    return this.http.get<Operator[]>(this.operatorsUrl,
      { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getOperatorById(id: string | null, query = {}): Observable<Operator> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<Operator>(this.operatorsUrl + `/${ id }`,
      { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getMailingsByFrpId(id: string): Observable<Mailing[]> {
    return this.http.get<Mailing[]>(`${ this.operatorsUrl }/mailings/`,
      { params: new HttpParams({ fromObject: { frpId: id, silent: 'true' }, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getOperatorsLists(): Observable<MailingList[]> {
    return this.http.get<MailingList[]>(`${ this.operatorsUrl }/mailings/lists`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createOperatorsList(data: unknown): Observable<MailingList> {
    return this.http.post<MailingList>(`${ this.operatorsUrl }/mailings/lists`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateOperatorsList(data: unknown): Observable<MailingList> {
    return this.http.patch<MailingList>(`${ this.operatorsUrl }/mailings/lists`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  deleteOperatorsList(data: { body: { id: string; }; }): Observable<unknown> {
    return this.http.delete<unknown>(`${ this.operatorsUrl }/mailings/lists`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  sendMailings(data: { type: string, frpId: string | null, operatorIds: string[], wingxOperatorIds?: number[], listIds: [], sender?: string, emailTemplateId?: string; comment?: string; }): Observable<unknown> {
    return this.http.post<unknown>(`${ this.operatorsUrl }/mailings`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  activate(id: string): Observable<Operator> {
    return this.http.post<Operator>(`${ this.operatorsUrl }/${ id }/activate`, null)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  openPdf(id?: string, type?: string): void {
    this.http.get(`${ this.operatorsUrl }/${ id }/${ type }`, { responseType: 'blob' })
      .pipe(
        first(),
        catchError(this.errorService.handleError)
      )
      .subscribe((resp) => {
        const fileURL = URL.createObjectURL(resp);
        window.open(fileURL);
      });
  }

  preregister(preRegisterInfo: PreRegisterInfo): Observable<PreRegisterResult> {
    return this.http.post<PreRegisterResult>(`${ this.operatorsUrl }/preregister`, preRegisterInfo)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  changeOperatorStatus(id: string | undefined, status: string): Observable<unknown> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.patch<unknown>(`${ this.operatorsUrl }/${ id }/status`, { status: status })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getOperatorRates(id: string): Observable<any> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<any>(`${ this.operatorsUrl }/${ id }/rates`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getDefaultChecklist(id: string): Observable<any> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<any>(`${ this.operatorsUrl }/${ id }/default-checklist`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createDefaultChecklist(id: string, data: Array<{ name: string, visible: boolean }>): Observable<unknown> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.put<unknown>(`${ this.operatorsUrl }/${ id }/default-checklist`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  isOlympusOperator(id: string): boolean {
    const testOlympusUserId = "af173c53-3a6e-4ddb-bcb8-0ae47231d3f7"
    const prodOlympusUserId = "f6df99b9-51c9-453b-9e71-f1d0f9bf573c"

    return [testOlympusUserId, prodOlympusUserId].includes(id)
  }

  isEstimatorOperator(id: string): boolean {
    const testEstimatorUserId = "af173c53-3a6e-4ddb-bcb8-0ae47231d3f7"
    const prodEstimatorUserId = "aa90030a-3c8a-4b76-bb16-6a580ae963f0"

    return [testEstimatorUserId, prodEstimatorUserId].includes(id)
  }

  isPremiumOperator(status: string): boolean {
    const PREMIUM_STATUS = ['gold', 'silver', 'bronze']

    return PREMIUM_STATUS.includes(status)
  }
}
