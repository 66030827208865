import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturedFlightConfirmationRoutingModule } from './featured-flight-confirmation-routing.module';
import { FeaturedFlightConfirmationComponent } from './featured-flight-confirmation.component';
import { GlobalSharedModule } from '../../global-shared.module';


@NgModule({
  declarations: [
    FeaturedFlightConfirmationComponent,
  ],
  imports: [
    CommonModule,
    FeaturedFlightConfirmationRoutingModule,
    GlobalSharedModule,
  ]
})
export class FeaturedFlightConfirmationModule { }
