import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorOpesJet } from '../../interfaces/ErrorOpesJet';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() {
    // do nothing.
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.error)}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => {
      const curError: ErrorOpesJet = new Error(error.error ? error.error.message : 'Something bad happened; please try again later.');
      curError.status = error.status;
      curError.code = error.error?.code;
      return curError;
    });
  }

  noPropertyError(): Observable<never> {
    return throwError(() => new Error('Something bad happened; no parameters provided.'));
  }
}
