import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from '../../../services/helpers/snack.service';
import { EditOperatorComponent } from '../edit-operator/edit-operator.component';

@Component({
  selector: 'lib-rename-document',
  templateUrl: './rename-document.component.html',
  styleUrls: ['./rename-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenameDocumentComponent {
  tabForm = this.fb.group({
    name: [ '', [ Validators.required, Validators.maxLength(100) ] ],
  });
  constructor(
    public dialogRef: MatDialogRef<EditOperatorComponent>,
    private fb: UntypedFormBuilder,
    private snack: SnackService,
    @Inject(MAT_DIALOG_DATA) public document: any,
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') this.close();
    });
    this.tabForm.patchValue({
      name: document.name,
    });
  }
  close(): void {
    this.dialogRef.close();
  }
  save(): void {
    if (!this.tabForm.value.name) return this.snack.systemError('Please complete the required fields', 5000);
    this.dialogRef.close(this.tabForm.value);
  }
}
