<div mat-dialog-title>
  <div class="d-f jc-sb ai-c">
    <div>Chat</div>
    <button mat-icon-button (click)="onNoClick()">
      <img src="assets/img/cancel.svg" alt="">
    </button>
  </div>
</div>
<div #chatcontent [scrollTop]="scrolltop" mat-dialog-content>
  <div class="chat-content">
    <div class="message-box" *ngFor="let chatMessage of chatMessages$ | async">
      <!--<div class="chat-status" text-center *ngIf="chat.type==='join'||chat.type==='exit';else message">
        <span class="chat-date">{{chat.date | date:'short'}}</span>
        <span class="chat-content-center">{{chat.txt}}</span>
      </div>-->
        <div class="chat-message">
          <!-- [ngClass]="{'right-bubble': chatMessage.data.senderName === nickname, 'left-bubble': chatMessage.data.senderName !== nickname}" -->
          <div class="right-bubble">
            <span class="msg-name">{{chatMessage.data.senderName}}</span>
            <span class="msg-date"> at {{chatMessage.data.sentDate | date:'short'}}</span>
            <p>{{chatMessage.data.txt}}</p>
          </div>
        </div>
    </div>
  </div>
</div>
