<mat-expansion-panel class="widget mat-elevation-z request-menu" (opened)="onWidgetExpand()" hideToggle>
  <mat-expansion-panel-header class="widget__header">
    <mat-panel-title>
      <div class="expand-icon" [class._active]="false">
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
        </svg>
      </div>
      <div class="title">Services</div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="description">
        <span class="description__first" (click)="$event.stopPropagation(); openCreateService()">Create Service</span>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="widget__body">
    <div *ngIf="services?.length; else noServices">
      <div *ngFor="let service of services; let i = index" class="service-item">
        <div class="service-item__count">
          #{{ i + 1 }}
        </div>
        <div class="service-item__status-container">
          <div class="service-item__status" [ngClass]="'status-' + service.status">
            {{ service.status }}
          </div>
          <div class="service-item__date">{{ service.created | date: 'dd.MM.YYYY HH:mm' }}</div>
        </div>
        <div class="service-item__content">
          <div class="service-item__content__title">{{ service.title }}</div>
          <div class="service-item__content__price">{{ service.price }}<span class="service-item__content__currency">{{ service.currency }}</span></div>
        </div>
        <button mat-icon-button class="service-item__actions">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>
    <ng-template #noServices>
      <p class="no-services">No services yet</p>
    </ng-template>
  </div>
</mat-expansion-panel>
