import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, ReplaySubject, tap } from 'rxjs';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { FeaturedFlightToCreate } from '../../classes/FeaturedFlightToCreate';
import { GlobalSharedService } from '../../global-shared.service';
import { FeaturedFlight } from '../../interfaces/FeaturedFlight';
import { ErrorService } from '../helpers/error.service';

@Injectable({
  providedIn: 'root'
})
export class FeaturedFlightsService {
  private featuredFlightsUrl = `${this.globalSharedService.config.baseUrl}/featured-flights`;
  public featuredFlights$ = new ReplaySubject<FeaturedFlight[]>();

  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
  ) { }

  getFeaturedFlights(): Observable<FeaturedFlight[]> {
    return this.http.get<FeaturedFlight[]>(`${this.featuredFlightsUrl}`, { params: new HttpParams({ encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        map(flights => flights.map(flight => {
          if (flight.bookings) flight.bookings = flight.bookings.filter(booking => !booking.deleted && booking.status !== 'canceled');
          return flight;
        })),
        catchError(this.errorService.handleError)
      );
  }

  getMyFeaturedFlights(query = {}): Observable<FeaturedFlight[]> {
    return this.http.get<FeaturedFlight[]>(`${this.featuredFlightsUrl}/my`, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        map(flights => flights.map(flight => {
          flight.bookings = flight.bookings
            .filter(booking => !booking.deleted && booking.status !== 'canceled')
            .map(booking => {
              booking.bookingLegs.forEach(leg => {
                if (!leg.from.id) leg.from = flight.from;
                if (!leg.to.id) leg.to = flight.to;
              });
              return booking;
            })
          return flight;
        }).sort((a, b) => new Date(a.minDate).getTime() - new Date(b.minDate).getTime())),
        tap(resp => this.featuredFlights$.next(resp)),
        catchError(this.errorService.handleError)
      );
  }

  cancelFeaturedFlightBookings(id: string): Observable<unknown> {
    return this.http.delete<unknown>(`${this.featuredFlightsUrl}/${id}/bookings`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  cancelFeaturedFlight(id?: string): Observable<unknown> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.delete<unknown>(`${this.featuredFlightsUrl}/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateFeaturedFlight(id?: string, flight?: FeaturedFlightToCreate): Observable<unknown> {
    if (!id || !flight) return this.errorService.noPropertyError();
    return this.http.patch<unknown>(`${this.featuredFlightsUrl}/${id}`, flight)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  publishFeaturedFlight(id: string | undefined, published?: boolean): Observable<unknown> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.patch<unknown>(`${this.featuredFlightsUrl}/${id}/publishing`, { published })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createFeaturedFlight(featuredFlight: FeaturedFlightToCreate): Observable<unknown> {
    return this.http.post<unknown>(`${this.featuredFlightsUrl}`, featuredFlight)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getFeaturedFlight(id: string | null): Observable<FeaturedFlight> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<FeaturedFlight>(`${this.featuredFlightsUrl}/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  bookFeaturedFlight(data: unknown): Observable<unknown> {
    return this.http.post<unknown>(`${this.featuredFlightsUrl}/bookings`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  confirmFeaturedFlightBooking(id: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.featuredFlightsUrl}/${id}/bookings`, {})
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
