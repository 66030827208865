import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditOperatorComponent } from '../../../components/modals/edit-operator/edit-operator.component';

@Component({
  selector: 'lib-edit-passenger-type',
  templateUrl: './edit-passenger-type.component.html',
  styleUrls: ['./edit-passenger-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPassengerTypeComponent {
  tabForm = this.fb.group({
    type: [ this.data.initialType, [ Validators.required, Validators.maxLength(30) ] ],
  });
  types = ['adult', 'child', 'infant', 'pet'];
  constructor(
    public dialogRef: MatDialogRef<EditOperatorComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') this.close();
    });
  }
  close(): void {
    this.dialogRef.close();
  }
}
