import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MakeFeaturedDealRoutingModule } from './make-featured-deal-routing.module';
import { MakeFeaturedDealComponent } from './make-featured-deal.component';
import { GlobalSharedModule } from '../../global-shared.module';
import { UnusualPriceComponent } from './unusual-price/unusual-price.component';


@NgModule({
  declarations: [
    MakeFeaturedDealComponent,
    UnusualPriceComponent,
  ],
  imports: [
    CommonModule,
    MakeFeaturedDealRoutingModule,
    GlobalSharedModule,
  ]
})
export class MakeFeaturedDealModule { }
