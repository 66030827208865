import { Pipe, PipeTransform } from '@angular/core';
import { Bid } from '../interfaces/Bid';
import { FeaturedFlight } from '../interfaces/FeaturedFlight';
import { Leg } from '../interfaces/Leg';
import { AirportDatetimeService } from '../services/helpers/airport-datetime.service';
/*
*
*/
@Pipe({name: 'airportDateTime'})
export class AirportDateTime implements PipeTransform {
  constructor(private airportDatetimeSrv: AirportDatetimeService) {}

  transform(value: Leg | Bid | FeaturedFlight, fromOrTo: string, type?: string): string {
    // if value is Bid or bookingLeg
    if (value && (value as Bid).takeoffDate) {
      if (fromOrTo === 'from') {
        return this.airportDatetimeSrv.getAirportDate((value as Bid).takeoffDate, value.from, type);
      }
      if (fromOrTo === 'to') {
        return this.airportDatetimeSrv.getAirportDate((value as Bid).landingDate, value.to, type);
      }
    } else if (value && (value as FeaturedFlight).minDate) {
      // if value is FeaturedFlight
      if (fromOrTo === 'from') {
          return this.airportDatetimeSrv.getAirportDate((value as FeaturedFlight).minDate, value.from, type);
      }
      if (fromOrTo === 'to') {
          return this.airportDatetimeSrv.getAirportDate((value as FeaturedFlight).minDate, value.to, type);
      }
    } else {
      // if value is Leg
      if (fromOrTo === 'from') {
          return this.airportDatetimeSrv.getAirportDate((value as Leg).flightDate, value.from, type);
      }
      if (fromOrTo === 'to') {
          return this.airportDatetimeSrv.getAirportDate((value as Leg).flightDate, value.to, type);
      }
    }
    return 'error';
  }
}
