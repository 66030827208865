<div class="modal">
  <form (ngSubmit)="save()" [formGroup]="tabForm">
    <div class="modal-header">
      <span class="modal-header__text">Rename tab</span>
      <button mat-icon-button class="close-icon" [mat-dialog-close]="true">
        <img src="assets/img/cancel.svg" alt="">
      </button>
    </div>
    <div class="modal-content">
      <mat-form-field  appearance="outline" hideRequiredMarker = true>
        <mat-label>Tab name</mat-label>
        <input matInput type="text" formControlName="title" placeholder="Tab name">
      </mat-form-field>
    </div>
    <div class="modal-footer">
      <div class="footer-bottom">
        <div class="d-f jc-fe">
          <button mat-stroked-button type="button" class="footer-bottom__cancel modal-actions mr-15" (click)="close();">Cancel</button>
          <button mat-stroked-button class="footer-bottom__ok modal-actions">Ok</button>
        </div>
      </div>
    </div>
  </form>
</div>
