import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from '../../../services/helpers/snack.service';

@Component({
  selector: 'lib-edit-operator',
  templateUrl: './edit-operator.component.html',
  styleUrls: [ './edit-operator.component.scss' ]
})
export class EditOperatorComponent {
  tabForm = this.fb.group({
    email: [ '', [ Validators.required, Validators.maxLength(30), Validators.email ] ],
  });
  constructor(
    public dialogRef: MatDialogRef<EditOperatorComponent>,
    private fb: UntypedFormBuilder,
    private snack: SnackService,
    @Inject(MAT_DIALOG_DATA) public email: string,
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') this.close();
    });
    this.tabForm.patchValue({
      email: email,
    });
  }
  close(): void {
    this.dialogRef.close();
  }
  save(): void {
    if (!this.tabForm.value.email) return this.snack.systemError('Please complete the required fields', 5000);
    this.dialogRef.close(this.tabForm.value.email);
  }

}
