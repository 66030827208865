<div class="modal-top row ai-c jc-sb">
	<div class="modal-top__title">Edit OPES Tokens balance</div>
  <button mat-icon-button mat-dialog-close>
    <img src="assets/img/cancel.svg" alt="">
  </button>
</div>
<form [formGroup]="form" class="modal-container">
	<mat-form-field class="wp-100" appearance="outline">
    <mat-label>Balance</mat-label>
    <input matInput type="number" formControlName="amount" min="0">
  </mat-form-field>
	<mat-form-field class="wp-100" appearance="outline">
    <mat-label>Comment</mat-label>
    <input matInput type="text" formControlName="description" min="0">
  </mat-form-field>
</form>
<div class="modal-bottom" (keydown.enter)="confirmModal()">
	<button class="modal-bottom__btn"  mat-stroked-button mat-dialog-close>Cancel</button>
	<button mat-raised-button color="primary" class="modal-bottom__btn ml-10" (click)="confirmModal()">Ok</button>
</div>
