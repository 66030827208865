import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { Client } from '../../interfaces/Client';
import { Pagination } from '../../interfaces/Pagination';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { ReferralTree } from '../../interfaces/ReferralTree';
import { Passenger } from '../../interfaces/Passenger';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private clientsUrl!: string;
  clientInfo$: BehaviorSubject<Client> = new BehaviorSubject({} as Client);
  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private globalSharedService: GlobalSharedService,
  ) {
    this.clientsUrl = `${this.globalSharedService.config.baseUrl}/clients`;
  }

  getClients(query: { [ x: string ]: string | boolean; }): Observable<{ data: Client[], pagination?: Pagination }> {
    return this.http.get<{ data: Client[], pagination?: Pagination }>(this.clientsUrl, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getClientInfo(): Observable<Client> {
    return this.http.get<Client>(this.clientsUrl + `/my`)
      .pipe(
        tap(client => {
          (window as any).Intercom('update', {
            Operational_Office: client.branch.toUpperCase()
          });
          this.clientInfo$.next(client)
        }),
        catchError(this.errorService.handleError)
      );
  }

  getClientById(id: string | null): Observable<Client> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<Client>(this.clientsUrl + `/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getClientReferralTree(id: string | null): Observable<ReferralTree> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<ReferralTree>(this.clientsUrl + `/${id}/referral-tree`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createClient(data: { name: string; type: string; regionCode: string}): Observable<any> {
    return this.http.post<any>(`${this.clientsUrl}`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateClient(data: unknown): Observable<unknown> {
    return this.http.patch<unknown>(`${this.clientsUrl}`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  changeClientVerify(data: any): Observable<any> {
    return this.http.post<any>(`${this.clientsUrl}/verify`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  changeOffice(data: { id: string, branch: string }): Observable<any> {
    return this.http.post<any>(`${this.clientsUrl}/branch`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  setClientNote(id: string, notes: string): Observable<any> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.post<any>(`${this.clientsUrl}/notes`, { id: id, notes: notes })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getClientBalance(id: string, query?: { [ x: string ]: string | boolean; }): Observable<any> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<any>(`${this.clientsUrl}/${id}/balance`, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  getClientRefferalLink(): Observable<string> {
    return this.http.get<string>(`${this.clientsUrl}/referrals`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateClientBalance(id: string, data?: { [ x: string ]: string; }): Observable<any> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.post<any>(`${this.clientsUrl}/${id}/balance`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
