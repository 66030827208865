import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { AircraftSaved } from '../../interfaces/AircraftSaved';

@Injectable({
  providedIn: 'root'
})
export class AircraftsSavedService {
  baseUrl = this.globalSharedService.config.baseUrl
  aircraftsSavedUrl = `${this.baseUrl}/aircrafts-saved`;
  aircraftsSaved$ = new ReplaySubject<AircraftSaved[] | undefined>();

  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService
  ) { }

  getAircraftsSaved(): Observable<AircraftSaved[]> {
    return this.http.get<AircraftSaved[]>(this.aircraftsSavedUrl + '/my')
      .pipe(
        tap(aircraftsSaved => this.aircraftsSaved$.next(aircraftsSaved)),
        catchError(this.errorService.handleError)
      );
  }

  getAircraftsByOperatorId(operatorId: string): Observable<AircraftSaved[]> {
    return this.http.get<AircraftSaved[]>(this.baseUrl + `/operators/${operatorId}/aircrafts-saved`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getAircraftSavedById(id: number): Observable<AircraftSaved> {
    return this.http.get<AircraftSaved>(this.aircraftsSavedUrl + `/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createAircraftSaved(aircraftSavedCreate: any): Observable<AircraftSaved> {
    const formData = new FormData();
    Object.keys(aircraftSavedCreate)
      .forEach(key => {
        if (aircraftSavedCreate[key] !== undefined) {
          formData.append(key, aircraftSavedCreate[key]);
        }
      });
    return this.http.post<AircraftSaved>(this.aircraftsSavedUrl, formData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateAircraftSaved(aircraftSavedPartial: any): Observable<AircraftSaved> {
    const formData = new FormData();
    Object.keys(aircraftSavedPartial)
      .forEach(key => {
        if (aircraftSavedPartial[key] !== undefined) {
          formData.append(key, aircraftSavedPartial[key]);
        }
      });
    return this.http.patch<AircraftSaved>(this.aircraftsSavedUrl, formData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  deleteAircraftSaved(aircraftSavedId: number): Observable<unknown> {
    return this.http.delete<unknown>(`${this.aircraftsSavedUrl}/${aircraftSavedId}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  /*  private log(message: string): void {
      this.messageService.add(`BidService: ${message}`);
    }*/
}
