import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  camelize(s: string): string {
    return s.replace(/-./g, x => x[1].toUpperCase());
  }

}
