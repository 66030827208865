import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { GlobalSharedService } from '../../global-shared.service';
import ClientInviteToCreate from '../../interfaces/ClientInviteToCreate';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { Pagination } from '../../interfaces/Pagination';
import { ErrorService } from '../helpers/error.service';
import { ClientInvite } from '../../interfaces/ClientInvite';

@Injectable({
  providedIn: 'root'
})
export class ClientsInvitesService {
  invitesUrl = `${this.globalSharedService.config.baseUrl}/invites`;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private globalSharedService: GlobalSharedService,
  ) { }

  getInvites(query: { [ x: string ]: string | boolean; }): Observable<{ data: ClientInvite[], pagination?: Pagination } | ClientInvite[]> {
    return this.http.get<{ data: ClientInvite[], pagination?: Pagination } | ClientInvite[]>(this.invitesUrl, {
      params: new HttpParams({
        fromObject: query,
        encoder: new CustomHttpParamEncoder()
      })
    })
      .pipe(
        first(),
        map((invitesResponse: { data: ClientInvite[], pagination?: Pagination } | ClientInvite[]) => {
          if (Array.isArray(invitesResponse)) {
            return invitesResponse.map(invite => {
                if (invite.discount) {
                  invite.discount *= 100;
                }
                return invite;
              }
            );
          } else {
            invitesResponse.data.forEach(invite => {
                if (invite.discount) {
                  invite.discount *= 100;
                }
              }
            );
            return invitesResponse;
          }
        }),
        catchError(this.errorService.handleError)
      );
  }

  getInviteById(id: string | null): Observable<ClientInvite> {
    return this.http.get<ClientInvite>(`${this.invitesUrl}/${id}`,)
      .pipe(
        first(),
        catchError(this.errorService.handleError)
      );
  }

  createInvite(clientInviteToCreate: ClientInviteToCreate): Observable<ClientInvite> {
    return this.http.post<ClientInvite>(this.invitesUrl, clientInviteToCreate)
      .pipe(
        first(),
        map(invite => {
            if (invite.discount) {
              invite.discount *= 100;
            }
            return invite;
          }
        ),
        catchError(this.errorService.handleError)
      );
  }

  sendInviteByEmail(id: string): Observable<ClientInvite> {
    return this.http.post<ClientInvite>(`${this.invitesUrl}/send`, { id })
      .pipe(
        first(),
        catchError(this.errorService.handleError)
      );
  }
}
