<div class="modal-header">
  <span class="modal-header__text">Reviews</span>
  <button mat-icon-button mat-dialog-close>
    <button mat-icon-button mat-dialog-close>
      <img src="assets/img/cancel.svg" alt="">
    </button>
  </button>
</div>

<div class="modal-body">
  <div class="review" *ngFor="let review of reviews">
    <div class="review__header">
      <span class="review__name">{{ review.author?.firstName }} {{ review.author?.lastName }}</span>
      <span class="review__date">{{ review.created | date: 'medium' }}</span>
    </div>
    <div class="review__stars">
      <mat-icon *ngFor="let star of [1, 2, 3, 4, 5]">
        {{ star <= review.value ? 'star' : 'star_border' }}
      </mat-icon>
    </div>
    <div class="review__comment" *ngIf="review.comment">
      {{ review.comment }}
    </div>
  </div>
</div>

<div class="modal-bottom">
  <button mat-raised-button class="modal-bottom__btn" (click)="dialogRef.close()">Ok</button>
</div>
