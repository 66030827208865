<div class="modal">
  <form (ngSubmit)="save()" [formGroup]="tabForm">
    <div class="modal-header">
      <span class="modal-header__text">Rename document</span>
      <button mat-button class="close-icon" mat-dialog-close>
        <img src="assets/img/cancel.svg" alt="">
      </button>
    </div>
    <div class="modal-content">
      <div class="d-f ai-c">
        <div class="title">Document Name</div>
        <mat-form-field hideRequiredMarker = true>
          <input matInput type="name" formControlName="name" placeholder="Type document name">
        </mat-form-field>
      </div>
    </div>
    <div class="modal-footer">
      <div class="footer-bottom">
        <div class="d-f jc-fe">
          <button mat-stroked-button type="button" class="footer-bottom__cancel modal-actions mr-15" (click)="close();">Cancel</button>
          <button mat-raised-button [disabled]="tabForm.invalid" class="footer-bottom__ok modal-actions">OK</button>
        </div>
      </div>
    </div>
  </form>
</div>
