import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { Tag } from '../../../interfaces/Tag';
import { SnackService } from '../../../services/helpers/snack.service';

@Component({
  selector: 'lib-tags',
  templateUrl: './tags.component.html'
})
export class TagsComponent implements OnChanges {
  @Input() tags?: Tag[];
  @Input() isDisabled = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl({value:'', disabled: this.isDisabled});
  filteredTags$!: Observable<string[]>;
  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;
  @Output() tagsAdded = new EventEmitter<string[]>();
  @Output() tagsRemoved = new EventEmitter<number[]>();
  constructor(private snackService: SnackService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled) {
      this.tagCtrl.disable();
    } else this.tagCtrl.enable();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').toUpperCase().trim();
    if (value) {
      if (value.length < 2) {
        return this.snackService.systemError('Tag length must be at least 2 symbols');
      }
      this.tagsAdded.emit([value]);
    }
    event.chipInput!.clear();
    this.tagCtrl.setValue(null);
  }

  removeTag(tag: Tag, index: number): void {
    if(this.tags) {
      this.tags.splice(index, 1);
      this.tagsRemoved.emit([tag.id]);
    }
  }

}
