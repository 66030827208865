import { Injectable } from '@angular/core';
import { DateTime, IANAZone, InvalidZone } from 'luxon';
import { Airport } from '../../interfaces/Airport';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root'
})
export class AirportDatetimeService {
  constructor(
    private timeService: TimeService
  ) { }

  getOffsetFromAirport(airport: Airport): number {
    return +airport.timeZone; // TODO what to return if no offset?
  }

  getTimeZoneFromAirport(airport: Airport): IANAZone {
    if (IANAZone.isValidSpecifier(airport.tzDbTimeZone)) {
      return IANAZone.create(airport.tzDbTimeZone);
    } else {
      return new InvalidZone();
    }
  }

  makeAirportDateTime(date: string | Date | DateTime, airport: Airport): DateTime {
    if (!date) return DateTime.invalid('No date parameter.');
    if (!airport) return DateTime.invalid('No airport parameter.');
    const curTimeZone = this.getTimeZoneFromAirport(airport);
    if (curTimeZone.isValid) {
      if (date instanceof Date) return DateTime.fromISO(date.toISOString()).setZone(curTimeZone);
      if (date instanceof DateTime) return date.setZone(curTimeZone);
      return DateTime.fromISO(date as string).setZone(curTimeZone);
    } else {
      if (date instanceof Date) return DateTime.fromISO(date.toISOString());
      if (date instanceof DateTime) return date;
      return DateTime.fromISO(date as string);
    }
    /*// TODO what to do with offsets?
    const curOffset = this.getOffsetFromAirport(airport);
    if (Number.isNaN(curOffset)) return DateTime.invalid('Offset is NaN.');
    const curDateTime = DateTime.fromISO(curDate);
    return curDateTime.offset = curOffset;*/
  }

  getAirportDate(date: string | Date, airport: Airport, type?: string): string {
    let result = 'error';
    if (date && airport) {
      const tmp = this.makeAirportDateTime(date, airport);
      if (tmp && tmp.isValid) {
        if (type === 'time') {
          result = tmp.startOf('minute').toISOTime({ suppressSeconds: true, suppressMilliseconds: true, });
        }
        else if (type === 'string_time') {
          result = tmp.startOf('minute').toFormat(this.timeService.timeFormat);
        }
        else if (type === 'timezone') {
          result = tmp.startOf('minute').toFormat('ZZZZ');
        }
        else if (type === 'calendar_day') {
          result = tmp.toFormat('dd MMM');
          if (tmp.year !== DateTime.now().year) result += ' ' + tmp.toFormat('yyyy');
        }
        else if (type === 'week_day') {
          result = tmp.toFormat('EEEE');
        }
        else {
          if (type) result = tmp.toFormat(type);
          else result = tmp.toISODate();
        }
      }
    }
    return result;
  }

  getAirportCurDateTime(airport: Airport): DateTime {
    if (!airport) return DateTime.invalid('No airport parameter.');
    const curTimeZone = this.getTimeZoneFromAirport(airport);
    if (curTimeZone.isValid) {
      return DateTime.now().setZone(curTimeZone);
    } else {
      return DateTime.now();
    }
  }
}
