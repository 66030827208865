import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { FRP } from '../../interfaces/FRP';
import { Offer } from '../../interfaces/Offer';
import { SuggestedParametersByFrp } from '../../interfaces/SuggestedParametersByFrp';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { Pagination } from '../../interfaces/Pagination';
import { FormControl } from '@angular/forms';
import { AircraftSaved } from '../../interfaces/AircraftSaved';
import { OperatorsService } from './operators.service';

@Injectable({
  providedIn: 'root'
})
export class FrpsService {
  private frpUrl!: string;
  frps$ = new BehaviorSubject<FRP[] | undefined>(undefined);
  public showFRPsPageCustomTabs = new FormControl(false);
  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
    private operatorService: OperatorsService,
  ) {
    this.frpUrl = `${this.globalSharedService.config.baseUrl}/frps`;
  }

  getFrpById(id: string | null): Observable<FRP> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<FRP>(`${this.frpUrl}/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getFRPs(query = {}): Observable<FRP[]> {
    return this.http.get<FRP[]>(this.frpUrl, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        tap(frps => { this.frps$.next(frps); }),
        catchError(this.errorService.handleError)
      );
  }

  getAllFRPs(query: { [ x: string ]: string | boolean; }): Observable<{ data: FRP[] | number, pagination?: Pagination }> {
    return this.http.get<{ data: FRP[] | number, pagination?: Pagination }>(`${this.frpUrl}/master-tab`, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getMyFRPs(query = {}): Observable<FRP[]> {
    return this.http.get<FRP[]>(`${this.frpUrl}/my`, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        tap(frps => { this.frps$.next(frps); }),
        catchError(this.errorService.handleError)
      );
  }

  getFRPCurrentPage(id: string): Observable<{ data: FRP[] | number, pagination?: Pagination }> {
    return this.http.get<{ data: FRP[] | number, pagination?: Pagination }>(`${this.frpUrl}/master-tab/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getFrpsCount(query = {}): Observable<number> {
    return this.http.get<number>(this.frpUrl, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getOffersByFrpId(id: string, opts = {}): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${this.frpUrl}/${id}/offers`, {
      params: new HttpParams({ fromObject: opts, encoder: new CustomHttpParamEncoder() })
    })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getSuggestedParametersByFRPId(id: string, opts = {}): Observable<SuggestedParametersByFrp> {
    return this.http.get<SuggestedParametersByFrp>(`${this.frpUrl}/${id}/suggestion`, {
      params: new HttpParams({ fromObject: opts, encoder: new CustomHttpParamEncoder() })
    })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  changeFrpAssignee(id: string, userId: string | undefined): Observable<unknown> {
    if (userId) {
      return this.http.post<unknown>(`${this.frpUrl}/${id}/assignee`, { assigneeId: userId })
        .pipe(
          catchError(this.errorService.handleError)
        );
    } else {
      return this.http.delete<unknown>(`${this.frpUrl}/${id}/assignee`)
        .pipe(
          catchError(this.errorService.handleError)
        );
    }
  }

  filterAndSortFrps(frps: FRP[]): FRP[] {
    return frps
      .map(frp => {
        frp.legs.sort((a, b) => new Date(a.flightDate).getTime() - new Date(b.flightDate).getTime());
        return frp;
      })
      .sort((a, b) => new Date(a.legs[0].flightDate).getTime() - new Date(b.legs[0].flightDate).getTime());
  }

  setFrpNote(id: string | undefined, notes: string): Observable<any> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.post<any>(`${this.frpUrl}/notes`, { id: id, notes: notes })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createFrp(frpData: any): Observable<FRP> {
    return this.http.post<FRP>(`${this.frpUrl}`, frpData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  deleteFrp(frpId: string | undefined): Observable<unknown> {
    if (!frpId) return this.errorService.noPropertyError();
    return this.http.delete<unknown>(`${this.frpUrl}/${frpId}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  addTags(frpId: string, tags: string[]): Observable<FRP> {
    return this.http.post<FRP>(`${this.frpUrl}/${frpId}/tags`, tags)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  removeTags(frpId: string, tags: number[]): Observable<unknown> {
    return this.http.delete<unknown>(`${this.frpUrl}/${frpId}/tags`, { body: tags })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateFrpAvinodeTripIDs(data: { frpId: string; avinodeTripIds: string[]; }): Observable<FRP> {
    return this.http.put<FRP>(`${this.frpUrl}/avinode-trips`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  sortOffers(data: Offer[], estimatedBudget?: number): Offer[] {
    return data.sort((routeA, routeB) => {
      const offerA = routeA.bids[0];
      const offerB = routeB.bids[0];

      // 1st level: Premium partners first, Olympus operators go above all premium ones
      const offerAisPremium = this.operatorService.isPremiumOperator(offerA.operator.status)
      const offerAisOlympus = this.operatorService.isOlympusOperator(offerA.operator.id)
      const offerBisPremium = this.operatorService.isPremiumOperator(offerB.operator.status)
      const offerBisOlympus = this.operatorService.isOlympusOperator(offerB.operator.id)

      if (offerAisOlympus || offerBisOlympus) return offerAisOlympus ? -1 : 1;
      if (offerAisPremium || offerBisPremium) return offerAisPremium ? -1 : 1;

      // 2nd level: Demo requests lower than real offers
      const offerAisDemo = this.operatorService.isEstimatorOperator(offerA.operator.id)
      const offerBisDemo = this.operatorService.isEstimatorOperator(offerB.operator.id)
      if (offerAisDemo || offerBisDemo) return offerAisDemo ? 1 : -1;

      // 3rd level: Offers with full aircraft info go above others
      const hasFullAircraftInfo = (aircraftInfo: AircraftSaved) => {
          return aircraftInfo.pictureExterior && aircraftInfo.pictureInterior && 
                aircraftInfo.pictureFloorplan && aircraftInfo.maxSpeed && aircraftInfo.seats && aircraftInfo.yom;
      };

      const offerAHasFullAircraftInfo = hasFullAircraftInfo(offerA.aircraftInfo);
      const offerBHasFullAircraftInfo = hasFullAircraftInfo(offerB.aircraftInfo);

      if (offerAHasFullAircraftInfo || offerBHasFullAircraftInfo) {
        return offerAHasFullAircraftInfo ? -1 : 1;
      }

      // 4th level: Sort by price closer to known budget (if set) or by lowest price
      if (estimatedBudget) {
        const priceDifferenceA = Math.abs(estimatedBudget - offerA.price);
        const priceDifferenceB = Math.abs(estimatedBudget - offerB.price);
        return priceDifferenceA - priceDifferenceB;
      } 
      return offerA.price - offerB.price;
    });
  }
}
