<app-maintenance-banner></app-maintenance-banner>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav col" fixedInViewport [attr.role]="(isTablet$ | async) ? 'dialog' : 'navigation'" [mode]="(isTablet$ | async) ? 'over' : 'side'" [opened]="(isTablet$ | async) === false" libSidebar>
    <mat-toolbar class="logo" routerLink="/">OPES JET</mat-toolbar>
    <mat-nav-list class="fg-1 ofy-auto">
      <div class="pl-16 lh-36 fs-16 block-title">Users management</div>
      <a mat-list-item routerLink="/users" routerLinkActive="active">Users</a>
      <a mat-list-item routerLink="/waiting-list" [queryParams]="{ page: '1', limit: '50' }" [class.active]="router.url.includes('/waiting-list?')">Waiting List</a>
      <a mat-list-item routerLink="/clients" [queryParams]="{ like: '1', page: '0', limit: '50' }" [class.active]="router.url.includes('/clients?')">Clients</a>
      <a mat-list-item routerLink="/operators/list" routerLinkActive="active">Operators</a>
      <a mat-list-item routerLink="/operators/lists" routerLinkActive="active">Operators Lists</a>
      <a mat-list-item routerLink="/clients-invites" [queryParams]="{ page: '0', limit: '50' }" [class.active]="router.url.includes('/clients-invites?')">Client invites</a>
      <a mat-list-item routerLink="/unsubscribers-list" [queryParams]="{ page: '0', limit: '50' }" [class.active]="router.url.includes('/unsubscribers-list?')">Unsubscribers list</a>
      <a mat-list-item routerLink="/clients-discounts" routerLinkActive="active">Client discounts</a>
      <a mat-list-item routerLink="/deleted-accounts" routerLinkActive="active">Deleted accounts</a>

      <div class="pl-16 lh-36 fs-16 block-title">Flights management</div>
      <a mat-list-item routerLink="/frps" routerLinkActive="active">Client requests</a>
      <a mat-list-item routerLink="/bookings" routerLinkActive="active">Bookings</a>
      <a mat-list-item routerLink="/offers" routerLinkActive="active">Offers</a>
      <a mat-list-item routerLink="/chats" routerLinkActive="active">Chats</a>
      <a mat-list-item routerLink="/airports" [queryParams]="{ like: '1', page: '0', limit: '50' }" [class.active]="router.url.includes('/airports?')">Airports</a>
      <a mat-list-item routerLink="/aircraft-categories" routerLinkActive="active">Aircraft categories</a>

      <div class="pl-16 lh-36 fs-16 block-title">System management</div>
      <a mat-list-item routerLink="/maintenance" routerLinkActive="active">Maintenance</a>
      <a mat-list-item routerLink="/rates" routerLinkActive="active">Token rates</a>
      <a mat-list-item routerLink="/download-report" routerLinkActive="active">Report</a>
      <!--<a mat-list-item routerLink="/variables" routerLinkActive="active">Variables</a>-->
      <div class="pl-16 lh-36 fs-16 block-title">WingX</div>
      <a mat-list-item routerLink="/wingX/operators/list" routerLinkActive="active">Operators list</a>
    </mat-nav-list>

    <mat-nav-list>
      <a mat-list-item>
        <div class="name d-f jc-fs ai-c">
          <span class="text-wrap tt-u fw-500 fs-14">{{ curEmail }}</span>
          <button *ngIf="afAuth.user | async as user" (click)="signOut()" class="pl-2" matTooltip="Sign out" mat-icon-button>
            <mat-icon>logout</mat-icon>
          </button>
        </div>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="(isTablet$ | async) === true">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <h1>{{ curTitle$ | async }}</h1>
      <span class="logo" routerLink="/"></span>
      <span class="fill-space"></span>
      <a mat-button routerLink="/signin" *ngIf="(afAuth.user | async) === undefined || (afAuth.user | async) === null">🔑 Sign in</a>
      <div *ngIf="afAuth.user | async" class="d-f jc-fs ai-c">
        <button *ngIf="router.url.includes('/clients-invites')" routerLink="/clients-invites/client-invite" mat-stroked-button class="mr-15">Create invite</button>
        <button *ngIf="router.url.split('?')[0] === '/unsubscribers-list'" (click)="activeComponent?.addToIgnoredList()" mat-stroked-button class="mr-15">Add ignored list</button>
        <button *ngIf="router.url === '/operators/list'" routerLink="/operators/operator-register" mat-stroked-button class="mr-15">Pre Register operator</button>
        <button *ngIf="router.url === '/operators/lists'" mat-raised-button color="primary" class="mr-15" (click)="activeComponent?.openList()">Create list</button>
        <mat-form-field *ngIf="activeComponent?.searchOnShell" appearance="outline" class="search">
          <input matInput type="search" [formControl]="search" placeholder="search">
          <button class="sidenav-container__search d-f jc-c ai-c" mat-icon-button matSuffix>
            <img class="d-f jc-c ai-c fs-25 op-03" src="assets/img/search.svg" alt="">
          </button>
        </mat-form-field>
      </div>
    </mat-toolbar>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
