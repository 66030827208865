import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { EstimatedLeg } from '../../interfaces/EstimatedLeg';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';

@Injectable({
  providedIn: 'root'
})
export class ErsService {
  private ersUrl!: string;
  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService
  ) {
    this.ersUrl = `${this.globalSharedService.config.baseUrl}/ers`;
  }

  getFlightEstimateByLegs(data: object, opts = {}): Observable<{ legs: EstimatedLeg[] }> {
    return this.http.post<{ legs: EstimatedLeg[] }>(this.ersUrl, data, { params: new HttpParams({ fromObject: opts, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
