import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Call, Device } from '@twilio/voice-sdk';
import { TwilioError } from '@twilio/voice-sdk/es5/twilio/errors';
import { catchError, first, Observable } from 'rxjs';
import { GlobalSharedService } from '../../global-shared.service';
import { CallRequest } from '../../interfaces/CallRequest';
import { ErrorService } from '../helpers/error.service';
import { SnackService } from '../helpers/snack.service';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  device!: Device;
  call?: Call;
  twilioUrl!: string;
  onPhone?: any;
  callDuration = 0;
  timer?: number;
  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private snackSrv: SnackService,
    private errorService: ErrorService,
  ) {
    this.twilioUrl = `${this.globalSharedService.config.baseUrl}/twilio`;
  }

  setupDevice(): void {
    this.getTwilioToken().pipe(first())
      .subscribe(token => {
        this.device = new Device(token, { tokenRefreshMs: 5000 });
        this.device.on('tokenWillExpire', () => {
          this.updateDeviceToken();
        });
        this.device.on('error', (twilioError: TwilioError) => {
          if(twilioError.code === 20104) {
            this.updateDeviceToken();
            this.snackSrv.systemError('Twilio token has been refreshed! Try again to call');
            return;
          }
          this.snackSrv.systemError('An error has occurred: ' + twilioError);
          delete this.onPhone;
          this.device.disconnectAll();
        });
      });
  }

  updateDeviceToken(): void {
    this.getTwilioToken()
    .pipe(first())
    .subscribe(token => {
      this.device.updateToken(token);
    });
  }

  getTwilioToken(): Observable<string> {
    return this.http.get<string>(`${this.twilioUrl}/token`)
      .pipe(
        catchError(this.errorService.handleError)
      )
  }

  async toggleCall(call?: CallRequest, onAccept?: () => void, onDisconnect?: (duration: number) => void): Promise<void> {
    if (!this.onPhone && call) {
      this.onPhone = call;
      this.call = await this.device.connect({ params: call.id ? { callRequestId: call.id } : { phoneNumber: call.phoneNumber } });
      this.call.on('accept', () => {
        if (onAccept) onAccept();
        this.callDuration = 0;
        this.timer = window.setInterval(() => {
          this.callDuration += 1000;
        }, 1000);
      });
      this.call.on('disconnect', () => {
        if (onDisconnect) onDisconnect(JSON.parse(JSON.stringify(this.callDuration / 1000)));
        delete this.onPhone;
        this.call?.removeAllListeners();
        delete this.call;
        this.callDuration = 0;
        clearInterval(this.timer);
      });
    } else {
      this.call?.disconnect();
      this.device.disconnectAll();
    }
  }

  toggleMute(): void {
    if (this.call) {
      this.call.mute(!this.call.isMuted());
    }
  }

  destroyDevice(): void {
    this.device.destroy();
  }
}
