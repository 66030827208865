export class Region {
    id: number;
    code: string;
    name: string;

    constructor(region: { id: number, code: string, name: string }) {
        this.id = region.id;
        this.code = region.code;
        this.name = region.name;
    }
}
