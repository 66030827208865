import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Passengers {
  adultsNumber: number;
  childrenNumber: number;
  infantsNumber: number;
  pets: boolean;
  petsDescription: string;
  cargo: boolean;
  cargoDescription: string;
}

@Component({
  selector: 'lib-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: [ './passengers.component.scss' ]
})
export class PassengersComponent {
  passengersForm = this.fb.group({
    adultsNumber: [ 1, [ Validators.required, Validators.min(1) ] ],
    childrenNumber: [ 0 ],
    infantsNumber: [ 0 ],
    pets: [ false ],
    petsDescription: [ '' ],
    cargo: [ false ],
    cargoDescription: [ '' ],
  });
  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PassengersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Passengers,
  ) {
    this.passengersForm.patchValue(this.data);
  }
  close(): void {
    this.dialogRef.close();
  }
  save(): void {
    this.dialogRef.close(this.passengersForm.value);
  }

  addRemovePassenger(field: string, add: boolean): void {
    this.passengersForm.controls[field].setValue(this.passengersForm.value[field] + (add ? 1 : -1));
  }
}
