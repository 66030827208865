import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionAlertComponent } from '../../modals/action-alert/action-alert.component';
import { FrpsService } from '../../../services/api/frps.service';
import { TimeService } from '../../../services/helpers/time.service';
import { first, Subscription } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FRP } from '../../../interfaces/FRP';
import { SnackService } from '../../../services/helpers/snack.service';
import { Leg } from '../../../interfaces/Leg';
import { PassengersService } from '../../../services/api/passengers.service';
import { Passenger } from '../../../interfaces/Passenger';
import { LegsService } from '../../../services/api/legs.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'lib-request',
  templateUrl: './request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestComponent implements OnChanges, OnDestroy {
  @Input() frp?: FRP;
  @Output() frpDeleted = new EventEmitter();
  @ViewChild('autosize') autosize?: CdkTextareaAutosize;
  frpForm = this.fb.group({
    note: [''],
  });
  frpNoteChangeRef?: Subscription;
  now = DateTime.now().toISODate();
  constructor(
    public timeService: TimeService,
    private dialog: MatDialog,
    private frpService: FrpsService,
    private legsService: LegsService,
    private passengersService: PassengersService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private snackService: SnackService,
    private cd: ChangeDetectorRef,
  ) {
    this.onFRPNoteChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes.frp.previousValue?.legs.forEach((element: Leg, i: number) => {
      this.repaintLayer(i);
    });
    if (changes.frp.currentValue?.id != changes.frp.previousValue?.id) this.fillForm();
  }

  ngOnDestroy(): void {
    this.frpNoteChangeRef?.unsubscribe();
  }

  fillForm(): void {
    this.frpForm.setValue({ note: this.frp?.notes?.notes || '' }, { emitEvent: false });
    setTimeout(() => {
      if (this.autosize) this.autosize.resizeToFitContent(true);
    });
  }

  returnToSourcing(): void {
    this.legsService.updateLegsById({
      id: this.frp?.legs[0].id,
      clientId: this.frp?.client.id,
      decisionDate: DateTime.now().plus({ days: 365 }).toISODate()
    }).pipe(first()).subscribe(() => {
      this.closeFrp();
      this.frpDeleted.emit();
    })
  }

  setDecisionRequired(): void {
    this.legsService.updateLegsById({
      id: this.frp?.legs[0].id,
      clientId: this.frp?.client.id,
      decisionDate: DateTime.now().toISODate()
    }).pipe(first()).subscribe(() => {
      this.closeFrp();
      this.frpDeleted.emit();
    })
  }

  deleteFrp(id: string | undefined): void {
    this.dialog.open(ActionAlertComponent, {
      width: '486px',
      data: {
        title: 'Delete flight request?',
        text: 'Are you sure you want to delete the flight request?',
        confirm: () => {
          this.frpService.deleteFrp(id)
            .pipe(first())
            .subscribe({
              next: () => {
                this.closeFrp();
                this.frpDeleted.emit();
              },
              error: error => {
                if (error.status === 400) {
                  let msg = 'You cannot delete an flight request. \n';
                  error.messages.forEach((m: string) => {
                    msg += `\n${m}`;
                  });
                  alert(msg);
                } else {
                  this.snackService.systemError(error.message, 5000);
                }
              }
            });
        }
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
  }

  onFRPNoteChanged(): void {
    this.frpNoteChangeRef = this.frpForm.get('note')?.valueChanges
      .pipe(
        map((note: string) => {
          return {note, frp: this.frp};
        }),
        debounceTime(1000),
      )
      .subscribe(data => {
        this.frpService.setFrpNote(data.frp?.id, data.note)
          .pipe(first())
          .subscribe((savedNote) => {
            if (data.frp) {
              data.frp.notes = savedNote;
            }
          });
      });
  }

  closeFrp(): void {
    const { request, ...query } = this.route.snapshot.queryParams;
    this.router.navigate([`/${window.location.pathname}`], {
      relativeTo: this.route,
      queryParams: query
    });
  }

  downloadPassengerDocumentsAsZip(passenger: Passenger, legId: string): void {
    if (this.frp) this.passengersService.getDocumentsAsZip(passenger.id, { format: 'zip', clientId: this.frp.client.id, leg: legId })
      .pipe(first())
      .subscribe(resp => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(resp);
        downloadLink.setAttribute('download', `${passenger.firstName}${passenger.lastName ? '_' + passenger.lastName : ''}_${passenger.id}`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  }

  getCountryFlagClass(countryCode: string): string {
    return `fi-${ countryCode.toLowerCase() }`;
  }

  get clientBudget(): number {
    return this.frp?.legs?.map(leg => leg.budget).reduce((sum, a) => sum + a, 0) || 0;
  }

  getSWCoordinates(coordinatesCollection: Array<[number, number]>): [number, number] {
    const lowestLng = Math.min(
      ...coordinatesCollection.map((coordinates) => coordinates[0])
    );
    const lowestLat = Math.min(
      ...coordinatesCollection.map((coordinates) => coordinates[1])
    );

    return [lowestLng, lowestLat];
  }

  getNECoordinates(coordinatesCollection: Array<[number, number]>): [number, number] {
    const highestLng = Math.max(
      ...coordinatesCollection.map((coordinates) => coordinates[0])
    );
    const highestLat = Math.max(
      ...coordinatesCollection.map((coordinates) => coordinates[1])
    );

    return [highestLng, highestLat];
  }

  calcBoundsFromCoordinates(coordinatesCollection: Array<[number, number]>): [number, number, number, number] | undefined {
    return coordinatesCollection.length > 1 ? [
      ...this.getSWCoordinates(coordinatesCollection),
      ...this.getNECoordinates(coordinatesCollection),
    ] : undefined;
  }
  map?: any;
  mapLoad(event: any): void {
    this.map = event;
    event.getCanvas().style.cursor = "pointer";
    this.cd.detectChanges();
  }

  repaintLayer(i: number): void {
    if(this.map?.getLayer('pointfrom'+i)) this.map?.removeLayer('pointfrom'+i);
    if(this.map?.getSource('pointfrom'+i)) this.map?.removeSource('pointfrom'+i);
    if(this.map?.getLayer('pointto'+i)) this.map?.removeLayer('pointto'+i);
    if(this.map?.getSource('pointto'+i)) this.map?.removeSource('pointto'+i);
    if(this.map?.getLayer('route'+i)) this.map?.removeLayer('route'+i);
    if(this.map?.getSource('route'+i)) this.map?.removeSource('route'+i);
  }

  get mapLines() {
    return this.frp ? this.frp.legs
      .filter(leg => !!leg.from?.location?.coordinates && !!leg.to?.location?.coordinates)
      .map(leg => [leg.from.location.coordinates, leg.to.location.coordinates]) : [];
  }

  get mapCoordinates(): [number, number, number, number] | undefined {
    return this.calcBoundsFromCoordinates(this.mapLines.flat());
  }
}
