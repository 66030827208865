<div class="modal">
  <form (ngSubmit)="save()" #form="ngForm" [formGroup]="passengersForm">
    <div class="modal-header">
      <span class="modal-header__text">Edit Passengers</span>
      <button mat-button class="close-icon" [mat-dialog-close]="true">
        <img src="assets/img/cancel.svg" alt="">
      </button>
    </div>
    <div class="modal-content">
      <div class="passengers">
        <div class="passengers-row">
          <div class="passengers-row__label">
            <img src="assets/img/passengers/Adult.svg" width="26" height="26" alt="">
            <span class="pl-15">Adults</span>
          </div>
          <div class="passengers-row__actions">
            <button type="button" mat-icon-button (click)="addRemovePassenger('adultsNumber', false)" [disabled]="passengersForm.get('adultsNumber')?.value === 1">-</button>
            <span>{{passengersForm.get('adultsNumber')?.value}}</span>
            <button type="button" mat-icon-button (click)="addRemovePassenger('adultsNumber', true)" [disabled]="passengersForm.get('adultsNumber')?.value + passengersForm.get('childrenNumber')?.value > 17">+</button>
          </div>
        </div>
        <div class="passengers-row">
          <div class="passengers-row__label">
            <img src="assets/img/passengers/Children.svg" width="26" height="26" alt="">
            <span class="pl-15">Children</span>
          </div>
          <div class="passengers-row__actions">
            <button type="button" mat-icon-button (click)="addRemovePassenger('childrenNumber', false)" [disabled]="passengersForm.get('childrenNumber')?.value === 0">-</button>
            <span>{{passengersForm.get('childrenNumber')?.value}}</span>
            <button type="button" mat-icon-button (click)="addRemovePassenger('childrenNumber', true)" [disabled]="passengersForm.get('adultsNumber')?.value + passengersForm.get('childrenNumber')?.value > 17">+</button>
          </div>
        </div>
        <div class="passengers-row border-bottom">
          <div class="passengers-row__label">
            <img src="assets/img/passengers/Infant.svg" width="26" height="26" alt="">
            <span class="pl-15">Infants</span>
          </div>
          <div class="passengers-row__actions">
            <button type="button" mat-icon-button (click)="addRemovePassenger('infantsNumber', false)" [disabled]="passengersForm.get('infantsNumber')?.value === 0">-</button>
            <span>{{passengersForm.get('infantsNumber')?.value}}</span>
            <button type="button" mat-icon-button (click)="addRemovePassenger('infantsNumber', true)" [disabled]="passengersForm.get('infantsNumber')?.value === passengersForm.get('adultsNumber')?.value">+</button>
          </div>
        </div>
        <div class="passengers-row pt-20">
          <div class="passengers-row__label">
            <img src="assets/img/passengers/white-pets.svg" width="26" height="26" alt="" class="fill-gold">
            <span class="pl-15">Pets</span>
          </div>
          <div class="passengers-row__actions">
            <mat-slide-toggle labelPosition="before" formControlName="pets">{{passengersForm.get('pets')?.value ? 'Yes' : 'No'}}</mat-slide-toggle>
          </div>
        </div>
        <div class="passengers-description" *ngIf="passengersForm.get('pets')?.value">
          <div class="label d-f jc-sb ai-c">Describe pet(s)</div>
          <mat-form-field appearance="outline">
            <input matInput type="text" placeholder="Describe pets" aria-label="From Airport" formControlName="petsDescription">
          </mat-form-field>
        </div>
        <div class="passengers-row">
          <div class="passengers-row__label">
            <img src="assets/img/comments-white.svg" width="26" height="26" alt="" class="fill-gold">
            <span class="pl-15">Comment</span>
          </div>
          <div class="passengers-row__actions">
            <mat-slide-toggle labelPosition="before" formControlName="cargo">{{passengersForm.get('cargo')?.value ? 'Yes' : 'No'}}</mat-slide-toggle>
          </div>
        </div>
        <div class="passengers-description" *ngIf="passengersForm.get('cargo')?.value">
          <div class="label d-f jc-sb ai-c">Enter comment</div>
          <mat-form-field appearance="outline">
            <input matInput type="text" placeholder="Enter comment" aria-label="From Airport" formControlName="cargoDescription">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="footer-bottom">
        <div class="wp-40">
        </div>
        <div class="wp-60 d-f jc-fe">
          <button mat-stroked-button type="button" class="footer-bottom__cancel modal-actions" (click)="close();">Cancel</button>
          <button mat-stroked-button class="footer-bottom__ok modal-actions">Ok</button>
        </div>
      </div>
    </div>
  </form>
</div>
