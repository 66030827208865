import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { debounceTime, delay, switchMap } from 'rxjs/operators';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {
  private overlayRef: OverlayRef | undefined = undefined;
  private loading = new BehaviorSubject(false);
  private requests = new Set();
  private delayTime = 0;

  constructor(
    private overlay: Overlay,
  ) {
    this.isLoading(100)
      .subscribe(isLoading => {
        if (isLoading) {
          this.show();
        } else {
          this.hide();
        }
      });
  }

  isLoading(time?: number): Observable<boolean> {
    return this.loading.asObservable().pipe(
      // uses switchMap to cancel the previous event
      switchMap(isLoading =>
        // use iif to put delay only for true value
        iif(
          () => !isLoading,
          of(isLoading).pipe(
            delay(time !== undefined ? time : this.delayTime),
          ),
          of(isLoading),
        ),
      ),
    );
  }

  start(request = 'default', delayTime?: number): void {
    if (delayTime !== undefined) {
      this.delayTime = delayTime;
    }

    this.requests.add(request);
    this.loading.next(true);
  }

  stop(request = 'default'): void {
    this.requests.delete(request);
    if (!this.requests.size) {
      this.loading.next(false);
    }
  }

/*  public readonly spinner$ = defer(() => {
    this.show();
    return NEVER.pipe(
      finalize(() => {
        this.hide();
      })
    );
  }).pipe(share()); */

  public show(): void {
    // Hack avoiding `ExpressionChangedAfterItHasBeenCheckedError` error
    if (this.overlayRef) return;
    Promise.resolve(null).then(() => {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically(),
        hasBackdrop: true,
      });
      this.overlayRef.attach(new ComponentPortal(LoadingSpinnerComponent));
    });
  }

  public hide(): void {
    this.overlayRef?.detach();
    this.overlayRef = undefined;
  }
}
