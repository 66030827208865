import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
  selector: 'lib-popup-default',
  templateUrl: './popup-default.component.html',
  styleUrls: ['./popup-default.component.scss']
})
export class PopupDefaultComponent implements AfterViewInit {
  @Input() head!: string;
  @Input() description!: string;
  @Input() copyOnClick = '';
  @Input() hoverOpen!: boolean;
  @Input() maxWidth!: number;

  menuState = new BehaviorSubject(false);
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChild('triggerElement') triggerElement!: ElementRef;

  ngAfterViewInit(): void {
    if (this.hoverOpen) {
      this.menuState.pipe(
        debounceTime(200)
      ).subscribe(opened => {
        if(opened) this.trigger.openMenu();
        else this.trigger.closeMenu();
      })
      this.triggerElement.nativeElement.addEventListener('mouseenter', () => {
        if (window.matchMedia('(min-width: 992px)').matches && !this.trigger.menuOpen) {
          this.menuState.next(true);
        }
      });
      this.triggerElement.nativeElement.addEventListener('mouseleave', () => {
        this.menuState.next(false);
      });
    }
  }

}
