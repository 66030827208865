<div class="right-content tab-chat">
  <div *ngIf="(chatMessages$ | async)?.length; else chat;">
    <div class="chat" #chatcontent>
      <div class="chat-row" *ngFor="let message of chatMessages$ | async; index as i">
        <div class="chat-day" *ngIf="!sameWithPreviousDate(i)">
          {{message?.sentDate | date: 'MMM, d'}}
        </div>
        <div class="row-body" [class.new-sender]="chatUsersByFirebaseId[message.senderId]?.firebaseId === curUser?.firebaseId">
          <div class="row-header">
            <div class="header-icon" *ngIf="!sameWithPreviousSender(i)">
              <div *ngIf="chatUsersByFirebaseId[message.senderId]?.firstName && chatUsersByFirebaseId[message.senderId]?.lastName; else noSender">
                {{chatUsersByFirebaseId[message.senderId].firstName[0]}}{{chatUsersByFirebaseId[message.senderId].lastName[0]}}
              </div>
              <ng-template #noSender>
                <mat-icon>person</mat-icon>
              </ng-template>
            </div>
          </div>
          <div class="row-content">
            <div class="row-name" *ngIf="!sameWithPreviousSender(i)">
              {{ client && client.owners[0].firebaseId === message.senderId ? chatUsersByFirebaseId[message.senderId].firstName + ' ' + chatUsersByFirebaseId[message.senderId].lastName : message.senderName}}
            </div>
            <div class="row-text" [innerText]="message.txt"></div>

            <button class="edit-button" *ngIf="message.senderId === curUser?.firebaseId" (click)="editMessage(message)">Edit</button>
          </div>
          <div class="message-status" (click)="toggleReadBy(message)" *ngIf="message.readBy && message.senderId === curUser?.firebaseId">
            <span class="checkmark">✔✔</span>
            <div #tooltip  *ngIf="readByVisible === message.id" class="tooltip">
              <span class="tooltip-header">Seen by:</span>
              <ul>
                <li *ngFor="let userId of getReaders(message.readBy)">
                  {{chatUsersByFirebaseId[userId].firstName}} {{chatUsersByFirebaseId[userId].lastName}}
                  <span class="tooltip-time">{{ message.readBy?.[userId] | date: 'dd.MM.yyyy HH:mm' }}</span>
                </li>
              </ul>
            </div>
          </div>

          <div class="row-date">{{message.sentDate | date: 'HH:mm'}}</div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #chat>
    <div class="no-chat">No messages yet</div>
  </ng-template>
</div>
<div class="right-footer">
  <form class="footer-chat" [formGroup]="chatForm" (ngSubmit)="!editingMessageId ? onFormSubmit(formDirective) : saveEditingMessage()" #formDirective="ngForm">
    <mat-form-field (keydown.enter)="$event.preventDefault(); !editingMessageId ? onFormSubmit(formDirective) : saveEditingMessage()" appearance="outline" class="chat-field">
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" class="chat-field__message" formControlName="message"></textarea>
    </mat-form-field>
    <div class="d-f ai-c" [ngClass]="{ 'fd-c': !!editingMessageId }">
      <button mat-button [disabled]="!chatForm.value.message" class="chat-field__button">{{editingMessageId ? 'Save' : 'Send'}}</button>
      <button *ngIf="editingMessageId" mat-button class="cancel-button" (click)="cancelEdit()">Cancel edit</button>
    </div>
  </form>
</div>