import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[libSmoothScroll]'
})
export class SmoothScrollDirective implements AfterViewInit {
  @Input() libSmoothScroll = '';
  @Input() scrollId?: string;
  @Input() elementScroll?: boolean;
  private topArrow!: HTMLDivElement;

  constructor(
    private elementRef: ElementRef,
  ) {
    this.topArrow = document.createElement('div');
    this.topArrow.innerHTML = `<button class="chevron__btn chevron mat-mdc-icon-button mat-mdc-button-base"><span class="material-icons" style="font-size: 36px;">expand_less</span></buuton>`;
    this.topArrow.className = 'chevron';
    this.topArrow.style.setProperty('display', 'none', 'important');
    this.topArrow.addEventListener('click', () => {
      this.smoothVerticalScrolling();
    });
  }

  @HostListener('scroll') onElementScroll(): void {
    if(this.elementScroll) {
      if (this.elementRef.nativeElement.scrollTop > 800) {
        this.topArrow.style.setProperty('display', 'block', 'important');
      } else {
        this.topArrow.style.setProperty('display', 'none', 'important');
      }
      if (this.libSmoothScroll) sessionStorage.setItem(this.libSmoothScroll, this.elementRef.nativeElement.scrollTop.toString());
    }
  }

  @HostListener('window:scroll') onScroll(): void {
    if(!this.elementScroll) {
      if (document.documentElement.scrollTop > 800) {
        this.topArrow.style.setProperty('display', 'block', 'important');
      } else {
        this.topArrow.style.setProperty('display', 'none', 'important');
      }
      if (this.libSmoothScroll) sessionStorage.setItem(this.libSmoothScroll, document.documentElement.scrollTop.toString());
    }
  }

  ngAfterViewInit(): void {
    (this.elementRef.nativeElement as Element).append(this.topArrow);
    if (this.scrollId) this.scrollToId(this.scrollId);
    else this.scrollToPosition();
  }

  scrollToPosition(): void {
    if (this.libSmoothScroll) {
      const scrollPos = sessionStorage.getItem(this.libSmoothScroll);
      if (scrollPos) {
        if(this.elementScroll) this.elementRef.nativeElement.scrollTo({ top: +scrollPos, left: 0 });
      else document.documentElement.scrollTo({ top: +scrollPos, left: 0 });
      }
    }
  }

  scrollToId(id: string): void {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
      if(this.elementScroll) this.elementRef.nativeElement.scrollBy(0, -100);
      else document.documentElement.scrollBy(0, -100);
    } else this.scrollToPosition();
  }

  smoothVerticalScrolling(): void {
    if(this.elementScroll) this.elementRef.nativeElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    else document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

}
