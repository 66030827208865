import { Directive, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Directive({
  selector: '[libSidebar]'
})
export class SidebarDirective implements AfterViewInit {

  constructor(
    private drawer: MatSidenav,
  ) { }

  ngAfterViewInit(): void {
    this.drawer.openedChange.subscribe(bool => {
      document.documentElement.classList.toggle('mob-overflow', bool);
    });
  }
}
