<div class="flight">
  <div class="flight-header">
    <div class="header-logo" [routerLink]="'/featured-flights'">
      <img src="assets/img/gold-logo.svg" alt="" class="header-logo__plane">
      <img src="assets/img/opes-jet.svg" alt="" class="header-logo__text">
    </div>
    <div class="header-title">
      <div class="d-f">
        <span class="pr-5">Featured <span class="header-title__request">flight offer</span></span>
        <lib-popup-default head="{{featuredFlight?.id?.toUpperCase()}}" copyOnClick="{{featuredFlight?.id}}" description="Copy empty leg request ID to Clipboard by Clicking" class="pointer">
          <div># {{featuredFlight?.id?.slice(0,7)}}</div>
        </lib-popup-default>
      </div>
    </div>
    <div class="header-user">
      <div class="d-f jc-c fd-c">
        <span>{{curUser?.operator?.name || curUser?.email}}</span>
        <div class="user-content">
          <span class="user-content__status" *ngIf="curUser?.operator?.status !== 'gray'" [class.gold]="curUser?.operator?.status === 'gold'" [class.silver]="curUser?.operator?.status === 'silver'" [class.bronze]="curUser?.operator?.status === 'bronze'">{{curUser?.operator?.status}}</span>
        </div>
      </div>
      <img width="44" height="44" [src]="curUser?.operator?.logo || 'assets/img/plane-with-border.svg'" alt="" class="header-user__logo">
    </div>
  </div>
  <div class="flight-body">
    <div class="body-title">
      <span *ngIf="featuredFlight?.status !== 'accepted_by_operator'">Please <span class="gold">check details</span> before confirming</span>
    </div>
    <form class="body-content" [formGroup]="flightForm">
      <div class="content left">
        <div class="content-row">
          <span class="content-row__label">Empty Leg offer</span>
          <div>
            <mat-checkbox formControlName="emptyLeg" color="primary">Empty leg conditions</mat-checkbox>
          </div>
        </div>
        <div class="content-row">
          <img src="assets/img/calendar.svg" width="44" height="44" alt="" class="mr-45">
          <div class="row-block fg-1">
            <div class="row-title">Date</div>
            <div class="row-content">{{booking?.flightDate | date: 'MMMM d y'}}</div>
          </div>
          <div class="w-96">
            <div class="row-title">Take off (local)</div>
            <div class="row-content">
              <mat-form-field appearance="outline" class="ta-c">
                <input matInput libTimeMask type="text" formControlName="takeoffTime" (timeChange)="flightTimeChange()">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="content-row">
          <img src="assets/img/arrow-up-right.svg" width="44" height="44" alt="" class="mr-45">
          <div class="row-block fg-1">
            <div class="row-title">Departure airport</div>
            <div class="row-content">{{featuredFlight?.from?.name}} ({{featuredFlight?.from?.icao}})</div>
          </div>
          <div class="w-96">
            <div class="row-title">Landing time</div>
            <div class="row-content">
              <mat-form-field appearance="outline" class="ta-c">
                <input matInput libTimeMask type="text" formControlName="landingTime" (timeChange)="flightTimeChange()">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="content-row">
          <img src="assets/img/arrow-down-right.svg" width="44" height="44" alt="" class="mr-45">
          <div class="row-block fg-1">
            <div class="row-title">Destination airport</div>
            <div class="row-content">{{featuredFlight?.to?.name}} ({{featuredFlight?.to?.icao}})</div>
          </div>
          <div class="w-96">
            <div class="row-title">Flight time</div>
            <div class="row-content d-f ai-c">
              <mat-icon class="mr-10">schedule</mat-icon>
              <span>{{flightForm.value?.flightTime || '00:00'}}</span>
            </div>
          </div>
        </div>
        <div class="content-row">
          <img src="assets/img/comments.svg" width="44" height="44" alt="" class="mr-45 mt-20">
          <div class="row-block fg-1">
            <div class="row-title">Comment</div>
            <div class="row-content">{{flightForm.value?.description}}</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-row pb-30">
          <img src="assets/img/users.svg" width="44" height="44" alt="" class="mr-45">
          <div class="edit">
            <div *ngIf="bookingLeg" class="passengers">
              <div class="passengers__item">
                <img src="assets/img/passengers/Adult.svg" width="26" height="26" alt="">
                <span>{{bookingLeg.adultsNumber}}</span>
              </div>
              <div class="passengers__item">
                <img src="assets/img/passengers/Children.svg" width="26" height="26" alt="">
                <span>{{bookingLeg.childrenNumber}}</span>
              </div>
              <div class="passengers__item">
                <img src="assets/img/passengers/Infant.svg" width="26" height="26" alt="">
                <span>{{bookingLeg.infantsNumber}}</span>
              </div>
              <lib-popup-default *ngIf="bookingLeg.pets" head="Pets" [description]="bookingLeg.petsDescription" [hoverOpen]="true">
                <div class="passengers__item jc-c nonum">
                  <img src="assets/img/pets.svg" width="26" height="26" alt="">
                </div>
              </lib-popup-default>
            </div>
          </div>
        </div>
        <div class="content-row">
          <span class="content-row__aircraft">{{featuredFlight?.aircraft?.typeName}}</span>
          <span class="content-row__tail">{{featuredFlight?.aircraft?.tailNumber}}</span>
        </div>
        <div class="content-row">
          <div class="aircraft">
            <div class="aircraft__logo">
              <img [src]="featuredFlight?.aircraft?.pictureExterior || 'assets/img/logo_dark.png'" alt="">
            </div>
            <div class="aircraft__details">
              <div class="field">
                <div class="d-f fg-1">
                  <img src="assets/img/aircraft/air-type.svg" width="24" height="24" alt="">
                  <div class="pl-10 d-f ai-c tt-c">{{featuredFlight?.aircraft?.categoryName}} Jet</div>
                </div>
                <div class="row jc-sb field-stats">
                  <div>Manufactured in</div>
                  <div>{{featuredFlight?.aircraft?.yom || 'Not added'}}</div>
                </div>
              </div>
              <div class="field">
                <div class="d-f fg-1">
                  <img src="assets/img/aircraft/speed.svg" width="24" height="24" alt="">
                  <div class="pl-10 d-f ai-c">{{featuredFlight?.aircraft?.maxSpeed || 'Not added'}}</div>
                </div>
                <div class="row jc-sb field-stats">
                  <div>Refurbished in</div>
                  <div>{{featuredFlight?.aircraft?.yor || 'Not added'}}</div>
                </div>
              </div>
              <div class="field">
                <div class="d-f fg-1">
                  <img src="assets/img/aircraft/max-pas.svg" width="24" height="24" alt="">
                  <div class="pl-10 d-f ai-c">{{featuredFlight?.aircraft?.seats || 'Not added'}}</div>
                </div>
                <div class="row jc-sb field-stats">
                  <div>Reg.Country</div>
                  <div>{{featuredFlight?.aircraft?.homebase?.countryName || 'Not added'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-row pt-10">
          <img src="assets/img/purse.svg" width="44" height="44" alt="" class="mr-45">
          <div class="row-block fg-1">
            <div class="row-title price">Price</div>
            <div class="row-content small">{{featuredFlight?.priceCurCode}} {{featuredFlight?.price}}</div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="flight-footer">
    <ng-container *ngIf="featuredFlight?.status !== 'accepted_by_operator'">
      <div class="footer-label">
        <span class="footer-label__text">Client is interested in your offer. <span class="gold">Please reconfirm your availability & price</span></span>
      </div>
      <div class="footer-actions">
        <button mat-flat-button class="footer-actions__confirm" (click)="confirmFlight()">CONFIRM FLIGHT OFFER</button>
        <button mat-icon-button class="footer-actions__more" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();">
          <img src="assets/img/more_vert_black.svg" alt="">
        </button>
        <mat-menu #menu="matMenu" yPosition="below">
          <button type="button" mat-menu-item (click)="cancelFlight()">
            <span>Remove flight offer</span>
          </button>
        </mat-menu>
      </div>
    </ng-container>
  </div>
</div>
