<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav col" fixedInViewport [attr.role]="(isMobile$ | async) ? 'dialog' : 'navigation'" [mode]="'over'" [opened]="false" libSidebar>
    <mat-toolbar class="logo" routerLink="/">
      <img class="logo" src="assets/img/light-logo.svg" alt="OPES JET Logo">
    </mat-toolbar>
    <mat-nav-list class="fg-1 ofy-auto">
      <a type="button" mat-list-item [class.active]="tab?.value === 'make-offer'" (click)="tab.patchValue('make-offer'); drawer.toggle()">
        <span class="default-label">Client Request</span>
      </a>
      <a type="button" mat-list-item [class.active]="tab?.value === 'competing-offer'" (click)="tab.patchValue('competing-offer'); drawer.toggle()">
        <span class="default-label">Competing offers</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="make-offer__topbar" [class.authorized-topbar]="isAuthorized">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="offers?.length &&(isMobile$ | async) === true">
        <mat-icon aria-label="Side nav toggle icon" color="accent">menu</mat-icon>
      </button>
      <a class="topbar-logo" [routerLink]="userService?.isOperator ? '/frps' : '/requests'">
        <img src="assets/img/gold-logo.svg" alt="" class="topbar-logo__plane">
        <img src="assets/img/opes-jet.svg" alt="" class="topbar-logo__text">
      </a>
      <div class="topbar-title" *ngIf="isAuthorized; else nonAuthorized">
        <span *ngIf="bookingId">{{isEditing ? 'EDIT' : 'MAKE'}} CONFIRMED FLIGHT</span>
        <div *ngIf="isDraftOffer else btns">CONFIRM OFFER</div>
        <ng-template #btns>
          <div class="topbar-btns" *ngIf="!bookingId && offers?.length">
            <mat-button-toggle-group [formControl]="tab">
              <mat-button-toggle value="make-offer">
                {{isEditing ? 'EDIT' : 'MAKE'}} OFFER
              </mat-button-toggle>
              <mat-button-toggle value="competing-offer">
                COMPETING OFFERS
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </ng-template>
      </div>
      <ng-template #nonAuthorized>
        <div class="topbar-unauthorized">
          <span><span class="topbar-unauthorized__gold">NO</span> SUBSCRIPTION FEES</span>
        </div>
      </ng-template>
      <div class="topbar-info" *ngIf="isAuthorized">
        <div *ngIf="userService?.isOperator; else superBroker" class="topbar-info__data">
          <div class="topbar-info__operator">{{curUser?.operator?.name || curUser?.email}}</div>
          <div class="info-content">
            <span class="info-content__status" *ngIf="curUser?.operator?.status !== 'gray'" [class.gold]="curUser?.operator?.status === 'gold'" [class.silver]="curUser?.operator?.status === 'silver'" [class.bronze]="curUser?.operator?.status === 'bronze'">{{curUser?.operator?.status}}</span>
          </div>
        </div>
        <ng-template #superBroker>
          <div class="topbar-info__data">
            <div class="topbar-info__role">Super Admin</div>
            <div class="topbar-info__name">{{curUser?.email}}</div>
          </div>
        </ng-template>
        <div class="topbar-info__avatar">
          <img [src]="curUser?.operator?.logo || 'assets/img/plane-with-border.svg'" alt="">
        </div>
      </div>
    </div>
    <div class="make-offer__container">
      <ng-container *ngIf="(isMobile$ | async) === true">
        <mat-tab-group *ngIf="tab?.value === 'make-offer'" class="wp-100" selectedIndex="1">
          <mat-tab label="Request">
            <ng-container *ngTemplateOutlet="requestSide"></ng-container>
          </mat-tab>
          <mat-tab label="Make Offer">
            <ng-container *ngTemplateOutlet="makeOfferSide"></ng-container>
          </mat-tab>
        </mat-tab-group>
        <ng-container *ngIf="tab?.value === 'competing-offer'">
          <ng-container *ngTemplateOutlet="comtetingOffersSide"></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="(isMobile$ | async) === false">
        <ng-container *ngTemplateOutlet="requestSide"></ng-container>
        <ng-container *ngIf="tab?.value === 'make-offer'">
          <ng-container *ngTemplateOutlet="makeOfferSide"></ng-container>
        </ng-container>
        <ng-container *ngIf="tab?.value === 'competing-offer'">
          <ng-container *ngTemplateOutlet="comtetingOffersSide"></ng-container>
        </ng-container>
      </ng-container>
      <div *ngIf="!isAuthorized" class="login-content">
        <lib-login (logined)="resetPage()"></lib-login>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


<ng-template #requestSide>
  <div class="left">
    <div class="head">
      <div class="head__title">Charter request</div>
      <ng-container *ngIf="legs">
        <!-- <lib-popup-default *ngIf="!isEditing" [head]="legs[0].frpId.toUpperCase()" [copyOnClick]="legs[0].frpId" description="Copy Charter request ID to Clipboard by Clicking">
          <div class="head__id">{{legs[0].frpId.slice(0,7)}}</div>
        </lib-popup-default> -->
        <a *ngIf="!isEditing" class="head__id" routerLink="/requests/new" [queryParams]="{ 'request': '123e83b9-1d46-4f35-a902-d81d5fad5ef7' }">{{legs[0].frpId.slice(0,7)}}</a>
        <a *ngIf="isEditing" class="head__id" routerLink="/requests/progress" [queryParams]="{ 'request': '123e83b9-1d46-4f35-a902-d81d5fad5ef7' }">{{legs[0].frpId.slice(0,7)}}</a>
      </ng-container>
    </div>
    <div class="content" (touchstart)="onScrollTwo = false; onScrollOne = true;" (mouseover)="onScrollTwo = false; onScrollOne = true;" (scroll)="updateScrollOne()" #scrollOne>
      <div *ngFor="let leg of legs; index as i" class="leg">
        <div class="leg__title-text">LEG - {{i+1}}</div>
        <div class="leg__field">
          <img src="assets/img/calendar.svg" width="44" height="44" alt="">
          <div class="ml-25">
            <div class="name">{{leg | airportDateTime: 'from' : 'EEEE yyyy'}}</div>
            <div class="value">{{leg | airportDateTime: 'from' : 'MMMM dd ' + timeService.timeFormat}} (LT)</div>
          </div>
        </div>
        <div class="leg__field">
          <img src="assets/img/arrow-up-right.svg" width="44" height="44" alt="">
          <div *ngIf="leg.fromCityOnly" class="ml-25">
            <div class="name">Departure city {{leg.from?.city}}</div>
            <div class="value">
              <span>{{leg.from?.city}}</span>
              <span *ngIf="leg.from?.province">, {{leg.from?.province}}</span>
              <span>, {{leg.from?.countryName}}</span>
            </div>
          </div>
          <div *ngIf="!leg.fromCityOnly" class="ml-25">
            <div class="name">Departure airport {{leg.from?.icao}}</div>
            <div class="value">{{leg.from?.name}}, {{leg.from?.city}}, {{leg.from?.countryName}}</div>
          </div>
        </div>
        <div class="leg__field">
          <img src="assets/img/arrow-down-right.svg" width="44" height="44" alt="">
          <div *ngIf="leg.toCityOnly" class="ml-25">
            <div class="name">Destination city {{leg.to?.city}}</div>
            <div class="value">
              <span>{{leg.to?.city}}</span>
              <span *ngIf="leg.to?.province">, {{leg.to?.province}}</span>
              <span>, {{leg.to?.countryName}}</span>
            </div>
          </div>
          <div *ngIf="!leg.toCityOnly" class="ml-25">
            <div class="name">Destination airport {{leg.to?.icao}}</div>
            <div class="value">{{leg.to?.name}}, {{leg.to?.city}}, {{leg.to?.countryName}}</div>
          </div>
        </div>
        <div class="leg__field">
          <img src="assets/img/users.svg" width="44" height="44" alt="">
          <div class="ml-25">
            <div class="name">Passengers</div>
            <div class="value">
              <span *ngIf="leg.adultsNumber">{{leg.adultsNumber}} Adults</span>
              <span *ngIf="leg.childrenNumber"> {{leg.childrenNumber}} Children</span>
              <span *ngIf="leg.infantsNumber"> {{leg.infantsNumber}} Infants</span>
            </div>
          </div>
        </div>
        <div *ngIf="leg.pets" class="leg__field">
          <img src="assets/img/pets.svg" width="44" height="44" alt="">
          <div class="ml-25">
            <div class="name">Pets</div>
            <div class="value">{{leg.petsDescription || 'No pets description'}}</div>
          </div>
        </div>
        <div *ngIf="leg.cargo" class="leg__field">
          <img src="assets/img/comments.svg" width="44" height="44" alt="">
          <div class="ml-25">
            <div class="name">Comments/Requests</div>
            <div class="value">{{leg.cargoDescription || 'No comments'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <!-- <div class="request-category">
        <div class="segment">Light</div>
        <div class="segment">Midsize</div>
        <div class="segment">Super Midsize</div>
        <div class="segment">Heavy</div>
        <div class="slider"></div>
      </div> -->
      <div *ngIf="legs" class="leg__field">
        <img src="assets/img/currency/{{legs[0]?.budgetCurCode}}.svg" width="44" height="44" alt="">
        <div class="ml-25">
          <div class="name">Client’s price expectation</div>
          <div class="value">{{legs[0].budgetCurCode}} {{clientBudget | number}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #makeOfferSide>
  <form class="right" [class.submit]="submitState" action="#" [formGroup]="bidsForm" (ngSubmit)="onSubmit()" [class.unauthorized]="!isAuthorized">
    <ng-container *ngIf="!successState">
      <div class="head">
        <ng-container *ngIf="!submitState">
          <div class="head__left">
            <!-- <div class="add-op">
              + Add operator
            </div> -->
            <div class="operator">
              <mat-form-field appearance="outline">
                <mat-label>Select operator</mat-label>
                <input matInput [matAutocomplete]="curOperator" formControlName="operator" type="text">
                <mat-autocomplete #curOperator="matAutocomplete" [displayWith]="displayOperatorFn">
                  <mat-option *ngFor="let operator of operatorsFiltered" [value]="operator">
                    <div class="operator-line">
                      <div class="operator-line__name">{{operator?.name}}</div>
                      <div class="operator-line__contacts">
                        <ng-container *ngIf="operator | getOperatorEmailAndPhone as currentOperator">
                          <span *ngIf="currentOperator.email">{{currentOperator.email}}</span>
                          <span [class.left]="!!currentOperator.email">{{currentOperator.phone}}</span>
                        </ng-container>
                      </div>
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <div class="head__right">
            <ng-container *ngIf="bidsForm.get('operator')?.value | getOperatorEmailAndPhone as currentOperator">
              <div *ngIf="currentOperator.phone" class="contact-field">
                <img src="assets/img/user/phone.svg" width="26" height="26" alt="">
                <span>{{currentOperator.phone}}</span>
              </div>
              <div *ngIf="currentOperator.email" class="contact-field">
                <img src="assets/img/user/email.svg" width="26" height="26" alt="">
                <span>{{currentOperator.email}}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="submitState">
          <div class="head__title">Offer to be sent to the client</div>
          <button mat-stroked-button (click)="back()">Edit</button>
        </ng-container>
      </div>
      <div class="content" formArrayName="bidsList" (touchstart)="onScrollOne = false; onScrollTwo = true;" (mouseover)="onScrollOne = false; onScrollTwo = true;" (scroll)="updateScrollTwo()" #scrollTwo>
        <div *ngFor="let item of bidsList.controls; let i = index" class="leg">
          <ng-container [formGroupName]="i">
            <div class="leg__title-box">
              <div class="leg__title-box__header">
                <div class="d-f ai-c">
                  <div class="leg__title-text">LEG - {{i+1}}</div>
                  <div *ngIf="!i" class="ref mr-10">
                    <span class="label mr-15">Ref №</span>
                    <mat-form-field *ngIf="!submitState" appearance="outline" class="price">
                      <input matInput type="text" formControlName="refNumber" placeholder="Enter ref number">
                    </mat-form-field>
                    <span *ngIf="submitState">{{ item.value?.refNumber }}</span>
                  </div>
                </div>
                <mat-checkbox formControlName="emptyLeg" class="leg__title-checkbox" color="primary">
                  <span matTooltip="Empty leg conditions">EL</span>
                </mat-checkbox>
              </div>
              <div class="make-featured" *ngIf="!i&&!hideFeaturedDealButton">
                <a routerLink="/make-featured-deal" [queryParams]="{ offer: offerId, leg: item.value.id }" target="_blank" rel="noopener noreferrer">
                  Make Featured
                </a>
              </div>
            </div>
            <div class="leg__field">
              <img src="assets/img/calendar.svg" width="44" height="44" alt="">
              <div class="edit">
                <div class="col wp-60 pr-10 calendar">
                  <div class="label">Date</div>
                  <mat-form-field *ngIf="!submitState" appearance="outline">
                    <input matInput [matDatepicker]="departureDate" formControlName="flightDate" (dateChange)="onDateChanged(item)">
                    <mat-datepicker-toggle class="calendar-icon" matSuffix [for]="departureDate"></mat-datepicker-toggle>
                    <mat-datepicker #departureDate></mat-datepicker>
                    <mat-error class="fs-11">
                      The date should be greater than current
                    </mat-error>
                  </mat-form-field>
                  <div *ngIf="submitState" class="preview">{{item.value.flightDate | date: 'MMMM dd YYYY'}}</div>
                </div>
                <div class="col wp-40 form-row time-picker">
                  <div class="label">Takeoff time (LT)</div>
                  <mat-form-field *ngIf="!submitState" appearance="outline">
                    <input matInput libTimeMask type="text" formControlName="takeoffTime" (timeChange)="onTimeChanged($event, item)">
                  </mat-form-field>
                  <div *ngIf="submitState" class="preview">{{item.value.takeoffTime}}</div>
                </div>
              </div>
            </div>
            <div class="leg__field">
              <img src="assets/img/arrow-up-right.svg" width="44" height="44" alt="">
              <div class="edit">
                <div class="form-row wp-60 pr-10 col">
                  <div class="label d-f jc-sb ai-c">Departure airport</div>
                  <mat-form-field *ngIf="!submitState" appearance="outline">
                    <input matInput type="text" placeholder="Select airport from {{legs[i].from?.city}}" aria-label="From Airport" [matAutocomplete]="fromAirport" formControlName="from" (focus)="focusAirport($event)">
                    <mat-autocomplete #fromAirport="matAutocomplete" [displayWith]="displayFn" (optionSelected)="timingsRecalculate($event.option.value, item, 'takeoff'); getFlightEstimateByLegs()">
                      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                      <ng-container *ngIf="!isLoading">
                        <mat-option *ngFor="let airport of filteredFromAirports" [value]="airport">({{airport?.icao}}) {{airport?.name}} <mat-icon *ngIf="airport?.closed" inline="true" class="closed-airport">block</mat-icon>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                  <div *ngIf="submitState" class="preview">{{item.value.from?.name}} ({{item.value.from?.icao}})</div>
                </div>
                <div class="col wp-40 form-row flight-time">
                  <div class="label d-f jc-sb ai-c">
                    <span>Flight time</span>
                    <span *ngIf="item.value?.aircraftInfo?.categoryName && estimatedFlightTime?.length && estimatedFlightTime[i] && estimatedFlightTime[i][item.value?.aircraftInfo?.categoryName]" class="fs-12 op-05 ta-r">est ({{ estimatedFlightTime[i][item.value?.aircraftInfo?.categoryName] }})</span>
                  </div>
                  <div *ngIf="!submitState" class="d-f jc-sb ai-c">
                    <mat-form-field appearance="outline" class="f-1-1-100 input-suffix">
                      <input matInput type="number" formControlName="flightTimeHours" placeholder="23" min="0" max="23" oninput="javascript: if (this.value.length > 2) this.value = this.value.slice(1, 3);" required pattern="[012]?\d" autocomplete="off">
                      <span matSuffix class="grey">Hr</span>
                    </mat-form-field>
                    <div class="mx-5">:</div>
                    <mat-form-field appearance="outline" class="f-1-1-100 input-suffix">
                      <input matInput type="number" formControlName="flightTimeMinutes" placeholder="59" min="0" max="59" oninput="javascript: if (this.value.length > 2) this.value = this.value.slice(1, 3);" autocomplete="off">
                      <span matSuffix class="grey">Min</span>
                    </mat-form-field>
                  </div>
                  <div *ngIf="submitState" class="preview">{{item.value.flightTimeHours}}<span class="op-03">H</span> : {{item.value.flightTimeMinutes}}<span class="op-03">M</span></div>
                </div>
              </div>
            </div>
            <div class="leg__field">
              <img src="assets/img/arrow-down-right.svg" width="44" height="44" alt="">
              <div class="edit">
                <div class="form-row wp-60 pr-10 col">
                  <div class="label d-f jc-sb ai-c">Destination airport</div>
                  <mat-form-field *ngIf="!submitState" appearance="outline">
                    <input matInput type="text" placeholder="Select airport from {{legs[i].to?.city}}" aria-label="To Airport" [matAutocomplete]="toAirport" formControlName="to" (focus)="focusAirport($event)">
                    <mat-autocomplete #toAirport="matAutocomplete" [displayWith]="displayFn" (optionSelected)="timingsRecalculate($event.option.value, item, 'landing'); getFlightEstimateByLegs()">
                      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                      <ng-container *ngIf="!isLoading">
                        <mat-option *ngFor="let airport of filteredToAirports" [value]="airport">({{airport?.icao}}) {{airport?.name}} <mat-icon *ngIf="airport?.closed" inline="true" class="closed-airport">block</mat-icon>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                  <div *ngIf="item.errors?.matchAirports" class="error">
                    <mat-icon>info_outline</mat-icon>
                    <span class="ml-10">Departure airport can't be the same as arrival city</span>
                  </div>
                  <div *ngIf="submitState" class="preview">{{item.value.to?.name}} ({{item.value.to?.icao}})</div>
                </div>
                <div class="col wp-40 form-row time-picker">
                  <div class="label">Arrival time (LT)</div>
                  <mat-form-field *ngIf="!submitState" appearance="outline">
                    <input matInput libTimeMask type="text" formControlName="landingTime" (timeChange)="onManualLandingTimeChanged($event, item)">
                  </mat-form-field>
                  <div *ngIf="submitState" class="preview">{{item.value.landingTime}}</div>
                </div>
              </div>
            </div>
            <div class="leg__field">
              <img src="assets/img/users.svg" width="44" height="44" alt="">
              <div class="edit">
                <div class="passengers">
                  <div class="passengers__item">
                    <img src="assets/img/passengers/Adult.svg" width="26" height="26" alt="">
                    <span>{{legs[i]?.adultsNumber}}</span>
                  </div>
                  <div class="passengers__item">
                    <img src="assets/img/passengers/Children.svg" width="26" height="26" alt="">
                    <span>{{legs[i]?.childrenNumber}}</span>
                  </div>
                  <div class="passengers__item">
                    <img src="assets/img/passengers/Infant.svg" width="26" height="26" alt="">
                    <span>{{legs[i]?.infantsNumber}}</span>
                  </div>
                  <lib-popup-default *ngIf="legs[i]?.pets" head="Pets" [description]="legs[i]?.petsDescription || 'No pets description'" [hoverOpen]="true">
                    <div class="passengers__item jc-c nonum">
                      <img src="assets/img/pets.svg" width="26" height="26" alt="">
                    </div>
                  </lib-popup-default>
                  <lib-popup-default *ngIf="legs[i]?.cargo" head="Comments" [description]="legs[i]?.cargoDescription || 'No comments'" [hoverOpen]="true">
                    <div class="passengers__item jc-c nonum">
                      <img src="assets/img/comments.svg" width="26" height="26" alt="">
                    </div>
                  </lib-popup-default>
                  <!-- <div class="passengers__item jc-c">Edit</div> -->
                </div>
              </div>
            </div>
            <div class="leg__field">
              <img class="as-fs" src="assets/img/plane.svg" width="44" height="34" alt="">
              <div class="edit col">
                <div class="aircraft-select">
                  <mat-form-field *ngIf="!submitState" class="wp-100" appearance="outline">
                    <mat-label>Select aircraft</mat-label>
                    <mat-select formControlName="aircraftInfo" (selectionChange)="fillEmptyAircrafts($event)">
                      <mat-select-trigger *ngIf="item.value?.aircraftInfo">
                        <div class="d-f selected">
                          <span class="d-f" [class._oa]="item.value?.aircraftInfo.ownerApproval==='yes'">{{item.value?.aircraftInfo.tailNumber}} {{item.value?.aircraftInfo.typeName}}</span>
                          <span (click)="$event.stopPropagation(); openEditAircraft(item.value?.aircraftInfo.id)" class="edit-plane">
                            Edit Aircraft
                          </span>
                        </div>
                      </mat-select-trigger>
                      <mat-option *ngFor="let savedAircraft of aircraftsSaved" [value]="savedAircraft">
                        <span [class._oa]="savedAircraft.ownerApproval==='yes'">{{savedAircraft.tailNumber}} {{savedAircraft.typeName}}</span>
                      </mat-option>
                      <mat-option *ngIf="!aircraftsSaved.length" disabled>
                        You have no aircrafts, add an aircraft in My Fleet
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div *ngIf="(bidsForm.value.operator?.id || userService?.isOperator) && !submitState" class="add-plane">
                    <span (click)="openAddAircraft(i)">Add New</span>
                  </div>
                </div>

                <div *ngIf="item.value.aircraftInfo as aircraft" class="aircraft">
                  <div class="aircraft__logo">
                    <img [src]="aircraft.pictureExterior || 'assets/img/logo_dark.png'" alt="">
                  </div>
                  <div class="aircraft__details">
                    <div class="field">
                      <div class="d-f wp-50">
                        <img src="assets/img/aircraft/air-type.svg" width="24" height="22" alt="">
                        <div class="pl-10 d-f ai-c tt-c">{{aircraft.categoryName}} Jet</div>
                      </div>
                      <div class="row jc-sb wp-50">
                        <div>Manufactured in</div>
                        <div>{{aircraft.yom || 'Not added'}}</div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="d-f wp-50">
                        <img src="assets/img/aircraft/speed.svg" width="24" height="24" alt="">
                        <div class="pl-10 d-f ai-c">{{aircraft.maxSpeed || 'Not added'}}</div>
                      </div>
                      <div class="row jc-sb wp-50">
                        <div>Refurbished in</div>
                        <div>{{aircraft.yor || 'Not added'}}</div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="d-f wp-50">
                        <img src="assets/img/aircraft/max-pas.svg" width="17" height="22" alt="">
                        <div class="pl-10 d-f ai-c">{{aircraft.seats || 'Not added'}}</div>
                      </div>
                      <div class="row jc-sb wp-50">
                        <div>Reg.Country</div>
                        <div>{{aircraft.homebase?.countryName || 'Not added'}}</div>
                      </div>
                    </div>
                    <div class="d-f ai-c" style="filter: brightness(0);">

                      <lib-popup-default *ngIf="aircraft.flightAttendant === 'yes'" head="Flight attendant" description="A flight attendant is usually included on this aircraft." [hoverOpen]="true">
                        <img src="assets/img/client-app/aircraft-indicators/personal.svg" alt="opes-jet-photo">
                      </lib-popup-default>

                      <lib-popup-default *ngIf="aircraft.satphone === 'yes'" head="Satphone" description="A satellite phone is available at an extra charge." [hoverOpen]="true">
                        <img src="assets/img/client-app/aircraft-indicators/connection.svg" alt="opes-jet-photo">
                      </lib-popup-default>

                      <lib-popup-default *ngIf="aircraft.toilet === 'yes'" head="Lavatory" description="This aircraft is equipped with a lavatory." [hoverOpen]="true">
                        <img src="assets/img/client-app/aircraft-indicators/wc.svg" alt="opes-jet-photo">
                      </lib-popup-default>

                      <lib-popup-default *ngIf="aircraft.catering === 'yes'" head="Catering" description="Hot meals can be prepared on board." [hoverOpen]="true">
                        <img src="assets/img/client-app/aircraft-indicators/food.svg" alt="opes-jet-photo">
                      </lib-popup-default>

                      <lib-popup-default *ngIf="aircraft.smoking === 'yes'" head="Smoking" description="Smoking is allowed on board. Extra charges may apply." [hoverOpen]="true">
                        <img src="assets/img/client-app/aircraft-indicators/smoking.svg" alt="opes-jet-photo">
                      </lib-popup-default>

                      <lib-popup-default *ngIf="aircraft.smoking === 'no'" head="No smoking" description="Smoking on board is not allowed." [hoverOpen]="true">
                        <img src="assets/img/client-app/aircraft-indicators/nosmoking.svg" alt="opes-jet-photo">
                      </lib-popup-default>


                      <lib-popup-default *ngIf="aircraft.wifi === 'yes'" head="Wifi" description="Wi-Fi is available on board. Please confirm conditions." [hoverOpen]="true">
                        <img src="assets/img/client-app/aircraft-indicators/wifi.svg" alt="opes-jet-photo">
                      </lib-popup-default>

                      <lib-popup-default *ngIf="aircraft.pets === 'yes'" head="Pets" description="Certain pets are welcome in the cabin." [hoverOpen]="true">
                        <img src="assets/img/client-app/aircraft-indicators/pets.svg" alt="opes-jet-photo">
                      </lib-popup-default>

                      <lib-popup-default *ngIf="aircraft.entertainment === 'yes'" head="Entertainment" description="This aircraft is equipped with entertainment options." [hoverOpen]="true">
                        <img src="assets/img/client-app/aircraft-indicators/entertainment.svg" alt="opes-jet-photo">
                      </lib-popup-default>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="leg__field">
              <div matRipple (click)="addCurrencyConversion(i);" class="description-icon">
                <img class="pointer" src="assets/img/comments.svg" width="44" height="44" alt="">
              </div>

              <div class="edit">
                <div class="form-row wp-100 col">
                  <div class="label d-f jc-sb ai-c">Comment</div>
                  <ng-container *ngIf="!submitState">
                    <mat-form-field class="f-1-1-100" appearance="outline">
                      <textarea (keydown)="setAdditionalInfoTemplate($event, i)" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10" formControlName="description" placeholder="Enter an info..." class="edit__info"></textarea>
                    </mat-form-field>
                    <div class="op-06 fs-14">Please do not disclose your company name and/or contact information until your offer has been accepted by our member. Thank you.</div>
                  </ng-container>
                  <div *ngIf="submitState" class="preview">{{item.value.description || 'Not added'}}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="footer">
        <div class="footer__warn" *ngIf="submitState">Please check your offer before {{isEditing ? 'updating' : 'sending'}} it</div>
        <div class="footer__warn" *ngIf="isDraftOffer">Please validate  offer before sending it</div>
        <div formArrayName="bidsList" class="footer__form">
          <ng-container *ngFor="let item of bidsList.controls; let i = index">
            <div *ngIf="!i" [formGroupName]="i" class="d-f jc-sb ai-c hp-100 wp-100">
              <div class="d-f ai-c fg-1 mr-10">
                <img src="assets/img/currency/{{item.value?.priceCurCode}}.svg" width="44" height="44" alt="">
                <div class="price">
                  <span class="label mr-10">Total price</span>
                  <mat-form-field class="price__input" *ngIf="!submitState" appearance="outline">
                    <input matInput type="number" step="1" min="0" formControlName="price" placeholder="Enter the price" required>
                    <span matSuffix class="op-05 fw-b">&nbsp;{{ item.value?.priceCurCode }}</span>
                    <mat-error class="fs-10">
                      The sum should not contain cents/commas/dots. Please round the sum to a whole number.
                    </mat-error>
                  </mat-form-field>
                  <span *ngIf="submitState">{{ item.value?.price }} <span class="op-05">{{ item.value?.priceCurCode }}</span></span>
                </div>
              </div>
              <div class="d-f ai-c">
                <div *ngIf="isDraftOffer else standart">
                  <button type="submit" [disabled]="requesting || !isAuthorized" mat-raised-button color="accent">
                    Approve and send Offer
                  </button>
                  <br>
                  <button type="button" [disabled]="requesting || !isAuthorized" mat-stroked-button color="accent" (click)="cancelOffer()">Reject and delete</button>
                </div>
                <ng-template #standart>
                  <button *ngIf="!isEditing" [disabled]="requesting || !isAuthorized" mat-raised-button color="accent">{{ submitState ? 'Confirm and send' : 'Make Offer' }}</button>
                  <button *ngIf="isEditing" [disabled]="requesting || !isAuthorized" mat-raised-button color="accent">{{ submitState ? 'Confirm and update' : (bookingId ? 'Update Confirmed Flight' : 'Update Offer') }}</button>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div *ngIf="successState" class="success-container">
      <div class="success-container__title">Thank you!</div>
      <div class="success-container__text1">
        <span *ngIf="!isEditing">The offer has been sent to the client.</span>
        <span *ngIf="isEditing && !bookingId">The offer has been changed.</span>
        <span *ngIf="isEditing && bookingId">The confirmed flight has been changed.</span>
        <br>When the client selects your offer, you will receive a confirmation email from OPES JET.
      </div>
      <div class="success-container__text2">Did you know that you can amend any of your offers at any time?</div>
      <a [routerLink]="userService?.isOperator ? '/offers' : '/requests/progress'" [queryParams]="userService?.isOperator ? {} : { request: frpId }">
        <button class="success-container__btn1" mat-stroked-button>CHECK {{userService?.isOperator ? 'MY OFFERS' : 'OPERATED REQUESTS'}}</button>
      </a>
      <div class="success-container__text2">There are many other requests on OPES JET platform.</div>
      <a [routerLink]="userService?.isOperator ? '/frps' : '/requests'">
        <button class="success-container__btn2" mat-stroked-button>SEE OTHER CLIENTS REQUESTS</button>
      </a>
    </div>
  </form>
</ng-template>

<ng-template #comtetingOffersSide>
  <div class="right">
    <div class="right-offers">
      <div class="offers-card" *ngFor="let offer of offers" [class.cancelled]="offer.bids[0].status === 'canceled'">
        <div class="l1">
          <div class="card-column center">
            <div class="card-column__value title">{{offer.bids[0]?.operator?.name}}</div>
          </div>
          <div class="card-column">
            <span class="card-column__title">{{offer.bids[0] | airportDateTime : 'from' : 'dd.MM.yyyy ' + timeService.timeFormat}}</span>
            <span class="op-05 fw-12">Created: {{offer.bids[0].created | date : 'dd.MM.yyyy ' + timeService.timeFormat}}</span>
            <span class="card-column__value">{{offer.bids[0]?.from?.icao}}-{{offer.bids[0]?.to?.icao}}</span>
          </div>
          <div class="card-column" *ngIf="(isMobile$ | async) === false">
            <span class="card-column__title">{{offer.bids[0]?.aircraftInfo?.categoryName}} Jet</span>
            <span class="card-column__value">{{offer.bids[0]?.aircraftInfo?.typeName}} <span class="pass">paх</span> {{offer.bids[0]?.aircraftInfo?.seats}}</span>
            <span class="card-column__value">{{offer.bids[0]?.aircraftInfo?.tailNumber}} </span>
          </div>
          <div class="card-column" *ngIf="(isMobile$ | async) === false">
            <span class="card-column__title">Man / Ref</span>
            <span class="card-column__value">{{offer.bids[0]?.aircraftInfo?.yom || '----'}} / {{offer.bids[0]?.aircraftInfo?.yor || '----'}}</span>
          </div>
          <div class="card-column-5">
            <div class="card-column__value">
              <img src="assets/img/aircraft/air-type.svg" width="24" height="22" alt="" class="plane-icon">
            </div>
          </div>
          <div class="card-column">
            <span class="card-column__title">Flight Time</span>
            <span class="card-column__value">{{offer.bids[0]?.flightTime}}</span>
          </div>
          <div class="card-column">
            <span class="card-column__title">{{offer.bids[0]?.priceCurCode}} <span class="price">{{offer.bids[0]?.price | number}}</span></span>
            <div class="card-column__value">
              <span class="offer-id pointer" (click)="$event.stopPropagation()" matTooltip="Click to copy" [cdkCopyToClipboard]="offer.bids[0].offer.slice(0, 6)"># {{offer.bids[0].offer.slice(0, 6)}}</span>
              <div class="type" *ngIf="(isMobile$ | async) === false"><span class="type-icon" *ngIf="offer.bids[0]?.aircraftInfo?.ownerApproval==='yes'">OA</span></div>
            </div>
          </div>
        </div>
        <div class="l2" *ngIf="(isMobile$ | async)">
          <div class="card-column">
            <span class="card-column__title">{{offer.bids[0]?.aircraftInfo?.categoryName}} Jet</span>
            <span class="card-column__value">{{offer.bids[0]?.aircraftInfo?.typeName}} <span class="pass">paх</span> {{offer.bids[0]?.aircraftInfo?.seats}}</span>
            <span class="card-column__value">{{offer.bids[0]?.aircraftInfo?.tailNumber}} </span>
          </div>
          <div class="card-column center">
            <span class="card-column__title">Man / Ref</span>
            <span class="card-column__value">{{offer.bids[0]?.aircraftInfo?.yom || '----'}} / {{offer.bids[0]?.aircraftInfo?.yor || '----'}}</span>
          </div>
          <div class="card-column center">
            <div class="type">
              <span class="type-icon" *ngIf="offer.bids[0]?.aircraftInfo?.ownerApproval==='yes'">OA</span>
              <span class="type-icon" *ngIf="offer.bids[0]?.emptyLeg">EL</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
