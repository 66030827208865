import { Component, Input } from '@angular/core';
import { Client } from '../../../interfaces/Client';

@Component({
  selector: 'lib-billing-info',
  templateUrl: './billing-info.component.html'
})
export class BillingInfoComponent {
  @Input() client?: Client;
  @Input() expanded!: boolean;
}
