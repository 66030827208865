import { Inject, Component } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'lib-event',
  templateUrl: './event.component.html',
  styleUrls: [ './event.component.scss' ]
})
export class EventComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { text: string; action: () => void },
    public snackBarRef: MatSnackBarRef<EventComponent>,
  ) { }

  dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
