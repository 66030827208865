import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor( private fbRTB: AngularFireDatabase ) { }

  checkStatus(): Observable<{ authorId: string; text: string, timestamp: number, type: string, updated: Date } | null> {
    return this.fbRTB.object<{ authorId: string; text: string, timestamp: number, type: string, updated: Date }>('operators/public/').valueChanges();
  }

  checkFeaturedFlights(userId: string): Observable<{ authorId: string, created: string, updated: string } | null> {
    return this.fbRTB.object<{ authorId: string, created: string, updated: string } >(`updates/users/${userId}/featuredFlightsList`).valueChanges();
  }

  checkFRPs(userId: string): Observable<{ authorId: string, created: string, updated: string } | null> {
    return this.fbRTB.object<{ authorId: string, created: string, updated: string } >(`updates/users/${userId}/flightRequestsList`).valueChanges();
  }

  checkBookings(userId: string): Observable<{ authorId: string, created: string, updated: string } | null> {
    return this.fbRTB.object<{ authorId: string, created: string, updated: string } >(`updates/users/${userId}/bookingsList`).valueChanges();
  }

  checkAdminBookings(): Observable<{ authorId: string, created: string, updated: string } | null> {
    return this.fbRTB.object<{ authorId: string, created: string, updated: string } >(`updates/admins/bookingsList`).valueChanges();
  }

  checkAdminCallRequests(): Observable<{ authorId: string, created: string, updated: string } | null> {
    return this.fbRTB.object<{ authorId: string, created: string, updated: string } >(`updates/admins/callRequestsList`).valueChanges();
  }

  checkBookingConfirmedPayment(bookingId: string): Observable<{ authorId: string, created: string, updated: string } | null> {
    return this.fbRTB.object<{ authorId: string, created: string, updated: string } >(`updates/bookings/${ bookingId }/confirmed-payment`).valueChanges();
  }

  checkBookingSignedAgreement(bookingId: string): Observable<{ authorId: string, created: string, updated: string } | null> {
    return this.fbRTB.object<{ authorId: string, created: string, updated: string } >(`updates/bookings/${ bookingId }/signed-agreement`).valueChanges();
  }

  checkBookingAdditionalDocuments(bookingId: string): Observable<{ authorId: string, created: string, updated: string } | null> {
    return this.fbRTB.object<{ authorId: string, created: string, updated: string } >(`updates/bookings/${ bookingId }/additional-documents`).valueChanges();
  }
}
