import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'lib-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {

  ngOnInit(): void {
    document.documentElement.setAttribute('style', 'overflow:hidden');
  }

  ngOnDestroy(): void {
    document.documentElement.removeAttribute('style');
  }

}
