import { DateTime } from 'luxon';
import { Passenger } from '../interfaces/Passenger';
import { Airport } from '../interfaces/Airport';


export class LegToSend {
  id?: string;
  fromId: string;
  from?: Airport;
  fromCityOnly = false;
  toId: string;
  to?: Airport;
  toCityOnly = false;
  budget = 0;
  estimatedBudget = 0;
  budgetCurCode = 'USD';
  adultsNumber = 1;
  childrenNumber = 0;
  infantsNumber = 0;
  pets = false;
  petsDescription: string;
  cargo = false;
  cargoDescription: string;
  flightDate: string;
  flightTimeMatter: boolean;
  clientId?: string;
  passengers?: string[];
  passengerIds?: string[];
  expirationDate!: string;
  decisionDate!: string;

  constructor(legFrom: Record<any, any>, passengers?: { [x: string]: Passenger[]; }) {
    this.id = legFrom.id;
    this.fromId = legFrom.from?.id;
    this.toId = legFrom.to?.id;
    this.fromCityOnly = legFrom.fromCityOnly;
    this.toCityOnly = legFrom.toCityOnly;
    this.budget = legFrom.budget;
    this.estimatedBudget = legFrom.estimatedBudget;
    this.budgetCurCode = legFrom.budgetCurCode;
    this.adultsNumber = legFrom.adultsNumber;
    this.childrenNumber = legFrom.childrenNumber;
    this.infantsNumber = legFrom.infantsNumber;
    this.pets = legFrom.pets;
    this.petsDescription = legFrom.petsDescription;
    this.cargo = legFrom.cargo;
    this.cargoDescription = legFrom.cargoDescription;
    if(typeof legFrom.flightDate === 'string') {
      this.flightDate = DateTime.fromISO(legFrom.flightDate).toISODate();
    } else {
      this.flightDate = DateTime.fromJSDate(legFrom.flightDate).toISODate();
    }
    if (legFrom.takeoffTime && legFrom.flightTimeMatter) this.flightDate += ' ' + legFrom.takeoffTime;
    this.flightTimeMatter = legFrom.flightTimeMatter;
    this.clientId = legFrom.clientId;
    if (!this.clientId) delete this.clientId;
    if (!this.id) delete this.id;
    this.passengers = Object.values(passengers || []).flat(1).map(p => p?.id).filter(p => !!p);
    this.passengerIds = Object.values(passengers || []).flat(1).map(p => p?.id).filter(p => !!p);
  }
}
