import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { Maintenance } from '../../interfaces/Maintenance';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  private maintenancesUrl!: string;

  constructor(
    private http: HttpClient,
    private fireRTB: AngularFireDatabase,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
  ) {
    this.maintenancesUrl = `${this.globalSharedService.config.baseUrl}/maintenances`;
  }

  watchMaintenance(): Observable<Maintenance> {
    return this.fireRTB.object(`updates/maintenance`).valueChanges() as Observable<Maintenance>;
  }

  getMaintenances(): Observable<Maintenance[]> {
    return this.http.get<Maintenance[]>(this.maintenancesUrl)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  startMaintenance(maintenance: Maintenance): Observable<Maintenance> {
    return this.http.post<Maintenance>(`${this.maintenancesUrl}/start`, maintenance)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  stopMaintenance(description: string): Observable<Maintenance> {
    return this.http.post<Maintenance>(`${this.maintenancesUrl}/stop`, { description })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
