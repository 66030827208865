import { AircraftSaved } from '../interfaces/AircraftSaved';


export class BookingToUpdate {
  id? = '';
  refNumber? = '';
  offer? = '';
  status? = '';
  price? = 0;
  emptyLeg? = false;
  priceCurCode? = '';
  aircraftInfo?: AircraftSaved | undefined = undefined; // TODO AircraftSaved id ??
  fromId? = ''; // Airport id
  toId? = ''; // Airport id
  takeoffDate? = '';
  landingDate? = '';
  takeoffOffset? = '';
  landingOffset? = '';
  description? = '';
  flightTime? = '';

  constructor(bookingLegFromForm: Record<any, any>) {
    this.id = bookingLegFromForm.id;
    this.refNumber = bookingLegFromForm.refNumber;
    this.offer = bookingLegFromForm.offer;
    this.status = bookingLegFromForm.status;
    this.price = +bookingLegFromForm.price;
    this.emptyLeg = bookingLegFromForm.emptyLeg;
    this.priceCurCode = bookingLegFromForm.priceCurCode;
    this.aircraftInfo = bookingLegFromForm.aircraftInfo;
    this.description = bookingLegFromForm.description;
    this.fromId = bookingLegFromForm.from?.id;
    this.toId = bookingLegFromForm.to?.id;
    this.takeoffDate = bookingLegFromForm.takeoffDate;
    this.landingDate = bookingLegFromForm.landingDate;
    this.takeoffOffset = bookingLegFromForm.takeoffOffset;
    this.landingOffset = bookingLegFromForm.landingOffset;
    this.flightTime = `${bookingLegFromForm.flightTimeHours}:${bookingLegFromForm.flightTimeMinutes}`;
  }
}
