import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { ErrorService } from '../helpers/error.service';
import { GlobalSharedService } from '../../global-shared.service';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { WingXOperator } from '../../interfaces/WingXOperator';

@Injectable({
  providedIn: 'root'
})
export class WingXService {
  wingXServiceUrl = '';

  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
  ) {
    this.wingXServiceUrl = `${ this.globalSharedService.config.baseUrl }/wingx-service`;
  }

  // Get operators suggestion from Wingx service
  getOperatorsByParams(query: { departureICAO: string, arrivalICAO: string; }): Observable<{ operators: WingXOperator[], request: { arrival_icao: string, departure_icao: string; }; }> {
    return this.http.get<{ operators: WingXOperator[], request: { arrival_icao: string, departure_icao: string; }; }>(`${ this.wingXServiceUrl }/suggestion`, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  // Operator from Wingx service by id
  getOperatorById(id: string): Observable<WingXOperator> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<WingXOperator>(`${ this.wingXServiceUrl }/operators`, { params: { id: id } })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  // Operators list from Wingx service
  getOperators(): Observable<WingXOperator[]> {
    return this.http.get<WingXOperator[]>(`${ this.wingXServiceUrl }/operators`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  // Update email for operator in Wingx service
  updateOperatorEmail(query: { id: string, email: string, ignore: boolean; }): Observable<unknown> {
    return this.http.patch<unknown>(`${ this.wingXServiceUrl }/operators`, query)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
