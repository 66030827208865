import { Inject, Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-action-alert',
  templateUrl: './action-alert.component.html',
  styleUrls: [ './action-alert.component.scss' ]
})
export class ActionAlertComponent {

  alertForm = this.fb.group({
    shortComment: [''],
    longComment: ['']
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      text: string,
      input: boolean,
      textarea: boolean,
      placeholder: string,
      defaultComment: string,
      alert: boolean,
      cancel: () => void,
      confirm: (x: { [x: string]: string }) => void
    },
    public dialogRef: MatDialogRef<ActionAlertComponent>,
    private fb: UntypedFormBuilder,
  ) {
    this.dialogRef.backdropClick().subscribe(_ => {
      if (this.data.cancel) this.data.cancel();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape' && this.data.cancel) this.data.cancel();
    });
    this.alertForm.setValue({ shortComment: '', longComment: this.data.defaultComment || '' })
  }

  confirmModal(): void {
    if (typeof this.data.confirm === 'function') this.data.confirm(this.alertForm.value);
    this.dialogRef.close({ data: true });
  }

  cancel(): void {
    if (this.data.cancel) this.data.cancel();
  }
}
