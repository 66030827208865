import { AfterViewInit, Inject, Component, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Bid } from '../../../interfaces/Bid';
import { UserService } from '../../../services/api/user.service';

@Component({
  selector: 'lib-other-offers',
  templateUrl: './other-offers.component.html',
  styleUrls: [ './other-offers.component.scss' ]
})
export class OtherOffersComponent implements AfterViewInit {

  dataSource: MatTableDataSource<Bid>;

  @ViewChild(MatSort) sort!: MatSort;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Array<{ offer: string; bids: Bid[] }>, public userService: UserService,
  ) {
    const bids = data.map(offer => {
      const bid = Object.assign({}, offer.bids[0]);
      bid.price = offer.bids.map(b => b.price).reduce((a, b) => a + b, 0);
      bid.aircrafts = offer.bids.map(b => b.aircraftInfo);
      return bid;
    });
    this.dataSource = new MatTableDataSource(bids);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = ((item, sortHeaderId: string) => {
      if (sortHeaderId === 'name') return item.aircraftInfo.operator[ sortHeaderId ];
      else if (sortHeaderId === 'typeName') return item.aircraftInfo[ sortHeaderId ];
      else return item.price;
    });
  }
  get operatorId(): string {
    return this.userService.userInfo$.getValue().operatorAccountOwner?.id || this.userService.userInfo$.getValue().operatorAccountAdmin?.id;
  }
}
