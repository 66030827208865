import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'centsToDollars'
})
export class CentsToDollarsPipe implements PipeTransform {
  transform(amount?: number): number {
    return (!!amount && typeof amount === 'number') ? Number((amount / 100).toFixed(2)): 0;
  }
}
