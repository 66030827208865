<div class="login">
  <div class="login-header">
    <img src="assets/img/login-logo.svg" alt="">
  </div>
  <div class="login-label">
    <ng-container *ngIf="step">
      <mat-icon class="pointer" (click)="back()">arrow_back_ios</mat-icon>
      <span class="login-label__text">{{step === 1 ? 'Sign in' : 'Forgot your password?'}}</span>
    </ng-container>
    <ng-container *ngIf="step === 0">
      <span class="login-label__text">Sign in Required</span>
    </ng-container>
  </div>
  <div *ngIf="step; else loginStart">
    <div *ngIf="step === 1; else resetPasswordForm">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <mat-form-field hideRequiredMarker="true">
          <mat-label>Company email</mat-label>
          <input type="email" matInput formControlName="email" autocomplete="off">
        </mat-form-field>
        <mat-form-field hideRequiredMarker="true">
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="password" autocomplete="off">
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!loginForm.value.email || !loginForm.value.password">
          SIGN IN
        </button>
        <div class="login-reset" *ngIf="isOperator">
          <a rel="noopener noreferrer" class="login-reset__label" (click)="openNextStep();">
            Forgot your password?
          </a>
        </div>
      </form>
    </div>
    <ng-template #resetPasswordForm>
      <form [formGroup]="resetForm" (ngSubmit)="resetPassword()">
        <mat-form-field hideRequiredMarker="true">
          <mat-label>Company email address</mat-label>
          <input type="email" matInput formControlName="email" autocomplete="off">
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!resetForm.value.email">
          SIGN REQUEST LINK
        </button>
      </form>
    </ng-template>
  </div>
  <ng-template #loginStart>
    <div class="login-content">
      <span class="login-content__text">You must be a user to enjoy all the benefits</span>
      <button mat-raised-button color="primary" (click)="openNextStep()" class="login-content__button">SIGN IN</button>
      <div *ngIf="isOperator" class="content-sign">
        <span class="content-sign__text">Don't have a <span class="upper">free</span> account yet?</span>
        <a routerLink="/register" [queryParams]="currentPage" rel="noopener noreferrer" class="content-sign__button">
          Create your account
        </a>
      </div>
    </div>
  </ng-template>
</div>
