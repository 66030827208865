<mat-expansion-panel class="widget mat-elevation-z request-menu" hideToggle>
  <mat-expansion-panel-header class="widget__header">
    <mat-panel-title>
      <div class="expand-icon" [class._active]="false">
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
        </svg>
      </div>
      <div class="title">OPES Token</div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="description">
        <div class="description__balance">
          <img class="mr-5" src="assets/img/opes-coin.svg" alt="">
          <span>{{balance | centsToDollars }}</span>
        </div>
      </div>
      <div class="menu">
        <button type="button" mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();" class="w-32 h-32">
          <img src="assets/img/more_vert.svg" alt="">
        </button>
        <mat-menu #menu="matMenu" yPosition="below">
          <button type="button" mat-menu-item (click)="editBalance()">
            <span>Edit Balance</span>
          </button>
        </mat-menu>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="widget__body">
    <ng-container *ngIf="transactions?.length; else noTransactions">
      <table class="wp-100 ws-nw" mat-table [dataSource]="transactions">
        <ng-container matColumnDef="name">
          <td class="px-10" mat-cell *matCellDef="let el">
            <ng-container *ngIf="el.isAutoCreated && el.description; else manual">
              <a class="link td-u" routerLink="/search" [queryParams]="{ id: el.itemId }">{{el.description}}</a>
            </ng-container>
            <ng-template #manual>
              <span *ngIf="el.author.firstName && el.author.lastName; else email">{{ el.author.firstName }} {{ el.author.lastName }}</span>
              <ng-template #email>{{ el.author.email }}</ng-template>
              <span *ngIf="el.description" class="op-05" style="white-space: normal; line-break: anywhere;"> ({{el.description}})</span>
              <span *ngIf="el.hiddenComment" class="op-05" style="white-space: normal; line-break: anywhere;"> ({{el.hiddenComment}})</span>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <td class="px-10" class="op-06" mat-cell *matCellDef="let el">{{ el.created | date: 'dd.MM (HH:mm)' }}</td>
        </ng-container>
        <ng-container matColumnDef="balance">
          <td class="px-10" style="color: #B4A27F" mat-cell *matCellDef="let el"><span *ngIf="el.amount > 0">+</span>{{ el.amount | centsToDollars }}</td>
        </ng-container>
        <tr style="height: 38px;" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </ng-container>
    <ng-template #noTransactions>
      <div class="d-f jc-c ai-c fs-16 op-06 py-10">
        <mat-icon class="mr-10">history</mat-icon>
        <span>No transactions history</span>
      </div>
    </ng-template>
  </div>
</mat-expansion-panel>
