import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Gallery, ImageItem, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { Observable, of } from 'rxjs';
import { debounceTime, first, skip, switchMap, tap } from 'rxjs/operators';
import { AircraftCategory } from '../../../interfaces/AircraftCategory';
import { AircraftSaved } from '../../../interfaces/AircraftSaved';
import { Airport } from '../../../interfaces/Airport';
import { AircraftsCategoriesService } from '../../../services/api/aircrafts-categories.service';
import { AircraftsSavedService } from '../../../services/api/aircrafts-saved.service';
import { AirportsService } from '../../../services/api/airports.service';
import { FileService } from '../../../services/helpers/file.service';
import { SnackService } from '../../../services/helpers/snack.service';
import { ActionAlertComponent } from '../action-alert/action-alert.component';

@Component({
  selector: 'lib-aircraft-saved-details',
  templateUrl: './aircraft-saved-details.component.html',
  styleUrls: [ './aircraft-saved-details.component.scss' ]
})
export class AircraftSavedDetailsComponent implements OnInit {
  aircraftSaved$: Observable<AircraftSaved> | undefined;
  aircraftCategories: Array<AircraftCategory> | undefined;
  aircraftCategoriesFiltered: Array<AircraftCategory> | undefined;
  selectedAircraft: AircraftCategory | undefined;
  currentAircraftSaved: AircraftSaved | undefined;
  photos: { pictureExterior?: string; pictureInterior?: string; pictureFloorplan?: string; } = {
    pictureExterior: undefined,
    pictureInterior: undefined,
    pictureFloorplan: undefined
  };
  curCategory = '';
  curYear = new Date().getFullYear().toString();
  aircraftSavedForm = this.fb.group({
    id: [ '' ],
    tailNumber: [ '', [ Validators.required, this.validatorTrim ] ],
    categoryName: [ '', Validators.required ],
    typeName: [ '', Validators.required ], // aircraft type (model)
    yom: [ '', [ Validators.min(0), Validators.max(new Date().getFullYear()) ] ], // year of manufacturing
    yor: [ '', [ Validators.min(0), Validators.max(new Date().getFullYear()) ] ], //  year of last refurbishment
    maxSpeed: [ '', [ Validators.min(0), Validators.max(5000) ] ], //  year of last refurbishment
    seats: [ '', [ Validators.required, Validators.min(0) ] ], // total number of seats
    homebaseId: [ '' ],
    catering: [ '' ],
    flightAttendant: [ '' ],
    entertainment: [ '' ],
    // luggageCapacity: [ '' ],
    ownerApproval: [ '' ],
    satphone: [ '' ],
    smoking: [ '' ],
    pets: [ '' ],
    toilet: [ '' ], // belted toilet
    wifi: [ '' ],
    pictureExterior: [ '' ],
    pictureInterior: [ '' ],
    pictureFloorplan: [ '' ],
  });
  requesting = false;
  items?: ImageItem[];

  myControl = new UntypedFormControl([ undefined, Validators.required ]);
  airportControl = new UntypedFormControl([ undefined ]);
  airportsList: Airport[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number, operatorId: string },
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AircraftSavedDetailsComponent>,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private aircraftsSavedService: AircraftsSavedService,
    private aircraftsCategoriesService: AircraftsCategoriesService,
    private fileService: FileService,
    private snackSrv: SnackService,
    public lightbox: Lightbox,
    public gallery: Gallery,
    public airportsSrv: AirportsService,
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') this.close();
    });
  }
  ngOnInit(): void {
    this.myControl.valueChanges
      .pipe(skip(1))
      .subscribe(value => {
        this.aircraftCategoriesFiltered = this._filter(value);
        this.aircraftSavedForm.patchValue({
          categoryName: undefined,
          typeName: undefined,
        });
      });
    this.airportControl.valueChanges
      .pipe(
        skip(1),
        debounceTime(500)
      ).subscribe(value => {
        if (value?.length >= 3) this.getAirports(value);
      });
    this.aircraftsCategoriesService.getAircraftsCategories()
      .pipe(first())
      .subscribe(categories => {
        this.aircraftCategories = categories;
        this.aircraftCategoriesFiltered = categories;
        this.selectedAircraft = this.aircraftCategories?.find(c => c.typeName === this.currentAircraftSaved?.typeName);
        if (this.selectedAircraft) this.myControl.setValue(this.selectedAircraft?.typeName);
      });
    this.route.paramMap.pipe(
      switchMap(() => {
        if (this.data?.id) {
          return this.aircraftsSavedService.getAircraftSavedById(this.data?.id);
        } else {
          return of({} as AircraftSaved);
          /* const localAircraft = localStorage.getItem('aircraftDraft');
          if (localAircraft) return of(JSON.parse(localAircraft) as AircraftSaved);
          else */
        }
      }),
      first(),
      tap(aircraftSaved => {
        this.currentAircraftSaved = aircraftSaved;
        this.curCategory = aircraftSaved.categoryName;
        this.aircraftSavedForm.patchValue({
          id: aircraftSaved.id,
          tailNumber: aircraftSaved.tailNumber,
          categoryName: aircraftSaved.categoryName,
          typeName: aircraftSaved.typeName, // aircraft type (model)
          // rangeFour: aircraftSaved.rangeFour, // range with 4 passengers
          // rangeFull: aircraftSaved.rangeFull, // range with full seats passengers
          yom: aircraftSaved.yom, // year of manufacturing
          yor: aircraftSaved.yor, //  year of last refurbishment
          maxSpeed: aircraftSaved.maxSpeed, //  year of last refurbishment
          homebaseId: aircraftSaved.homebaseId,
          catering: aircraftSaved.catering,
          flightAttendant: aircraftSaved.flightAttendant,
          entertainment: aircraftSaved.entertainment,
          // luggageCapacity: aircraftSaved.luggageCapacity,
          ownerApproval: aircraftSaved.ownerApproval,
          satphone: aircraftSaved.satphone,
          seats: aircraftSaved.seats, // total number of seats
          smoking: aircraftSaved.smoking,
          pets: aircraftSaved.pets,
          toilet: aircraftSaved.toilet, // belted toilet
          wifi: aircraftSaved.wifi,
        });
        if (aircraftSaved.id) {
          this.photos.pictureExterior = aircraftSaved.pictureExterior;
          this.photos.pictureInterior = aircraftSaved.pictureInterior;
          this.photos.pictureFloorplan = aircraftSaved.pictureFloorplan;
          this.aircraftSavedForm.patchValue(this.photos);
          this.items = Object.values(this.photos)
            .filter(val => !!val)
            .map(val => new ImageItem({ src: val, thumb: val }));
          this.setLightbox();
        }
      }
      )).subscribe(() => {
        if (this.currentAircraftSaved?.homebase) this.airportControl.setValue(this.currentAircraftSaved?.homebase);
        this.selectedAircraft = this.aircraftCategories?.find(c => c.typeName === this.currentAircraftSaved?.typeName);
        if (this.selectedAircraft) this.myControl.setValue(this.selectedAircraft?.typeName);
        // Trigger validate the whole form
        if (this.aircraftSavedForm.value.id) {
          Object.keys(this.aircraftSavedForm.controls).forEach(field => {
            const control = this.aircraftSavedForm.get(field);
            control?.markAsTouched({ onlySelf: true });
          });
        }
      });
  }
  displayFn(aircraft: AircraftCategory): string {
    return aircraft && aircraft.typeName ? aircraft.typeName : '';
  }
  displayAirportFn(airport: Airport): string {
    return airport && airport.icao ? airport.icao : '';
  }
  private _filter(value: any ): AircraftCategory[] | undefined {
    const filterValue = value.typeName || value.toLowerCase();
    if (filterValue) {
      return this.aircraftCategories?.filter(option => option.typeName.toLowerCase().includes(filterValue));
    } else {
      return this.aircraftCategories;
    }
  }
  validatorTrim(formControl: AbstractControl): ValidationErrors | null {
    const isWhitespace = (formControl.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  onSubmit(): void {
    this.requesting = true;
    this.aircraftSavedForm.patchValue({ tailNumber: this.aircraftSavedForm.value.tailNumber.toUpperCase() });
    // Trigger validate the whole form before submit
    Object.keys(this.aircraftSavedForm.controls).forEach(field => {
      const control = this.aircraftSavedForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
    const obj = { ...this.aircraftSavedForm.value };
    if (this.data.operatorId) obj.operatorId = this.data.operatorId;
    if (!obj.yom) obj.yom = 0;
    if (!obj.yor) obj.yor = 0;
    if (!obj.maxSpeed) obj.maxSpeed = 0;
    if (!obj.homebaseId) delete obj.homebaseId;
    if (this.aircraftSavedForm.value.id) {
      this.aircraftsSavedService.updateAircraftSaved(obj)
        .pipe(first())
        .subscribe(aircraftSaved => {
          this.dialogRef.close(aircraftSaved.id);
          // this.router.navigate([ '/aircrafts-saved' ]);
        });
    } else {
      if (this.currentAircraftSaved) {
        Object.entries(this.currentAircraftSaved).forEach(val => {
          if (val[1] === this.aircraftSavedForm.value[ val[0] ]) {
            delete this.aircraftSavedForm.value[ val[0] ];
          }
        });
      }
      delete this.aircraftSavedForm.value.id;
      this.aircraftsSavedService.createAircraftSaved(obj)
        .pipe(first())
        .subscribe(aircraftSaved => {
          this.requesting = false;
          this.dialogRef.close(aircraftSaved.id);
          // localStorage.removeItem('aircraftDraft');
          this.snackSrv.systemError(`Aircraft ${ this.aircraftSavedForm.value.typeName } has been successfully added to My Fleet.`);
        },
          error => {
            let errorMessage = error.error.message;
            if (error.error.messages) {
              error.error.messages.forEach((message: string) => {
                errorMessage += `\n${ message }`;
              });
            }
            this.requesting = false;
            alert(errorMessage);
          });
    }
  }

  close(): void {
    if (this.aircraftSavedForm.dirty) {
      this.dialog.open(ActionAlertComponent, {
        width: '486px',
        data: {
          title: 'Cancel changes',
          text: `Are you sure you want to cancel changes?\nIf you leave right now, they won't be saved.`,
          confirm: () => {
            this.dialogRef.close();
          }
        },
        panelClass: 'no-padding-dialog',
        autoFocus: false
      });
    }
    else this.dialogRef.close();
  }

  getAirports(searchWord: string): void {
    this.airportsSrv.searchAirports(searchWord)
      .pipe(first())
      .subscribe(resp => {
        this.airportsList = resp.airports;
      });
  }

  onAirportSelect(event: MatAutocompleteSelectedEvent): void {
    console.log(event);
    this.aircraftSavedForm.patchValue({
      homebaseId: event.option.value.id
    });
  }

  onAircraftSelect(event: MatAutocompleteSelectedEvent): void {
    this.aircraftSavedForm.patchValue({
      categoryName: event.option.value.categoryName,
      typeName: event.option.value.typeName,
    });
  }

  setLightbox(): void {
    const lightboxRef = this.gallery.ref('lightbox');
    lightboxRef.setConfig({ imageSize: ImageSize.Contain });
    lightboxRef.load(this.items || []);
  }

  onFileSelect(event: Event, fileType: string): void {
    this.fileService.onFileSelect(event, [ 'image/jpg', 'image/jpeg', 'image/png' ], file => {
      const img = new FileReader();
      img.onload = () => {
        (this.photos as any)[ fileType ] = img.result;
        this.items = Object.values(this.photos)
          .filter(val => !!val)
          .map(val => new ImageItem({ src: val, thumb: val }));
        this.setLightbox();
      };
      img.readAsDataURL(file);
      this.aircraftSavedForm.patchValue({ [ fileType ]: file });
    });
  }
}
