<mat-expansion-panel class="widget mat-elevation-z request-menu" hideToggle expanded>
  <mat-expansion-panel-header class="widget__header">
    <mat-panel-title>
      <div class="expand-icon" [class._active]="false">
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
        </svg>
      </div>
      <div class="title">Featured deal</div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="description">
        <div class="row jc-fe">
          <div class="description__first">Created</div>
          <div class="description__second">{{created | date: 'dd/MM/yyyy ' + timeService.timeFormat}}</div>
        </div>
        <div *ngIf="created !== updated" class="row jc-fe">
          <div class="description__first">Updated</div>
          <div class="description__second">{{updated | date: 'dd/MM/yyyy ' + timeService.timeFormat}}</div>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="widget__body featured-deal">
    <div class="col mb-5">
      <div class="d-f">
        <div class="fw-b" style="width: 70px;">Operator:</div>
        <div>{{featuredDeal.operator?.name}}</div>
      </div>
      <div class="d-f jc-sb ai-c">
        <div class="d-f ai-c">
          <span class="fw-b" style="width: 70px;">Login link:</span>
          <mat-form-field appearance="outline">
            <mat-label>Select employee</mat-label>
            <mat-select class="w-200" #employeeSelect>
              <mat-select-trigger *ngIf="employeeSelect.value">
                <ng-container *ngIf="employeeSelect.value.firstName && employeeSelect.value.lastName; else noName">
                  {{employeeSelect.value.firstName}} {{employeeSelect.value.lastName}}
                </ng-container>
                <ng-template #noName>{{employeeSelect.value.email}}</ng-template>
              </mat-select-trigger>
              <mat-option *ngFor="let employee of featuredDeal.operator?.employees || []" [value]="employee">{{employee.firstName}} {{employee.lastName}}<span class="op-06 ml-10">{{employee.email}}</span></mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="d-f">
          <button *ngIf="employeeSelect.value" class="link-btn" mat-icon-button matTooltip="Make and copy{{(userLoginLink$ | async) ? ' new' : ''}} login link" (click)="makeAndCopyLoginLink(employeeSelect.value)">
            <mat-icon>add_link</mat-icon>
          </button>
          <button class="link-btn ml-10" *ngIf="userLoginLink$ | async as link" mat-icon-button matTooltip="Copy current login link" (click)="copyLoginLink(link)">
            <img src="assets/img/copy.svg" alt="">
          </button>
        </div>
      </div>
      <div *ngIf="userLoginLink$ | async as link" class="mb-5">{{link}}</div>
    </div>
    <div *ngIf="featuredDeal.aircraft" class="aircraft mb-10">
      <div class="aircraft__logo">
        <img [src]="featuredDeal.aircraft.pictureExterior || 'assets/img/logo_dark.png'" alt="">
      </div>
      <div class="col jc-sa">
        <div class="gold">{{featuredDeal.aircraft.typeName}}</div>
        <div>{{featuredDeal.aircraft.tailNumber}} <span class="aircraft__oa" *ngIf="featuredDeal.aircraft.ownerApproval==='yes'">OA</span></div>
        <div class="d-f ai-c">
          <img src="assets/img/aircraft/air-type.svg" width="24" height="22" alt="">
          <span class="pl-10 pr-15 d-f ai-c tt-c">{{featuredDeal.aircraft.categoryName}} Jet</span>
          <ng-container *ngIf="featuredDeal.aircraft.maxSpeed">
            <img src="assets/img/aircraft/speed.svg" width="24" height="24" alt="">
            <span class="pl-10 pr-15 d-f ai-c">{{featuredDeal.aircraft.maxSpeed}}</span>
          </ng-container>
          <ng-container *ngIf="featuredDeal.aircraft.seats">
            <img src="assets/img/aircraft/max-pas.svg" width="17" height="22" alt="">
            <span class="pl-10 d-f ai-c">{{featuredDeal.aircraft.seats}}</span>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="d-f pb-5">
      <div class="featured-deal-datetime">
        <div class="featured-deal-datetime__day">{{flightDateRange}}</div>
        <div class="featured-deal-datetime__divider"></div>
        <div class="featured-deal-datetime__month">
          {{flightMonthRange}}
        </div>
        <div class="featured-deal-datetime__divider"></div>
        <div class="featured-deal-datetime__time">{{flightTime}}</div>
      </div>
      <div class="of-hide wp-100 col jc-sb">
        <div class="featured-deal-icao">
          <div class="featured-deal-icao__title">Departure airport</div>
          <div class="featured-deal-icao__value">
            <div class="d-f ai-c of-hide">
              <img class="pin" src="assets/img/airport.svg">
              <span>{{ featuredDeal.from.name.replace(' Airport', '') }} ({{ featuredDeal.from.icao }})</span>
            </div>
            <span class="fi" [class]="'fi-'+featuredDeal.from.countryCode.toLowerCase()"></span>
          </div>
        </div>
        <div class="featured-deal-icao">
          <div class="featured-deal-icao__title">Destination airport</div>
          <div class="featured-deal-icao__value">
            <div class="d-f ai-c of-hide">
              <img class="pin" src="assets/img/airport.svg">
              <span>{{ featuredDeal.to.name.replace(' Airport', '') }} ({{ featuredDeal.to.icao }})</span>
            </div>
            <span class="fi" [class]="'fi-'+featuredDeal.to.countryCode.toLowerCase()"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="featured-deal-field">
      <div class="featured-deal-field__name">MAX PASSEENGERS</div>
      <div class="featured-deal-field__budget fg-1 fill-gold">{{featuredDeal.maxPassengers}}</div>
    </div>
    <div class="featured-deal-field">
      <div class="featured-deal-field__name">PRICE</div>
      <div class="featured-deal-field__budget fg-1">{{featuredDeal.priceCurCode}} {{featuredDeal.price | number}}</div>
    </div>
  </div>
</mat-expansion-panel>
