import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { Bid } from '../../interfaces/Bid';
import { Offer } from '../../interfaces/Offer';
import { BidToCreate } from '../../classes/BidToCreate';
import { BidToUpdate } from '../../classes/BidToUpdate';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { EstimatedLeg } from '../../interfaces/EstimatedLeg';

@Injectable({
  providedIn: 'root'
})
export class BidsService {
  private bidUrl!: string;
  offers$: ReplaySubject<Offer[]> = new ReplaySubject<Offer[]>();
  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService,
  ) {
    this.bidUrl = `${this.globalSharedService.config.baseUrl}/bids`;
  }

  getBidById(id: string | null): Observable<Bid> {
    return this.http.get<Bid>(`${this.bidUrl}/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  createBid(bidsToCreate: BidToCreate[]): Observable<unknown> {
    return this.http.post<unknown>(`${this.bidUrl}`, bidsToCreate)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateBid(bidPartial: BidToUpdate[]): Observable<unknown> {
    return this.http.patch<unknown>(`${this.bidUrl}`, bidPartial)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getOtherOffers(fromObject: { frpid?: string; offerid?: string; }): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${this.bidUrl}`, { params: new HttpParams({ fromObject, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getBids(opts = {}): Observable<Offer[]> {
    return this.http.get<Offer[]>(this.bidUrl, { params: new HttpParams({ fromObject: opts, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getMyBids(opts = {}): Observable<Offer[]> {
    return this.http.get<Offer[]>(this.bidUrl + '/my', {
      params: new HttpParams({ fromObject: opts, encoder: new CustomHttpParamEncoder() })
    })
      .pipe(
        tap(offers => this.offers$.next(offers)),
        catchError(this.errorService.handleError)
      );
  }

  cancelBid(bidId: string): Observable<unknown> {
    return this.http.post<unknown>(`${ this.bidUrl }/${ bidId }/cancel`, {})
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  rejectBid(data: { bidIds: string[], rejectionReason: string, clientId?: string }): Observable<unknown> {
    return this.http.post<unknown>(`${ this.bidUrl }/reject`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getHistoricalOffers(data: object, opts = {}): Observable<{aircraftPriceRanges: { category: 'light' | 'midsize' | 'super midsize' | 'large', min: number, max: number }[], bids: Bid[]}> {
    return this.http.post<{aircraftPriceRanges: any, bids: Bid[]}>(`${ this.bidUrl }/historical-offers`, data, { params: new HttpParams({ fromObject: opts, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
