import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { first, Observable, Observer, Subscription } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { CallRequest } from '../../../interfaces/CallRequest';
import { Client } from '../../../interfaces/Client';
import { User } from '../../../interfaces/User';
import { ClientsService } from '../../../services/api/clients.service';
import { TwilioService } from '../../../services/api/twilio.service';
import { UserService } from '../../../services/api/user.service';
import { SnackService } from '../../../services/helpers/snack.service';
import { EditPhoneComponent } from './edit-phone/edit-phone.component';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Component({
  selector: 'lib-client-info',
  templateUrl: './client-info.component.html'
})
export class ClientInfoComponent implements OnChanges, OnDestroy {
  @Input() client?: Client;
  @Input() expanded?: boolean;
  @Output() infoChanged = new EventEmitter();
  clientForm = this.fb.group({
    note: [''],
  });
  clientNoteChangeRef?: Subscription;
  reviewRequest$!: Observable<any>;
  firebaseId!: string;
  constructor(
    private dialog: MatDialog,
    private clientsService: ClientsService,
    private userService: UserService,
    private twilioService: TwilioService,
    private snackSrv: SnackService,
    private fb: UntypedFormBuilder,
    private fbRTB: AngularFireDatabase,
  ) {
    this.onClientNoteChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.client.currentValue?.id != changes.client.previousValue?.id) this.fillForm();
  }

  ngOnDestroy(): void {
    this.clientNoteChangeRef?.unsubscribe();
  }

  fillForm(): void {
    this.clientForm.setValue({ note: this.client?.notes?.notes || '' }, { emitEvent: false });
    if (this.client) this.reviewRequest$ = this.clientsService.getClientById(this.client.id)
      .pipe(
        tap(client => {
          this.firebaseId = client.owners[0].firebaseId;
        }),
        switchMap(client => {
        return this.fbRTB.object(`userPreferences/${client.owners[0].firebaseId}/reviewRequest`).valueChanges().pipe(
          tap(reviewRequest => {
            if (!reviewRequest) this.fbRTB.object(`userPreferences/${client.owners[0].firebaseId}/reviewRequest`).update({ showRequest: false });
          }));
      }));
  }

  onClientNoteChanged(): void {
    this.clientNoteChangeRef = this.clientForm.get('note')?.valueChanges
      .pipe(
        map((note: string) => {
          return { note, client: this.client };
        }),
        debounceTime(1000)
      )
      .subscribe(data => {
        if (data.client) this.clientsService.setClientNote(data.client.id, data.note)
          .pipe(first())
          .subscribe((savedNote) => {
            if (data.client) data.client.notes = savedNote;
          });
      });
  }

  openIntercom(): void {
    this.userService.getUserById(this.firebaseId)
      .pipe(first())
      .subscribe(user => {
        if (user.intercomLink) {
          window.open(user.intercomLink);
        } else alert('Intercom link is not found.');
      });
  }

  changeClientVerify(): void {
    if (this.client) {
      this.client.verified = !this.client.verified;
      this.clientsService.changeClientVerify({
        id: this.client?.id,
        verified: this.client?.verified
      })
        .pipe(first())
        .subscribe(() => {
          this.infoChanged.emit();
        });
    }
  }

  toggleReview(bool: boolean): void {
    this.fbRTB.object(`userPreferences/${this.firebaseId}/reviewRequest`).update({ showRequest: bool });
  }

  expReviewDate(firstRequestDate: string): string {
    return DateTime.fromISO(firstRequestDate).plus({ days: 365 }).toFormat('DD/MM/YYYY')
  }

  editClientPhoneNumber(): void {
    const dialogRef = this.dialog.open(EditPhoneComponent, {
      width: '486px',
      data: {
        phoneNumber: this.clientInfo?.phoneNumber || ''
      },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
    dialogRef.afterClosed()
      .pipe(first())
      .subscribe(data => {
        if (data && this.clientInfo && this.clientInfo.phoneNumber !== data) {
          this.clientInfo.phoneNumber = data;
          this.userService.changeClientPhoneNumber({
            userId: this.clientInfo.firebaseId,
            phoneNumber: this.clientInfo.phoneNumber
          }).pipe(first()).subscribe(() => {
            this.infoChanged.emit();
          });
        }
      });
  }

  toggleCall(): void {
    if (this.twilioService.onPhone) return this.snackSrv.systemError('Phone call is active');
    if (this.clientInfo?.phoneNumber) {
      const phoneNumber: string = this.clientInfo.phoneNumber.split(' ').join('').split('(').join('').split(')').join('').split('-').join('');
      if (phoneNumber.match(/^[+]?[0-9]{10,14}$/)) {
        this.twilioService.toggleCall({
          client: this.client,
          phoneNumber
        } as CallRequest);
      } else this.snackSrv.systemError('Phone number of this client is invalid');
    }
  }

  get relativeDate(): string | null {
    return DateTime.fromISO(this.client?.created || '').toRelative();
  }

  get clientInfo(): User | undefined {
    return this.client?.owners ? this.client?.owners[0] : undefined;
  }
}
