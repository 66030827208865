import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistComponent implements OnInit {

  checkList = this.fb.array([]);
  dialogForm = this.fb.group({
    checkList: this.checkList
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Array<{ name: string, visible: boolean, isChecked: boolean}>,
    public dialogRef: MatDialogRef<ChecklistComponent>,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.data.forEach(item => {
      this.checkList.push(this.fb.group({
        name: [item.name, [Validators.required, this.validatorTrim]],
        visible: item.visible,
        isChecked: item.isChecked
      }))
    });
  }

  validatorTrim(formControl: AbstractControl): ValidationErrors | null {
    const isWhitespace = (formControl.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  addItem(index: number): void {
    this.checkList.insert(index, this.fb.group({
      name: ['', [Validators.required, this.validatorTrim]],
      visible: true
    }));
  }

  deleteItem(index: number): void {
    this.checkList.removeAt(index);
  }

  get visibleItemsLength(): number {
    return this.checkList.value.filter((item: any) => item.visible).length;
  }
}
