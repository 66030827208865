<mat-expansion-panel class="widget mat-elevation-z request-menu" hideToggle (opened)="autosize.resizeToFitContent(true);" [expanded]="expanded">
  <mat-expansion-panel-header class="widget__header">
    <mat-panel-title>
      <div class="expand-icon" [class._active]="false">
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
        </svg>
      </div>
      <div class="title">
        <div>Client information</div>
        <lib-popup-default head="Questionnaire answers" [hoverOpen]="true">
          <img class="mx-20" src="assets/img/menu-questionnaire.svg" alt="">
          <div description>
            <div>Learn from: {{clientInfo?.questionnaire?.learnFrom || 'Not added'}}</div>
            <div>Number of Flights: {{clientInfo?.questionnaire?.numFlights || 'Not added'}}</div>
            <div>Who travels: {{clientInfo?.questionnaire?.whoBooks || 'Not added'}}</div>
            <div>Regions: {{clientInfo?.questionnaire?.region || 'Not added'}}</div>
          </div>
        </lib-popup-default>
      </div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="description">
        <div class="row jc-fe">
          <div class="description__first">Registered</div>
          <div class="description__second">{{relativeDate}}</div>
        </div>
        <div class="description__link" (click)="$event.stopPropagation(); openIntercom();">Open in Intercom</div>
      </div>
      <div class="menu">
        <button type="button" mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();" class="w-32 h-32">
          <img src="assets/img/more_vert.svg" alt="">
        </button>
        <mat-menu #menu="matMenu" yPosition="below">
          <button type="button" mat-menu-item (click)="changeClientVerify()">
            <span>{{client?.verified ? 'Unverify' : 'Verify'}} user</span>
          </button>
          <ng-container *ngIf="reviewRequest$ | async as reviewRequest">
            <button *ngIf="!reviewRequest.showRequest && !reviewRequest.alreadyReviewed" type="button" mat-menu-item (click)="toggleReview(true)">
              <span>Request AppStore review<br>
              <span class="fs-12 op-06" *ngIf="reviewRequest.requestsLeft; else non">{{reviewRequest.requestsLeft}} in-app prompt(s) left within recent 365-day period</span>
              <ng-template #non>
                <span class="fs-12 op-06" *ngIf="reviewRequest.requestsLeft === 0; else nan">no in-app prompts left, a right to prompt will be released on {{expReviewDate(reviewRequest.firstRequestDate)}}</span>
                <ng-template #nan>
                  <span class="fs-12 op-06">3 in-app prompt(s) left within recent 365-day period</span>
                </ng-template>
              </ng-template>
            </span>
            </button>


            <button *ngIf="!reviewRequest.showRequest && reviewRequest.alreadyReviewed" type="button" mat-menu-item (click)="toggleReview(true)">
              <span>Ask to update review</span>
            </button>
            <button *ngIf="reviewRequest.showRequest" type="button" mat-menu-item (click)="toggleReview(false)">
              <span>Withdraw review request</span>

            </button>

          </ng-container>
        </mat-menu>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <form [formGroup]="clientForm">
    <div class="widget__body">
      <div class="row">
        <div class="widget__field">
          <div class="title">Title</div>
          <div class="value">{{clientInfo?.title}}</div>
        </div>
        <div class="widget__field">
          <div class="title">Name</div>
          <div class="value">{{clientInfo?.firstName}}</div>
        </div>
        <div class="widget__field">
          <div class="title">Last name</div>
          <div class="value">{{clientInfo?.lastName}}</div>
        </div>
      </div>
      <div class="row">
        <div class="widget__field">
          <div class="title">Email</div>
          <div class="value icon">
            <ng-container *ngIf="clientInfo?.email">
              {{clientInfo?.email}}
              <lib-popup-default *ngIf="!clientInfo?.emailVerified" head="Email address is not verified" description="Client may not receive your emails" [hoverOpen]="true">
                <button mat-icon-button>
                  <img src="/assets/img/verify.svg">
                </button>
              </lib-popup-default>
            </ng-container>
          </div>
        </div>
        <div class="widget__field">
          <div class="title">
            <span>Phone</span>
            <button mat-icon-button class="edit-phone" (click)="editClientPhoneNumber()">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
            </button>
          </div>
          <div class="value icon">
            <ng-container *ngIf="clientInfo?.phoneNumber">
              {{clientInfo?.phoneNumber}}
              <lib-popup-default *ngIf="clientInfo?.phoneVerified === false" head="Phone number is not verified" description="Client may not receive your calls" [hoverOpen]="true">
                <img src="/assets/img/verify.svg">
              </lib-popup-default>
              <button mat-raised-button class="call-btn" (click)="toggleCall()">
                <span >Call</span>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="widget__field">
          <mat-label class="title">Additional Information</mat-label>
          <mat-form-field style="width: 100%" appearance="outline">
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" formControlName="note"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-expansion-panel>
