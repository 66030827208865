import { DateTime } from 'luxon';
import { Booking } from '../interfaces/Booking';

export class FeaturedFlightToCreate {
  aircraftId?: number;
  fromId?: number;
  toId?: number;
  maxPassengers: number;
  minDate?: string | Date;
  maxDate?: string | Date;
  minTime?: string;
  maxTime?: string;
  price: number;
  priceEstimated: number;
  priceCurCode: 'EUR' | 'USD' | 'GBP';
  pets: boolean;
  cargo!: boolean;
  description?: string;
  published: boolean;
  bookings?: Booking[];
  emptyLeg?: boolean;

  constructor(featuredFlightForm: Record<any, any>) {
    this.aircraftId = featuredFlightForm.aircraft.id;
    this.fromId = featuredFlightForm.from.id;
    this.toId = featuredFlightForm.to.id;
    this.maxPassengers = featuredFlightForm.maxPassengers;
    this.minDate = DateTime.fromJSDate(featuredFlightForm.minDate).toISO({ includeOffset: false });
    this.maxDate = DateTime.fromJSDate(featuredFlightForm.maxDate).toISO({ includeOffset: false });
    this.minTime = featuredFlightForm.minTime;
    this.maxTime = featuredFlightForm.maxTime;
    this.description = featuredFlightForm.description;
    this.price = featuredFlightForm.price;
    this.priceEstimated = featuredFlightForm.priceEstimated;
    this.priceCurCode = featuredFlightForm.priceCurCode;
    this.pets = featuredFlightForm.pets;
    this.published = true;
    this.emptyLeg = featuredFlightForm.emptyLeg;
  }
}
