import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmDeactivateGuard } from '../../guards/confirm-deactivate.guard';
import { MakeOfferComponent } from './make-offer.component';

const routes: Routes = [
  {
    path: '',
    component: MakeOfferComponent,
    canDeactivate: [ConfirmDeactivateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MakeOfferRoutingModule { }
