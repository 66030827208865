import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { Airport } from '../../interfaces/Airport';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { NewAirport } from '../../interfaces/NewAirport';

interface SearchedAirports {
  airports: Airport[];
  total: number;
}
@Injectable({
  providedIn: 'root'
})

export class AirportsService {
  airportsUrl = `${this.globalSharedService.config.baseUrl}/airports`;
  closestAirport$: ReplaySubject<Airport | undefined> = new ReplaySubject();

  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService
  ) { }
  searchAirports(value: string): Observable<SearchedAirports> {
    return this.http.get<SearchedAirports>(`${this.airportsUrl}?search=${value}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getAirports(query?: { [ x: string ]: string; }): Observable<{ total: number, airports: Airport[] }> {
    return this.http.get<{ total: number, airports: Airport[] }>(this.airportsUrl, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getAirportById(id: string | null): Observable<Airport> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<Airport>(this.airportsUrl + `/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateAirport(data: unknown): Observable<unknown> {
    return this.http.patch<unknown>(this.airportsUrl, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getCountries(): Observable<{ [x: string]: [string] }> {
    return this.http.get<{ [x: string]: [string] }>(this.airportsUrl + '/countries')
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  create(data: NewAirport): Observable<Airport> {
    return this.http.post<Airport>(this.airportsUrl, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getNearestAirports(query: { lat: number; lon: number, radius: number }): Observable<Airport[]> {
    return this.http.get<Airport[]>(this.airportsUrl + '/radius', { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
