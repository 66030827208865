<div class="modal-header">
	<span class="modal-header__text">Create Service</span>
	<button mat-icon-button mat-dialog-close>
		<img src="assets/img/cancel.svg" alt="">
	</button>
</div>
<form [formGroup]="serviceForm" class="modal-container">
	<mat-form-field appearance="outline">
		<mat-label>Service name</mat-label>
		<input matInput formControlName="name"/>
	</mat-form-field>

  <div class="d-f ai-c fg-1 jc-sb">
    <div>
      <mat-form-field class="budget-input mr-10" appearance="outline">
        <input matInput type="number" step="1" min="0" formControlName="price" placeholder="Price" required>
      </mat-form-field>
      <mat-form-field class="currency" appearance="outline">
        <mat-select formControlName="currency">
          <mat-option value="USD">USD</mat-option>
          <mat-option value="EUR">EUR</mat-option>
          <mat-option value="GBP">GBP</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <span class="opes-coin-rate">=</span>

    <div>
      <img class="mr-5" src="assets/img/opes-coin.svg" alt="">
      <span class="opes-coin-rate">{{ convertedValue }}</span>
    </div>
  </div>

  <div>
    <mat-label class="rate-label">Exchange rate on the platform</mat-label>
    <div>
      <img class="mr-5" src="assets/img/opes-coin.svg" alt="">
      <span class="rate">1 = {{ exchangeRate }} {{ serviceForm.get('currency')?.value | uppercase }}</span>
    </div>
  </div>

  <div class="mt-10">
    <label>Description</label>
    <mat-form-field class="wp-100" appearance="outline">
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="10" cdkAutosizeMaxRows="10" formControlName="description"></textarea>
    </mat-form-field>
  </div>

  <!-- <mat-error *ngIf="true" class="fs-12">Something went wrong</mat-error> -->
</form>
<div class="modal-bottom">
	<button mat-stroked-button class="modal-bottom__btn d-f jc-c ai-c cancel" mat-dialog-close>Cancel</button>
	<!-- <button mat-stroked-button class="modal-bottom__btn d-f jc-c ai-c ml-10 cancel" [disabled]="serviceForm.get('name')?.invalid" mat-dialog-close>Save</button> -->
  <button mat-raised-button color="accent" class="modal-bottom__btn d-f jc-c ai-c ml-10" [disabled]="serviceForm.get('name')?.invalid" mat-dialog-close (click)="createAdditionalService()">Save & Send</button>
</div>
