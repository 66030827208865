import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from './error.service';
import { SnackService } from './snack.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private filesUrl!: string;
  constructor(
    private snackSrv: SnackService,
    private http: HttpClient,
    private errorService: ErrorService,
    private globalSharedService: GlobalSharedService,
  ) {
    this.filesUrl = `${this.globalSharedService.config.baseUrl}/files`;
  }
  onFileSelect(event: Event, accept: string[] = [], succes: (file: File) => void): void {
    if (event && event.target) {
      const files = (event.target as HTMLInputElement).files;
      if (files && files.length > 0) {
        const file = files[ 0 ];
        if (!accept.includes(file.type)) {
          return this.snackSrv.systemError(
            `Please, upload files in ${ accept.map(a => a.split('/').pop()).join(', ').toUpperCase() } format${ accept.length > 1 && 's' || '' } only.`
          );
        }
        if (file.size > 52428800) {
          return this.snackSrv.systemError('File size exceeds maximum limit 50 MB.');
        }
        if (succes) succes(file);
      }
    }
  }

  onFilesSelect(event: Event | FileList, accept: string[] = [], succes: (files: FileList) => void): void {

    const checkFiles = (files: FileList | null) => {
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          if (!accept.includes(files[i].type)) {
            return this.snackSrv.systemError(
              `Please, upload files in ${ accept.map(a => a.split('/').pop()).join(', ').toUpperCase() } format${ accept.length > 1 && 's' || '' } only.`
            );
          }
          if (files[i].size > 52428800) {
            return this.snackSrv.systemError('File size exceeds maximum limit 50 MB.');
          }
        }
        if (succes) succes(files);
      }
    }

    if ((event as Event) && (event as Event).target) {
      const files = ((event as Event).target as HTMLInputElement).files;
      checkFiles(files);
    } else checkFiles(event as FileList);
  }

  deleteDocs(data: object): Observable<unknown> {
    return this.http.delete<unknown>(this.filesUrl, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
