import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { EventComponent } from '../../components/alerts/event/event.component';

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  constructor(private snackBar: MatSnackBar, private router: Router) { }

  authError(state: RouterStateSnapshot): Subscription | undefined {
    this.snackBar.open('You must be logged in!', 'Ok', { duration: 5000 });
    return this.snackBar._openedSnackBarRef
      ?.onAction()
      .pipe(tap(() => this.router.navigate([ '/' ], { queryParams: { returnUrl: state.url } })))
      .subscribe();
  }

  serverError(errorName: string, errorMessage: string): void {
    this.snackBar.open(errorMessage || errorName, 'Ok', { duration: 5000 });
  }

  systemError(errorMessage: string, duration = 5000): void {
    this.snackBar.open(errorMessage, 'Ok', { duration });
  }

  eventMsg(msg: string, action: () => void): void {
    this.snackBar.openFromComponent(EventComponent, {
      panelClass: 'event',
      data: {
        text: msg,
        action
      },
      duration: 30000,
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }
}
