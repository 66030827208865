import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';
import { GlobalSharedService } from '../../global-shared.service';
import { CallRequest } from '../../interfaces/CallRequest';
import { Pagination } from '../../interfaces/Pagination';
import { ErrorService } from '../helpers/error.service';

@Injectable({
  providedIn: 'root'
})
export class CallRequestsService {

  callRequestUrl!: string;
  callRequestsCounts: { [x: string]: number } = {
    new: 0,
    in_progress: 0,
    closed: 0,
  };

  constructor(
    private http: HttpClient,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService
  ) {
    this.callRequestUrl = `${this.globalSharedService.config.baseUrl}/call-requests`;
  }

  getCallRequests(query = {}): Observable<{ data: CallRequest[] | number, pagination?: Pagination }> {
    return this.http.get<{ data: CallRequest[] | number, pagination?: Pagination }>(this.callRequestUrl, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getCallRequestById(id: string): Observable<CallRequest> {
    return this.http.get<CallRequest>(`${this.callRequestUrl}/${id}`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  updateCallRequest(id: string, data: Record<string, string>, query = {}): Observable<unknown> {
    return this.http.patch<unknown>(`${this.callRequestUrl}/${id}`, data, { params: new HttpParams({ fromObject: query, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  startCall(id: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.callRequestUrl}/${id}/start-call`, {})
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  endCall(id: string, data: Record<string, string | number>): Observable<unknown> {
    return this.http.post<unknown>(`${this.callRequestUrl}/${id}/end-call`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
