import { Pipe, PipeTransform } from '@angular/core';
import { Passenger } from '../interfaces/Passenger';

@Pipe({
  name: 'passengersType'
})
export class PassengersTypePipe implements PipeTransform {

  transform(passengers: Passenger[] | null, type: string): Passenger[] | undefined {
    return passengers?.slice().filter(p => p.type === type);
  }

}
