import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor( private afAuth: AngularFireAuth ){}

  // tslint:disable-next-line:no-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    return this.afAuth.idToken
      .pipe(
        switchMap(token => {
          const headers = request.headers
            .set('Authorization', 'Bearer ' + token);
            // .append('Content-Type', 'application/json');
          const requestClone = request.clone({
            headers
          });
          return next.handle(requestClone);
        })
      );
  }
}
