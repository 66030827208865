<div class="modal-header">
	<span class="modal-header__text">Cancel Booking</span>
	<button mat-icon-button mat-dialog-close>
		<img src="assets/img/cancel.svg" alt="">
	</button>
</div>

<form class="modal-container" [formGroup]="dialogForm">
	<div class="modal-container__text center">Are you sure you wish to cancel this booking?</div>
	<div class="d-f ai-c">
    <div class="fg-1 ws-nw mr-10">Coins to return:</div>
    <mat-form-field class="comment" appearance="outline">
      <mat-label>Amount</mat-label>
      <input matInput placeholder="Coins amount to return" type="number" formControlName="coinsToReturn"/>
    </mat-form-field>
  </div>
	<mat-form-field class="comment" appearance="outline">
		<mat-label>Leave a comment</mat-label>
		<textarea matInput placeholder="Ex. The reason of this action is..." cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="14" formControlName="comment"></textarea>
	</mat-form-field>
</form>

<div class="modal-bottom">
	<button class="modal-bottom__btn d-f jc-c ai-c cancel" mat-stroked-button mat-dialog-close>No</button>
	<button mat-raised-button color="accent" class="modal-bottom__btn d-f jc-c ai-c ml-10" [disabled]="dialogForm.invalid" (click)="save()">Yes</button>
</div>
