import { Pipe, PipeTransform } from '@angular/core';
import { Booking } from '../interfaces/Booking';

/*
*
*/
@Pipe({name: 'bookingStatus'})
export class BookingStatusPipe implements PipeTransform {

  transform(value: Booking): { status: string, statusText: string, clientStatusText: string, clientStatusIndex: number, buttonText: string } {
    const result = { status: '', statusText: '', clientStatusText: '', clientStatusIndex: 0, buttonText: '' };
    // TODO Should be with else or refactor to switch case?
    // Upload charter agreement
    if (value.status === 'WaitingForServiceFee') {
      result.status = 'waiting-servicefee';
      result.statusText = 'Waiting for service fee...';
      result.clientStatusText = 'Pay service fee';
      result.clientStatusIndex = 0;
      result.buttonText = '';
      return result;
    }
    // Upload charter agreement
    if (!value.agreement) {
      result.status = 'upload_agreement';
      result.statusText = 'Upload charter agreement';
      result.clientStatusText = 'Waiting for charter contract';
      result.clientStatusIndex = 1;
      result.buttonText = 'Upload Documents';
    }
    // Waiting for client`s signature...
    if (value.agreement && !value.agreementSigned) {
      result.status = 'waiting-signature';
      result.statusText = 'Waiting for client`s signature...';
      result.clientStatusText = 'Sign charter contract';
      result.clientStatusIndex = 1;
      result.buttonText = '';
    }
    // Check & Verify documents
    if (value.agreement && value.agreementSigned) {
      result.status = 'confirm-agreement';
      result.statusText = 'Check & Verify documents';
      result.clientStatusText = 'Document processing';
      result.clientStatusIndex = 2;
      result.buttonText = 'Confirm CA & Upload Invoice';
    }
    // Upload Invoice
    if (value.status === 'WaitingForPayment' && !value.invoice) {
      result.status = 'upload-invoice';
      result.statusText = 'Upload Invoice';
      result.clientStatusText = 'Waiting for invoice';
      result.clientStatusIndex = 3;
      result.buttonText = 'Upload Invoice';
    }
    // Waiting for payment
    if (value.status === 'WaitingForPayment' && value.invoice && !value.paymentConfirmation) {
      result.status = 'confirm-payment';
      result.statusText = 'Waiting for payment...';
      result.clientStatusText = 'Upload payment confirmation';
      result.clientStatusIndex = 3;
      result.buttonText = '';
    }
    // Confirm payment
    if (value.status === 'WaitingForPayment' && value.invoice && value.paymentConfirmation) {
      result.status = 'confirm-payment';
      result.statusText = 'Check & Verify payment';
      result.clientStatusText = 'Payment processing';
      result.clientStatusIndex = 3;
      result.buttonText = 'Payment Received';
    }
    // Payment successful
    if (value.status === 'PreparingForFlight') {
      result.status = 'confirm-flight-ready';
      result.statusText = 'Payment successful. Start preparing the Flight';
      result.clientStatusText = 'Preparing the Flight';
      result.clientStatusIndex = 4;
      result.buttonText = 'Confirm Flight Ready';
    }
    // Flight Confirmed
    if (value.status === 'WaitingForDeparture') {
      result.status = 'flight-confirmed';
      result.statusText = 'Flight Confirmed';
      result.clientStatusText = 'Flight Confirmed';
      result.clientStatusIndex = 5;
      result.buttonText = '';
    }
    // In Flight
    if (value.status === 'InFlight' || value.status === 'Mixed') {
      result.status = 'in-flight';
      result.statusText = 'In Flight';
      result.clientStatusText = 'In Flight';
      result.clientStatusIndex = 5;
      result.buttonText = '';
    }
    // Landed
    if (value.status === 'Landed') {
      result.status = 'landed';
      result.statusText = 'Landed';
      result.clientStatusText = 'Landed';
      result.clientStatusIndex = 6;
      result.buttonText = '';
    }
    return result;
  }
}
