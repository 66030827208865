<div class="modal">
  <div class="modal-top row _nowrap jc-sb ai-c">
    <span class="modal-top__title">Competing offers</span>
    <img src="assets/img/dark-logo.svg" alt="">
  </div>
  <div class="modal-container">
    <div mat-dialog-content>
      <table *ngIf="dataSource?.data?.length; else empty" mat-table matSort [dataSource]="dataSource" class="no-cell-borders">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Operator </th>
          <td mat-cell *matCellDef="let el" [class.own]="el.aircraftInfo?.operator?.id === operatorId">
            <div class="row jc-fs ai-c">
              <div *ngIf="el.aircraftInfo.operator?.logo" class="avatar">
                <img [src]="userService.baseUrl+'/operators/'+el.aircraftInfo.operator?.id+'/logo?'+el.aircraftInfo.operator?.logo" alt="">
              </div>
              {{el.aircraftInfo.operator?.name}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="typeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Aircraft </th>
          <td mat-cell *matCellDef="let el" [class.own]="el.aircraftInfo.operator?.id === operatorId">
            <div class="row">
              <div *ngFor="let aircraft of el.aircrafts" class="aircraft ws-nw" [matTooltip]="aircraft.typeName" [matTooltipDisabled]="el.aircrafts.length===1">{{el.aircrafts.length>1 ? aircraft.categoryName : aircraft.typeName}}</div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
          <td class="ws-nw" mat-cell *matCellDef="let el" [class.own]="el.aircraftInfo.operator?.id === operatorId">
            {{el.price | currency: el.priceCurCode : 'symbol' : '1.0-0'}}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['name', 'typeName', 'price']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name', 'typeName', 'price'];"></tr>
      </table>
      <ng-template #empty>
        <div class="empty-list">Competing offers list is empty</div>
      </ng-template>
    </div>
  </div>
  <div class="modal-bottom">
      <button class="w-128" mat-dialog-close mat-raised-button color="primary" >OK</button>
  </div>
</div>
