import { AircraftSaved } from '../interfaces/AircraftSaved';


export class BidToUpdate {
  // operator: Record<any, any>;
  id: string;
  refNumber = '';
  offer = '';
  status = '';
  price = 0;
  emptyLeg = false;
  priceCurCode = '';
  aircraftInfo: AircraftSaved | undefined = undefined; // TODO AircraftSaved id ??
  from = ''; // Airport id
  to = ''; // Airport id
  takeoffDate = '';
  landingDate = '';
  takeoffOffset = '';
  landingOffset = '';
  description = '';
  flightTime = '';
  operatorId?: string;
  draft?: boolean;

  constructor(bidFromForm: Record<any, any>) {
    this.id = bidFromForm.id;
    this.refNumber = bidFromForm.refNumber;
    this.offer = bidFromForm.offer;
    this.status = bidFromForm.status;
    this.price = +bidFromForm.price;
    this.emptyLeg = bidFromForm.emptyLeg;
    this.priceCurCode = bidFromForm.priceCurCode;
    this.aircraftInfo = bidFromForm.aircraftInfo;
    this.description = bidFromForm.description;
    this.from = bidFromForm.from.id;
    this.to = bidFromForm.to.id;
    this.takeoffDate = bidFromForm.takeoffDate;
    this.landingDate = bidFromForm.landingDate;
    this.takeoffOffset = bidFromForm.takeoffOffset;
    this.landingOffset = bidFromForm.landingOffset;
    this.flightTime = `${ bidFromForm.flightTimeHours || '0' }:${ bidFromForm.flightTimeMinutes || '00' }`;
    if (!this.operatorId) delete this.operatorId;
  }
}
