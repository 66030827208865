import { Component, OnInit } from '@angular/core';
import { Maintenance, MaintenanceService } from 'common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.scss']
})
export class MaintenanceBannerComponent implements OnInit {
  maintenance$: Observable<Maintenance> = new Observable<Maintenance>();
  constructor(
    private maintenanceSrv: MaintenanceService,
  ) {}

  ngOnInit(): void {
    this.maintenance$ = this.maintenanceSrv.watchMaintenance();
  }

}
