<svg class="svg-loader"
     width="808" height="712" viewBox="0 0 808 712" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path d="M357.76 712C354.103 712 350.424 710.881 347.267 708.549C339.351 702.723 337.639 691.557 343.434 683.613L541.204 412.996L549.999 412.828C654.046 410.849 766.904 387.633 772.296 356.003C766.904 324.373 654.046 301.152 549.999 299.173L541.204 299.011L343.434 28.3935C337.639 20.445 339.351 9.28217 347.267 3.45752C355.145 -2.36353 366.301 -0.667725 372.109 7.29028L559.501 263.716C626.63 265.643 802.205 278.088 807.905 354.675L808 356.003L807.905 357.332C802.205 433.909 626.63 446.352 559.501 448.285L372.109 704.712C368.629 709.473 363.222 712 357.76 712Z"
          fill="white">
    </path>
    <path d="M366.185 374H17.8145C7.97971 374 0 365.942 0 356C0 346.058 7.97971 338 17.8145 338H366.185C376.02 338 384 346.058 384 356C384 365.942 376.02 374 366.185 374Z"
          fill="white">
    </path>
    <path d="M402.17 460H227.83C217.986 460 210 451.942 210 442C210 432.053 217.986 424 227.83 424H402.17C412.014 424 420 432.053 420 442C420 451.942 412.014 460 402.17 460Z"
          fill="white">
    </path>
    <path d="M402.17 288H227.83C217.986 288 210 279.942 210 270C210 260.053 217.986 252 227.83 252H402.17C412.014 252 420 260.053 420 270C420 279.942 412.014 288 402.17 288Z"
          fill="white">

    </path>
    <animateMotion
      path="M -3000 0 H 3000"
      dur="3s" repeatCount="indefinite"
    />
  </g>
</svg>
