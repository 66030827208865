<div class="modal-header">
	<span class="modal-header__text">Phone number</span>
	<button mat-icon-button mat-dialog-close>
		<img src="assets/img/cancel.svg" alt="">
	</button>
</div>
<form class="modal-container">
	<mat-form-field class="comment" appearance="outline">
		<mat-label>Phone</mat-label>
		<input matInput placeholder="Ex. The reason of this action is..." [formControl]="phoneNumber"/>
    <mat-error *ngIf="phoneNumber.invalid" class="fs-12">Phone number is not valid</mat-error>
	</mat-form-field>
</form>
<div class="modal-bottom">
	<button class="modal-bottom__btn d-f jc-c ai-c cancel" mat-stroked-button [mat-dialog-close]="">Cancel</button>
	<button mat-stroked-button class="modal-bottom__btn d-f jc-c ai-c ml-10" mat-button-disabled [disabled]="phoneNumber.invalid" [mat-dialog-close]="phoneNumber.value">Ok</button>
</div>
