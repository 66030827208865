import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SnackService } from '../services/helpers/snack.service';

@Injectable({ providedIn: 'root' })
export class ProcessResponseInterceptor implements HttpInterceptor {
  passByErrorsCodes = [
    'oj_0009',
    'oj_0008',
    'oj_0007',
    'oj_0005',
    'oj_0003',
    'oj_0001',
  ];
  constructor(private snackSrv: SnackService) {
  }

  // tslint:disable-next-line:no-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        map(event => {
          if (event instanceof HttpResponse) {
            if (event.body.ok) {
              if (event.body.pagination) {
                event = event.clone({ body: { data: event.body.data, pagination: event.body.pagination } });
              } else {
                event = event.clone({ body: (event.body.data) });
              }
            }
          }
          return event;
        }),
        catchError((err: HttpErrorResponse, caught: Observable<HttpEvent<any>>) => {
          if (!this.passByErrorsCodes.includes(err.error.error.code)) {
            if (err.error && err.error.error) {
              console.error('An error occurred:', err.error.error.message);
              this.snackSrv.serverError(err.error.error.name, err.error.error.message);
            } else {
              console.error(`Error code ${ err.status }, name: ${ err.name }, message: ${err.message}`);
              this.snackSrv.systemError(err.name);
            }
          }
          // ...optionally return a default fallback value so app can continue (pick one)
          // which could be a default value (which has to be a HttpResponse here)
          // return Observable.of(new HttpResponse({body: [{name: "Default value..."}]}));
          // or simply an empty observable
          return throwError(err.error);
        }));
  }
}
/*
.catch((err: HttpErrorResponse) => {

  if (err.error instanceof Error) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', err.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
  }

  // ...optionally return a default fallback value so app can continue (pick one)
  // which could be a default value (which has to be a HttpResponse here)
  // return Observable.of(new HttpResponse({body: [{name: "Default value..."}]}));
  // or simply an empty observable
  return Observable.empty<HttpEvent<any>>();*/
