import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';

@Injectable({
  providedIn: 'root'
})
export class RatesService {
  currentRatesUrl = `${ this.globalSharedService.config.baseUrl }/opes-coin/rates/current`;

  constructor(
    private globalSharedService: GlobalSharedService,
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  getCurrentRates(): Observable<Record<string, number>> {
    return this.http.get<Record<string, number>>(this.currentRatesUrl)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
