import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-flight-progress',
  templateUrl: './flight-progress.component.html',
  styleUrls: [ './flight-progress.component.scss' ]
})
export class FlightProgressComponent {
  @Input() set status(status: string | undefined) {
    if (isNaN(Number(status))) {
      switch (status) {
        case 'WaitingForServiceFee':
          this.statusNumber = 1;
          break;
        case 'WaitingForSignature':
          this.statusNumber = 3;
          break;
        case 'CheckingAgreement':
          this.statusNumber = 4;
          break;
        case 'WaitingForPayment':
          this.statusNumber = 5;
          break;
        case 'PreparingForFlight':
          this.statusNumber = 6;
          break;
        case 'WaitingForDeparture':
        case 'InFlight':
        case 'Mixed':
          this.statusNumber = 7;
          break;
        case 'Landed':
          this.statusNumber = 8;
          break;
        default:
          this.statusNumber = 2;
          break;
      }
    } else {
      this.statusNumber = Number(status);
    }
  }
  statusNumber!: number;
}
