<div class="modal-top row jc-sb ai-c">
  <div class="modal-top__title">Please share your rejection reason</div>
  <button mat-icon-button mat-dialog-close>
    <img src="assets/img/cancel.svg" alt="">
  </button>
</div>
<div class="modal-container">
  <div class="form-field">
    <div>
      <div class="form-field__title">I don't like this aircraft at all</div>
      <div class="form-field__subtitle">cancel this offer, and block this aircraft from being offered to you on any future charter requests</div>
    </div>
    <mat-slide-toggle labelPosition="before" [checked]="selectedReason.dislike_aircraft" (change)="setReason($event, 'dislike_aircraft')"></mat-slide-toggle>
  </div>
  <div class="form-field">
    <div>
      <div class="form-field__title">I like the aircraft, but not for this price</div>
      <div class="form-field__subtitle">cancel this offer, but allow the charter operator to improve their price if possible</div>
    </div>
    <mat-slide-toggle labelPosition="before" [checked]="selectedReason.dislike_price" (change)="setReason($event, 'dislike_price')"></mat-slide-toggle>
  </div>
  <div class="form-field">
    <div>
      <div class="form-field__title">Choose your own reason(s) and offer feedback</div>
      <div class="form-field__subtitle">your reason(s) and feedback will be shared with the operator, who may respond to you if feasible</div>
    </div>
    <mat-slide-toggle labelPosition="before" [checked]="selectedReason.other" (change)="setReason($event, 'other')"></mat-slide-toggle>
  </div>
  <div *ngIf="selectedReason.other" class="mx-20 my-10">
    <mat-form-field class="wp-100" appearance="outline">
      <textarea style="min-height: 56px;" matInput [formControl]="customReason" cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10" placeholder="Please elaborate on the reason(s) why this particular offer was not suitable for you. Your feedback will help operators improve their service to you in future."></textarea>
    </mat-form-field>

  </div>

</div>
<div class="modal-bottom">
  <button *ngIf="anyReasonSelected" class="modal-bottom__btn save" mat-raised-button [mat-dialog-close]="rejectionReason">SEND</button>
  <div *ngIf="!anyReasonSelected" class="fill-gold">In order to improve the effectiveness of the charter offers you receive on OPES JET, please try to provide operators with frequent feedback on their offers.</div>
</div>
