import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GlobalSharedService } from '../../global-shared.service';
import { ErrorService } from '../helpers/error.service';
import { SnackService } from '../helpers/snack.service';
import { UserAccount } from '../../interfaces/UserAccount';
import { Region } from '../../classes/Region';
import { User } from '../../interfaces/User';
import { UserRegister } from '../../interfaces/UserRegister';
import { Operator } from '../../interfaces/Operator';
import { DeletedAccount } from '../../interfaces/DeletedAccount';
import { UserSettings } from '../../interfaces/UserSettings';
import { CustomHttpParamEncoder } from '../../classes/custom-http-param-encoder';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public baseUrl = this.globalSharedService.config.baseUrl;
  public isOperator = this.globalSharedService.config.env === 'operator';
  public pageAfterAuth?: { url: string, params: Params };
  registerTempInfo: UserRegister | undefined;

  constructor(
    private http: HttpClient,
    public router: Router,
    public afAuth: AngularFireAuth,
    public snackSrv: SnackService,
    private globalSharedService: GlobalSharedService,
    private errorService: ErrorService
  ) {
    this.afAuth.user
      .pipe(first())
      .subscribe(fbuser => {
        if (fbuser?.uid) {
          if (globalSharedService.config.env === 'broker') this.getSuperBrokers();
        }
      });
  }

  userInfo$: BehaviorSubject<User> = new BehaviorSubject({} as User);
  superBrokers$: BehaviorSubject<UserAccount[]> = new BehaviorSubject([] as UserAccount[]);
  userSettings$: ReplaySubject<UserSettings> = new ReplaySubject();
  regions$: ReplaySubject<Region[]> = new ReplaySubject();

  getUsers(): Observable<UserAccount[]> {
    return this.http.get<UserAccount[]>(`${ this.baseUrl }/users`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getSuperBrokers(): void {
    this.http.get<UserAccount[]>(`${ this.baseUrl }/users/bizadmins`)
      .pipe(
        first(),
        tap(users => {
          this.superBrokers$.next(users);
        }),
        catchError(this.errorService.handleError)
      ).subscribe();
  }

  getUserInfo(): Observable<User> {
    return this.http.get<User>(`${ this.baseUrl }/users/me`)
      .pipe(
        tap(user => {
          user.operator = user.operatorAccountOwner || user.operatorAccountAdmin;
          this.userInfo$.next(user);
        }),
        catchError(this.errorService.handleError)
      );
  }

  getUserSettings(): Observable<UserSettings> {
    return this.http.get<UserSettings>(`${ this.baseUrl }/users/me/settings`)
      .pipe(
        tap(settings => {
          if (!settings.customTabsFrps) settings.customTabsFrps = [];
          if (!settings.customTabsBookings) settings.customTabsBookings = [];
          if (!settings.listsDaylimit || settings.listsDaylimit > 30) settings.listsDaylimit = 7;
          if (!settings.sortFrps) settings.sortFrps = { sortBy: 'created', direction: 'desc' };
          this.userSettings$.next(settings);
        }),
        catchError(this.errorService.handleError)
      );
  }

  updateUserSettings(settings: UserSettings): Observable<UserSettings> {
    return this.http.post<UserSettings>(`${ this.baseUrl }/users/me/settings`, settings)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getUserById(id: string | null): Observable<UserAccount> {
    if (!id) return this.errorService.noPropertyError();
    return this.http.get<UserAccount>(`${ this.baseUrl }/users/${ id }`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  extractRoleAndLink(user: UserAccount): UserAccount {
    user.roles = [];
    user.frontendLinks = [];
    if (user.clientAccountOwner && user.clientAccountOwner.id) {
      user?.roles?.push('Client Owner');
      user?.frontendLinks?.push(`/clients/${ user.clientAccountOwner.id }`);
    }
    if (user.clientAccountAdmin && user.clientAccountAdmin.id) {
      user?.roles?.push('Client Admin');
      user?.frontendLinks?.push(`/clients/${ user.clientAccountAdmin.id }`);
    }
    if (user.operatorAccountOwner && user.operatorAccountOwner.id) {
      user?.roles?.push('Operator Owner');
      user?.frontendLinks?.push(`/operators/${ user.operatorAccountOwner.id }`);
    }
    if (user.operatorAccountAdmin && user.operatorAccountAdmin.id) {
      user?.roles?.push('Operator Admin');
      user?.frontendLinks?.push(`/operators/${ user.operatorAccountAdmin.id }`);
    }
    if (user.isSysAdmin) {
      user?.roles?.push('SysAdmin');
    }
    if (user.isBizAdmin) {
      user?.roles?.push('BizAdmin');
    }
    return user;
  }

  ban(userId: string, reason: string, status?: boolean): Observable<UserAccount> {
    return this.http.post<UserAccount>(`${ this.baseUrl }/users/ban`, { userId, reason, enabled: status })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  signOut(): Promise<void> {
    return this.afAuth.signOut()
      .catch(() => {
        console.log('Logout error.');
      });
  }

  deleteFirebaseUser(): Promise<void> {
    return this.afAuth.currentUser.then(user => user?.delete());
  }

  getCustomToken(params: { [ x: string ]: string; }): Observable<string> {
    return this.http.post<string>(`${ this.baseUrl }/tokens`, params)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getLoginLink(id: string): Observable<string> {
    return this.http.get<string>(`${ this.baseUrl }/users/${ id }/tokens`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  contactUs(): void {
    (window as any).Intercom('show');
  }

  inviteUser(inviteData: { email: string; }): Observable<unknown> {
    return this.http.post<unknown>(`${ this.baseUrl }/invites`, inviteData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  updateUser(data: object): Observable<unknown> {
    return this.http.patch<unknown>(`${ this.baseUrl }/users`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  deleteUser(data: object): Observable<unknown> {
    return this.http.delete<unknown>(`${ this.baseUrl }/users`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  makeAdmin(data: object): Observable<unknown> {
    return this.http.put<unknown>(`${ this.baseUrl }/admins`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  removeAdmin(data: object): Observable<unknown> {
    return this.http.delete<unknown>(`${ this.baseUrl }/admins`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  checkInvite(token: string): Observable<{ [ x: string ]: string; }> {
    return this.http.get<{ [ x: string ]: string; }>(`${ this.baseUrl }/invites/${ token }`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  createFirebaseUser(data: any): Observable<any> {
    return this.http.post<any>(`${ this.baseUrl }/firebase/users`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  createUser(data: any): Observable<any> {
    return this.http.post<any>(`${ this.baseUrl }/users`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  registerUser(userCreate: UserRegister): Observable<User> {
    return this.http.post<User>(`${ this.baseUrl }/users/register`, userCreate)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  resendEmail(): Observable<unknown> {
    return this.http.post<unknown>(`${ this.baseUrl }/users/send-verification`, {})
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  registerCompany(companyCreate: FormData): Observable<unknown> {
    return this.http.post<unknown>(`${ this.baseUrl }/operators`, companyCreate)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  sendPasswordResetEmail(email: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.baseUrl}/send-reset`, { email })
      .pipe(
        catchError(this.errorService.handleError),
        tap(() => {
          this.snackSrv.systemError(`The link for password reset was sent to your Email ${email}.  Please check it to complete the password reset.`, 8000);
        })
      );
  }

  changeClientPhoneNumber(data: { userId: string, phoneNumber: string }): Observable<void> {
    return this.http.patch<void>(`${ this.baseUrl }/users/phones`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getEmployees(): Observable<Operator> {
    return this.http.get<Operator>(`${ this.baseUrl }/operators/my`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  updateOperator(data: unknown, silent = false): Observable<User> {
    return this.http.patch<User>(`${ this.baseUrl }/operators`, data, { params: new HttpParams({ fromObject: {silent}, encoder: new CustomHttpParamEncoder() }) })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getRegions(): Observable<Region[]> {
    return this.http.get<Region[]>(`${ this.baseUrl }/regions`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getDocument(link: string): Observable<Blob> {
    return this.http.get(link, { responseType: 'blob' })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  uploadFile(formData: FormData, type: string, useOperatorId = true): Observable<unknown> {
    let url;
    if (useOperatorId) url = `${ this.baseUrl }/operators/${ this.userInfo$.getValue().operator?.id }/${ type }`;
    else url = `${ this.baseUrl }/operators/${ type }`;
    return this.http.post<unknown>(url, formData)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getDeletedAccounts(): Observable<DeletedAccount[]> {
    return this.http.get<DeletedAccount[]>(`${ this.baseUrl }/users/deleted-accounts`)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  registerDeleted(data: { id: string, force?: boolean, description?: string; }): Observable<DeletedAccount> {
    return this.http.post<DeletedAccount>(`${ this.baseUrl }/users/deleted-accounts`, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
